import React from "react"
import { Text } from "gatsby-interface"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import PropTypes from "prop-types"
import { captureException } from "@sentry/browser"
import { MdErrorOutline } from "react-icons/md"
import { subscriptionStatus as text } from "@modules/locales/default.js"
import Loading from "@modules/ui/components/Loading"

/*
 * Stripe can detect a user's locale and localize its error messages accordingly
 * However, we only support English locale for now,
 * so for UI consistency we need to force "en" locale
 **/

const DEFAULT_LOCALE = `en`

// Will async load the Stripe.js script and initialize a Stripe object
// https://stripe.com/docs/stripe-js/react

let stripeInstance

const getStripeInstance = async () => {
  if (!stripeInstance) {
    stripeInstance = await loadStripe(process.env.GATSBY_STRIPE_KEY)
  }
  return stripeInstance
}

function PaymentProvider({ children }) {
  const [stripe, setStripe] = React.useState(undefined)
  const [hasError, setHasError] = React.useState(false)

  React.useEffect(() => {
    getStripeInstance()
      .then(instance => {
        setStripe(instance)
      })
      .catch(error => {
        setHasError(true)
        captureException(error)
      })
  }, [])

  if (hasError) {
    return (
      <Text css={theme => ({ color: theme.colors.red[50] })}>
        <MdErrorOutline aria-hidden={true} />{" "}
        {text.messages.errorLoadingProvider}
      </Text>
    )
  }

  if (!stripe) {
    return <Loading bufferSize="none" message={text.messages.loadingProvider} />
  }

  return (
    <Elements stripe={stripe} options={{ locale: DEFAULT_LOCALE }}>
      {children}
    </Elements>
  )
}

PaymentProvider.propTypes = {
  children: PropTypes.node,
}

export default PaymentProvider

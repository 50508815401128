import * as React from "react"
import ContentSection from "./ContentSection"
import { userSettings as text } from "@modules/locales/default.js"
import { Heading } from "gatsby-interface"

function OrganizationsAndBilling() {
  return (
    <ContentSection>
      <Heading
        as="h1"
        css={theme => ({
          fontSize: theme.fontSizes[6],
          marginBottom: theme.space[7],
        })}
      >
        {text.yourAccount}
      </Heading>
    </ContentSection>
  )
}

export default OrganizationsAndBilling

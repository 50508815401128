import * as React from "react"
import { MdInfoOutline } from "react-icons/md"
import {
  ThemeCss,
  StyledPanelHeader,
  Tooltip,
  VisuallyHidden,
  Spacer,
  Link,
} from "gatsby-interface"
import { FormattedMessage } from "@modules/locales"
import { workspaceBuildQueue as text } from "@modules/locales/default.js"
import { useLocation } from "@gatsbyjs/reach-router"
import { useWorkspaceDetailsShortcuts } from "../hooks/useWorkspaceDetailsShortcuts"
import { useTracker, SegmentEventType } from "@modules/analytics"

export type WorkspaceBuildQueueHeaderProps = {
  organizationId: string
  onClose: () => void
}

/**
 * A child component of WorkspaceBuildQueue, it renders the header of build queue panel
 */

export function WorkspaceBuildQueueHeader({
  organizationId,
  onClose,
}: WorkspaceBuildQueueHeaderProps) {
  const { trackSegment, trackAction } = useTracker()
  const { pathname } = useLocation()

  const workspace = useWorkspaceDetailsShortcuts(organizationId)

  const ctaIsVisible = workspace.isOnProfessionalPlan || workspace.isOnFreePlan
  const ctaLinkAddress = workspace.isOnFreePlan
    ? `/dashboard/pricing/${organizationId}`
    : `/dashboard/organization/${organizationId}/settings/general#addons`
  const ctaLinkLabel = workspace.isOnFreePlan
    ? text.labels.upgrade
    : text.labels.addOn

  const handleWorkspaceNameClick = () => {
    const isWorkspaceScreen = pathname?.includes(`/dashboard/organization/`)

    if (isWorkspaceScreen) {
      onClose()
    }
  }

  return (
    <StyledPanelHeader onCloseButtonClick={onClose}>
      {workspace.name && (
        <FormattedMessage<"name", "link">
          message={text.headers.buildQueueFor}
          values={{
            name: workspace.name,
          }}
          tags={{
            link: function (content) {
              return (
                <Link
                  to={`/dashboard/organization/${organizationId}/sites`}
                  variant="SIMPLE"
                  onClick={handleWorkspaceNameClick}
                >
                  {content}
                </Link>
              )
            },
          }}
        />
      )}

      {workspace.concurrencyTotalLimit && (
        <div css={noteCss}>
          <Tooltip
            label={text.labels.learnMoreAboutConcurrency}
            css={customTooltipCss}
            style={customTooltipCss}
          >
            <a
              href="https://support.gatsbyjs.com/hc/en-us/articles/4416086727059"
              target="_blank"
              rel="noopener noreferrer"
              css={linkCss}
              onClick={() => {
                trackAction({
                  eventType: `TRACK_EVENT`,
                  name: `Learn more link clicked`,
                  uiSource: `Workspace build queue (panel)`,
                })
                trackSegment({
                  type: SegmentEventType.Track,
                  event: `Learn more link clicked`,
                  properties: {
                    location: `Workspace build queue (panel)`,
                  },
                })
              }}
            >
              <MdInfoOutline />
              <VisuallyHidden>
                {text.labels.learnMoreAboutConcurrency}
              </VisuallyHidden>
            </a>
          </Tooltip>

          <Spacer size={2} direction="horizontal" />

          <FormattedMessage<"number">
            message={text.messages.planIncludes}
            values={{
              number: workspace.concurrencyTotalLimit,
            }}
          />

          {ctaIsVisible && (
            <React.Fragment>
              <Spacer size={2} direction="horizontal" />
              <Link
                to={ctaLinkAddress}
                variant="SIMPLE"
                onClick={() => {
                  trackAction({
                    eventType: `TRACK_EVENT`,
                    name: `CTA (${ctaLinkLabel}) link clicked`,
                    uiSource: `Workspace build queue (panel)`,
                  })
                  trackSegment({
                    type: SegmentEventType.Track,
                    event: `CTA (${ctaLinkLabel}) link clicked`,
                    properties: {
                      location: `Workspace build queue (panel)`,
                    },
                  })
                  onClose()
                }}
              >
                {ctaLinkLabel}
              </Link>
            </React.Fragment>
          )}
        </div>
      )}
    </StyledPanelHeader>
  )
}

/* styles */

const noteCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  fontFamily: theme.fonts.body,
  fontWeight: theme.fontWeights.body,
  margin: 0,
  marginTop: theme.space[2],

  span: {
    flexShrink: 0,
    display: `flex`,

    svg: {
      color: theme.colors.purple[60],
      fontSize: `1.1em`,
    },
  },
})

const linkCss: ThemeCss = theme => ({
  display: `inline-flex`,
  alignItems: `center`,
  justifyContent: `center`,
})

// uses this hack to force Tooltip component properly render in Modal
const customTooltipCss = {
  zIndex: 10000,
  transform: `translateY(-1rem)`,
}

// temporary imports the currently used data till the time
// the singup flows are behind the flag
const countriesData = require("@modules/onboarding/gaFlow/utils/countrySelectorData.json")
export const countries = countriesData

export type RegionData = {
  name: string
  shortCode: string
}

export type CountryData = {
  countryName: string
  countryShortCode: string
  regions?: Array<RegionData>
}

import * as React from "react"

export type InfinityIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  a11yId?: string
}

export function InfinityIcon({ a11yId, ...rest }: InfinityIconProps) {
  const id = a11yId ? a11yId : `ProfileRequestIcon`

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      id={id}
      {...rest}
    >
      <path
        d="M7.55296 8.02734C5.09629 8.02734 3.10156 10.0011 3.10156 12.3808C3.10156 14.7884 5.09629 16.7342 7.55296 16.7342C9.31672 16.7342 10.6115 15.8593 11.4514 15.1034C11.6684 14.8864 11.8154 14.7324 11.9974 14.5225C12.1513 14.7184 12.2983 14.8654 12.5503 15.1034C13.4182 15.8943 14.699 16.7342 16.4418 16.7342C18.9054 16.7342 20.9002 14.7884 20.9002 12.3808C20.9002 10.0011 18.9054 8.02734 16.4418 8.02734C14.734 8.02734 13.4042 8.88123 12.5153 9.71412C12.3123 9.91009 12.1513 10.0711 11.9974 10.267C11.8294 10.0711 11.6824 9.92409 11.4654 9.71412C10.5975 8.89523 9.31672 8.02734 7.55296 8.02734ZM5.71921 12.3808C5.71921 11.4359 6.5451 10.631 7.55296 10.631C8.35785 10.631 9.07175 11.0509 9.63868 11.5829C9.83465 11.7718 10.1986 12.1638 10.3666 12.3808C10.2196 12.5907 9.99563 12.8847 9.63868 13.2066C9.01576 13.7666 8.35785 14.1305 7.55296 14.1305C6.5451 14.1305 5.71921 13.3536 5.71921 12.3808ZM18.2615 12.3808C18.2615 13.3536 17.4566 14.1305 16.4418 14.1305C15.6229 14.1305 14.951 13.7526 14.363 13.2066C14.0411 12.8987 13.8171 12.6467 13.6351 12.3808C13.7751 12.2128 14.1111 11.8278 14.363 11.5829C14.895 11.0719 15.6229 10.631 16.4418 10.631C17.4566 10.631 18.2615 11.4359 18.2615 12.3808Z"
        fill="currentColor"
      />
    </svg>
  )
}

// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type CreateOrganizationMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  avatarUrl?: Types.Maybe<Types.Scalars['String']>;
  subscription?: Types.Maybe<Types.SubscriptionBootstrapInput>;
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createGatsbyOrganization?: Types.Maybe<(
    { __typename?: 'Organization' }
    & Pick<Types.Organization, 'id' | 'name'>
  )> }
);


export const CreateOrganizationDocument = gql`
    mutation createOrganization($name: String!, $avatarUrl: String, $subscription: SubscriptionBootstrapInput) {
  createGatsbyOrganization(
    name: $name
    avatarUrl: $avatarUrl
    subscription: $subscription
  ) {
    id
    name
  }
}
    `;
export type CreateOrganizationMutationFn = ApolloReactCommon.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      avatarUrl: // value for 'avatarUrl'
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, baseOptions);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = ApolloReactCommon.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

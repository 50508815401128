import { SubscriptionPlan, MachinePricingTier } from "@modules/graphql/types"
import { useAllAvailablePlansQuery } from "@modules/billing/queries.generated"

export const FREE_PLAN_ID = `free`
export const FREE_PLAN: SubscriptionPlan = {
  name: `Free`,
  features: [],
  formattedAmount: `$0.00`,
  formattedMonthlyAmount: `$0.00`,
  id: FREE_PLAN_ID,
  planId: FREE_PLAN_ID,
  amount: 0,
  isRecommended: false,
  buildsTier: MachinePricingTier.Free,
  hostingTier: MachinePricingTier.Free,
  baseFeatures: {
    tier: MachinePricingTier.Free,
  },
}

export const useAvailableBillingPlans = ({
  organizationId,
  skip,
}: {
  organizationId?: string
  skip?: boolean
} = {}) => {
  const planGroup = `UNIT_PRICING_SELF_SERVE`

  const { loading, error, data } = useAllAvailablePlansQuery({
    variables: {
      organizationId,
      group: planGroup,
    },
    skip,
  })

  const monthlyPlans = (data && data.monthlyPlans) || []
  const annualPlans = (data && data.annualPlans) || []
  const allPlans = [...monthlyPlans, ...annualPlans]
  const finalPlans = allPlans.length > 0 ? [...allPlans, FREE_PLAN] : allPlans

  return {
    plans: finalPlans,
    loading,
    error,
  }
}

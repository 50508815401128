// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type UserOrganizationsWithBillingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserOrganizationsWithBillingQuery = (
  { __typename?: 'Query' }
  & { currentOrganizations?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Organization' }
    & Pick<Types.Organization, 'id' | 'name'>
    & { billing?: Types.Maybe<(
      { __typename?: 'BillingInformation' }
      & Pick<Types.BillingInformation, 'id' | 'status'>
      & { plan?: Types.Maybe<(
        { __typename?: 'SubscriptionPlan' }
        & Pick<Types.SubscriptionPlan, 'id' | 'amount' | 'formattedAmount' | 'name'>
      )> }
    )>, permissions?: Types.Maybe<(
      { __typename?: 'PermissionsDigest' }
      & Pick<Types.PermissionsDigest, 'id' | 'role'>
    )> }
  )>>> }
);

export type UserStatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserStatsQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Types.Maybe<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { stats?: Types.Maybe<(
      { __typename?: 'PermissionsStats' }
      & Pick<Types.PermissionsStats, 'totalOrganizationsCount' | 'totalSitesCount' | 'totalOrganizationsOwnedCount' | 'totalSitesOwnedCount'>
    )> }
  )> }
);

export type PersonalAccessTokensQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PersonalAccessTokensQuery = (
  { __typename?: 'Query' }
  & { personalAccessTokens?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'PersonalAccessToken' }
    & Pick<Types.PersonalAccessToken, 'id' | 'name' | 'lastUsedAt'>
  )>>> }
);

export type UpdateUserInfoMutationVariables = Types.Exact<{
  user: Types.UserInfoInput;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInformation?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message'>
  )> }
);

export type CreatePersonalAccessTokenMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type CreatePersonalAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { createPersonalAccessToken?: Types.Maybe<(
    { __typename?: 'CreatedPersonalAccessToken' }
    & Pick<Types.CreatedPersonalAccessToken, 'id' | 'name' | 'token'>
  )> }
);

export type DeletePersonalAccessTokenMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeletePersonalAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { deletePersonalAccessToken?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message'>
  )> }
);


export const UserOrganizationsWithBillingDocument = gql`
    query UserOrganizationsWithBilling {
  currentOrganizations {
    id
    name
    billing {
      id
      plan {
        id
        amount
        formattedAmount
        name
      }
      status
    }
    permissions {
      id
      role
    }
  }
}
    `;

/**
 * __useUserOrganizationsWithBillingQuery__
 *
 * To run a query within a React component, call `useUserOrganizationsWithBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationsWithBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationsWithBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrganizationsWithBillingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserOrganizationsWithBillingQuery, UserOrganizationsWithBillingQueryVariables>) {
        return ApolloReactHooks.useQuery<UserOrganizationsWithBillingQuery, UserOrganizationsWithBillingQueryVariables>(UserOrganizationsWithBillingDocument, baseOptions);
      }
export function useUserOrganizationsWithBillingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserOrganizationsWithBillingQuery, UserOrganizationsWithBillingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserOrganizationsWithBillingQuery, UserOrganizationsWithBillingQueryVariables>(UserOrganizationsWithBillingDocument, baseOptions);
        }
export type UserOrganizationsWithBillingQueryHookResult = ReturnType<typeof useUserOrganizationsWithBillingQuery>;
export type UserOrganizationsWithBillingLazyQueryHookResult = ReturnType<typeof useUserOrganizationsWithBillingLazyQuery>;
export type UserOrganizationsWithBillingQueryResult = ApolloReactCommon.QueryResult<UserOrganizationsWithBillingQuery, UserOrganizationsWithBillingQueryVariables>;
export const UserStatsDocument = gql`
    query UserStats {
  currentUser {
    id
    stats {
      totalOrganizationsCount
      totalSitesCount
      totalOrganizationsOwnedCount
      totalSitesOwnedCount
    }
  }
}
    `;

/**
 * __useUserStatsQuery__
 *
 * To run a query within a React component, call `useUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStatsQuery, UserStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStatsQuery, UserStatsQueryVariables>(UserStatsDocument, baseOptions);
      }
export function useUserStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStatsQuery, UserStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStatsQuery, UserStatsQueryVariables>(UserStatsDocument, baseOptions);
        }
export type UserStatsQueryHookResult = ReturnType<typeof useUserStatsQuery>;
export type UserStatsLazyQueryHookResult = ReturnType<typeof useUserStatsLazyQuery>;
export type UserStatsQueryResult = ApolloReactCommon.QueryResult<UserStatsQuery, UserStatsQueryVariables>;
export const PersonalAccessTokensDocument = gql`
    query PersonalAccessTokens {
  personalAccessTokens {
    id
    name
    lastUsedAt
  }
}
    `;

/**
 * __usePersonalAccessTokensQuery__
 *
 * To run a query within a React component, call `usePersonalAccessTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalAccessTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalAccessTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalAccessTokensQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonalAccessTokensQuery, PersonalAccessTokensQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonalAccessTokensQuery, PersonalAccessTokensQueryVariables>(PersonalAccessTokensDocument, baseOptions);
      }
export function usePersonalAccessTokensLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonalAccessTokensQuery, PersonalAccessTokensQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonalAccessTokensQuery, PersonalAccessTokensQueryVariables>(PersonalAccessTokensDocument, baseOptions);
        }
export type PersonalAccessTokensQueryHookResult = ReturnType<typeof usePersonalAccessTokensQuery>;
export type PersonalAccessTokensLazyQueryHookResult = ReturnType<typeof usePersonalAccessTokensLazyQuery>;
export type PersonalAccessTokensQueryResult = ApolloReactCommon.QueryResult<PersonalAccessTokensQuery, PersonalAccessTokensQueryVariables>;
export const UpdateUserInfoDocument = gql`
    mutation updateUserInfo($user: UserInfoInput!) {
  updateUserInformation(user: $user) {
    success
    message
  }
}
    `;
export type UpdateUserInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, baseOptions);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = ApolloReactCommon.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const CreatePersonalAccessTokenDocument = gql`
    mutation createPersonalAccessToken($name: String!) {
  createPersonalAccessToken(name: $name) {
    id
    name
    token
  }
}
    `;
export type CreatePersonalAccessTokenMutationFn = ApolloReactCommon.MutationFunction<CreatePersonalAccessTokenMutation, CreatePersonalAccessTokenMutationVariables>;

/**
 * __useCreatePersonalAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreatePersonalAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalAccessTokenMutation, { data, loading, error }] = useCreatePersonalAccessTokenMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePersonalAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePersonalAccessTokenMutation, CreatePersonalAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePersonalAccessTokenMutation, CreatePersonalAccessTokenMutationVariables>(CreatePersonalAccessTokenDocument, baseOptions);
      }
export type CreatePersonalAccessTokenMutationHookResult = ReturnType<typeof useCreatePersonalAccessTokenMutation>;
export type CreatePersonalAccessTokenMutationResult = ApolloReactCommon.MutationResult<CreatePersonalAccessTokenMutation>;
export type CreatePersonalAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePersonalAccessTokenMutation, CreatePersonalAccessTokenMutationVariables>;
export const DeletePersonalAccessTokenDocument = gql`
    mutation deletePersonalAccessToken($id: UUID!) {
  deletePersonalAccessToken(id: $id) {
    success
    message
  }
}
    `;
export type DeletePersonalAccessTokenMutationFn = ApolloReactCommon.MutationFunction<DeletePersonalAccessTokenMutation, DeletePersonalAccessTokenMutationVariables>;

/**
 * __useDeletePersonalAccessTokenMutation__
 *
 * To run a mutation, you first call `useDeletePersonalAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonalAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonalAccessTokenMutation, { data, loading, error }] = useDeletePersonalAccessTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonalAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePersonalAccessTokenMutation, DeletePersonalAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePersonalAccessTokenMutation, DeletePersonalAccessTokenMutationVariables>(DeletePersonalAccessTokenDocument, baseOptions);
      }
export type DeletePersonalAccessTokenMutationHookResult = ReturnType<typeof useDeletePersonalAccessTokenMutation>;
export type DeletePersonalAccessTokenMutationResult = ApolloReactCommon.MutationResult<DeletePersonalAccessTokenMutation>;
export type DeletePersonalAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePersonalAccessTokenMutation, DeletePersonalAccessTokenMutationVariables>;

import * as React from "react"
import {
  useFunctionInvocationsQuery,
  useFunctionInvocationCreatedSubscription,
  FunctionInvocationsDocument,
  FunctionInvocationsQuery,
  FunctionInvocationsQueryVariables,
} from "@modules/functions/queries.generated"
import { FunctionInvocation } from "@modules/graphql/types"
import { NetworkStatus } from "apollo-client"

const LOGS_LIMIT_PER_PAGE = 10

export function useFunctionInvocationsLogs(
  siteId: string,
  functionId: string,
  buildId: string
) {
  const hasLoadedAll = React.useRef<boolean>(false)
  const [beforeStartedAt, setBeforeStartedAt] = React.useState(null)
  const [seeRecentLogs, setSeeRecentLogs] = React.useState(false)

  const {
    data,
    loading,
    error,
    fetchMore,
    networkStatus,
  } = useFunctionInvocationsQuery({
    variables: {
      siteId,
      functionId,
      buildId,
      limit: LOGS_LIMIT_PER_PAGE,
    },
    fetchPolicy: `cache-and-network`,
  })

  const functionInvocations = data?.functionInvocations

  const lastInitialInvocation =
    functionInvocations && functionInvocations[functionInvocations.length - 1]

  if (!beforeStartedAt && lastInitialInvocation) {
    setBeforeStartedAt(lastInitialInvocation.startedAt)
  }

  const loadMore = () => {
    const currentLogsSize = data?.functionInvocations?.length ?? 0
    const hasMoreLogs =
      currentLogsSize >= LOGS_LIMIT_PER_PAGE && !hasLoadedAll.current
    const isAlreadyFetching = networkStatus === NetworkStatus.fetchMore

    if (!hasMoreLogs || isAlreadyFetching) {
      return
    }

    return fetchMore({
      variables: {
        siteId,
        functionId,
        buildId,
        beforeStartedAt,
        limit: LOGS_LIMIT_PER_PAGE,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const moreLogs = fetchMoreResult?.functionInvocations ?? []

        if (moreLogs.length === 0) {
          return previousResult
        }

        const lastInvocation = moreLogs[moreLogs.length - 1]

        if (lastInvocation && beforeStartedAt !== lastInvocation.startedAt) {
          setBeforeStartedAt(lastInvocation.startedAt)
        }

        return {
          ...previousResult,
          functionInvocations: [
            ...(previousResult?.functionInvocations ?? []),
            ...moreLogs,
          ],
        }
      },
    }).then(({ data }) => {
      const moreLogsSize = data?.functionInvocations?.length ?? 0

      hasLoadedAll.current = moreLogsSize < LOGS_LIMIT_PER_PAGE
    })
  }

  useFunctionInvocationCreatedSubscription({
    variables: {
      buildId,
      functionId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryInfo = {
        query: FunctionInvocationsDocument,
        variables: {
          buildId,
          functionId,
          siteId,
          limit: LOGS_LIMIT_PER_PAGE,
        },
      }

      try {
        const currentLogs = client.readQuery<
          FunctionInvocationsQuery,
          FunctionInvocationsQueryVariables
        >(queryInfo)

        if (!currentLogs) {
          return
        }

        const currentFunctionInvocations =
          currentLogs?.functionInvocations || []

        const logsPayload =
          subscriptionData?.data?.functionInvocationCreated ||
          ({} as FunctionInvocation)

        currentLogs.functionInvocations = [
          logsPayload,
          ...currentFunctionInvocations,
        ]

        client.writeQuery<
          FunctionInvocationsQuery,
          FunctionInvocationsQueryVariables
        >({
          ...queryInfo,
          data: currentLogs,
        })

        //  show the announcment badge only if the content of the page is scrolled down,
        //  the `500px` values is picked manually
        if (window.pageYOffset > 500 && !seeRecentLogs) {
          setSeeRecentLogs(true)
        }
      } catch (e) {
        console.error(e)
      }
    },
  })

  return [
    functionInvocations,
    {
      error,
      loading,
      loadMore,
      networkStatus,
      seeRecentLogs,
      setSeeRecentLogs,
    },
  ] as const
}

import * as React from "react"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import { PreviewLogin } from "@modules/login/preview"

export function SitePreviewAuth({ location }: RouteComponentProps) {
  if (!location) {
    return null
  }
  return <PreviewLogin location={location} />
}

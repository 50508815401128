import React from "react"
import ContentSection from "./ContentSection"
import { Spacer } from "gatsby-interface"
import Loading from "@modules/ui/components/Loading"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import { PersonalAccessTokensTable } from "./PersonalAccessTokensTable"
import { PersonalAccessTokenPanel } from "./PersonalAccessTokenPanel"
import { usePersonalAccessTokensQuery } from "@modules/user-settings/queries.generated"
import { userSettings as text } from "@modules/locales/default.js"

export type AddPersonalAccessTokenFormProps = {
  userId: string
  onComplete: () => void
  onCancel: () => void
}

const PanelState = {
  Closed: 0,
  AccessForm: 1,
  AccessToken: 2,
}

function PersonalAccessTokens() {
  const [panelState, setPanelState] = React.useState<number>(PanelState.Closed)
  const { data, loading, error, refetch } = usePersonalAccessTokensQuery()
  const tokens = data?.personalAccessTokens || []

  React.useEffect(() => {
    if (panelState === PanelState.Closed && refetch) {
      refetch()
    }
  }, [panelState])

  const onCancel = () => {
    setPanelState(PanelState.Closed)
  }
  const onClose = () => {
    setPanelState(PanelState.Closed)
  }

  const onComplete = () => {
    setPanelState(PanelState.AccessToken)
  }

  const handleClick = () => {
    setPanelState(PanelState.AccessForm)
  }

  if (loading) {
    return (
      <Loading variant="baby" message={text.personalAccessTokens.loading} />
    )
  }

  if (error) {
    return <ErrorAlert>{error.message}</ErrorAlert>
  }

  return (
    <ContentSection>
      <PersonalAccessTokensTable tokens={tokens} handleClick={handleClick} />
      <Spacer size={5} direction="vertical" />
      {panelState !== PanelState.Closed && (
        <PersonalAccessTokenPanel
          showPanel={panelState !== PanelState.Closed}
          onClose={onClose}
          onComplete={onComplete}
          onCancel={onCancel}
          showPersonalAccessForm={panelState === PanelState.AccessForm}
          showPersonalAccessToken={panelState === PanelState.AccessToken}
        />
      )}
    </ContentSection>
  )
}

export default PersonalAccessTokens

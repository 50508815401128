import React from "react"
import { BuildStatus } from "@modules/graphql/types"
import { DeploymentFail } from "./icons/DeploymentFail"
import { DeploymentPending } from "./icons/DeploymentPending"
import { DeploymentSuccess } from "./icons/DeploymentSuccess"

export function DeploymentStatus({ value }) {
  if (value === BuildStatus.Success) {
    return <DeploymentSuccess />
  }

  if (value === BuildStatus.PublishError) {
    return <DeploymentFail />
  }

  if (value === BuildStatus.PublishCanceled) {
    return <DeploymentFail title="Deploy canceled" />
  }

  if (value === BuildStatus.Publishing) {
    return <DeploymentPending />
  }

  return null
}

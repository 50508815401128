import React from "react"
import PropTypes from "prop-types"
import { BuildType, BuildRunnerType } from "@modules/graphql/types"
import { CmsVendorName } from "@modules/cms/shared/constants"
import { isBuildRunnerType } from "@modules/build/shared/utils"
import { buildStatus as buildStatusText } from "@modules/locales/default.js"

const buildTypeLabels = {
  [BuildType.OrganizationActiveUpdate]:
    buildStatusText.labels.organizationActive,
  [BuildType.SystemUpdate]: `Triggered by Gatsby Cloud`,
  [BuildType.SourceUpdate]: `Triggered by a commit`,
  [BuildType.DataUpdate]: `Triggered by Gatsby Preview webhook`,
  BUILDS_DATA_UPDATE: `Triggered by Gatsby Build webhook`,
  [BuildType.CdnUpdate]: `Triggered by a change to hosting configuration`,
  [BuildType.EnvVarUpdate]: `Triggered by an environment variables update`,
  [BuildType.ManualUpdate]: `Triggered by a manual rebuild`,
}

const propTypes = {
  buildType: PropTypes.oneOf(Object.values(BuildType)).isRequired,
  runnerType: PropTypes.oneOf(Object.values(BuildRunnerType)).isRequired,
}

export default function BuildTypeLabel({
  buildType,
  buildSource,
  runnerType,
  ...rest
}) {
  let labelKey = buildType

  if (buildType === BuildType.DataUpdate && isBuildRunnerType(runnerType)) {
    labelKey = `BUILDS_DATA_UPDATE`
  }

  let label = buildTypeLabels[labelKey]

  // Interim until we support all cmses as sources on backend
  if (
    buildSource &&
    buildType === BuildType.DataUpdate &&
    CmsVendorName[buildSource]
  ) {
    label = `Data update from ${CmsVendorName[buildSource]}`
  }

  return <span {...rest}>{label}</span>
}

BuildTypeLabel.propTypes = propTypes

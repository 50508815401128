import * as React from "react"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { EmptyState } from "gatsby-interface"
import { maintenance as maintenanceText } from "@modules/locales/default.js"
import { ServiceRouteLayout } from "./ServiceRouteLayout"

export function Maintenance() {
  return (
    <ServiceRouteLayout>
      <EmptyState
        heading={maintenanceText.headers.maintenanceHeader}
        text={maintenanceText.messages.maintenanceDescription}
        graphic={<EmptyStateGraphic />}
        variant="BORDERED"
      />
    </ServiceRouteLayout>
  )
}

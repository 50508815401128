import * as React from "react"
import {
  Modal,
  ModalCard,
  StyledModal,
  StyledModalBody,
  StyledModalActions,
  Button,
  StyledModalHeader,
  Text,
  CheckboxFieldBlock,
} from "gatsby-interface"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import {
  ui as uiText,
  errorAlert as errorText,
  deploysView as deploysViewText,
} from "@modules/locales/default.js"
import { useActivateHostingVersionMutation } from "@modules/build/shared/queries.generated"
import { SiteDetailsDocument } from "@modules/site/shared/queries.generated"
import { useToggleManualHostingDeploys } from "@modules/site/settings/hooks/useToggleManualHostingDeploys"

export type PublishBuildConfirmationProps = {
  siteId: string
  buildId: string
  onDismiss: () => void
  onComplete: () => void
  manualHostingDeploysEnabled?: boolean
}

export function PublishBuildConfirmation({
  siteId,
  buildId,
  onDismiss,
  onComplete,
  manualHostingDeploysEnabled,
}: PublishBuildConfirmationProps) {
  const [disableAutomaticBuilds, setDisableAutomaticBuilds] = React.useState<
    boolean
  >(true)
  const [toggleManualHostingDeploys] = useToggleManualHostingDeploys(siteId)
  const [activateHostingVersionMutation] = useActivateHostingVersionMutation({
    onCompleted: data => {
      if (
        data?.activateHostingVersion?.success &&
        disableAutomaticBuilds &&
        !manualHostingDeploysEnabled
      ) {
        toggleManualHostingDeploys()
      }
    },
  })
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [loading, setLoading] = React.useState(false)

  const handlePublish = async () => {
    setLoading(true)

    activateHostingVersionMutation({
      variables: {
        siteId,
        buildId,
      },
      refetchQueries: [
        {
          query: SiteDetailsDocument,
          variables: { id: siteId },
        },
      ],
    })
      .then(({ data }) => {
        setLoading(false)
        const success = data?.activateHostingVersion?.success ?? false

        if (success) {
          onComplete()
        }

        const message =
          data?.activateHostingVersion?.message ||
          errorText.messages.somethingWentWrong
        setError({ message })
      })
      .catch(e => {
        setLoading(false)
        setError(e)
      })
  }

  return (
    <Modal isOpen={Boolean(buildId)} onDismiss={onDismiss} type="info">
      <ModalCard>
        <StyledModal variant="ACTION">
          <StyledModalHeader onCloseButtonClick={onDismiss}>
            {deploysViewText.headers.publishBuild}
          </StyledModalHeader>

          <StyledModalBody>
            <Text>{deploysViewText.messages.thisBuildWillGoLive}</Text>
            {!manualHostingDeploysEnabled && (
              <CheckboxFieldBlock
                id="required"
                label={deploysViewText.labels.disableAutomaticDeploys}
                name="required"
                checked={disableAutomaticBuilds}
                onChange={() =>
                  setDisableAutomaticBuilds(!disableAutomaticBuilds)
                }
              />
            )}

            {errorAlert && (
              <div
                css={theme => ({
                  marginTop: theme.space[5],
                })}
              >
                {errorAlert}
              </div>
            )}

            <StyledModalActions>
              <Button type="button" onClick={onDismiss} variant="SECONDARY">
                {uiText.actions.cancel}
              </Button>
              <Button
                type="button"
                onClick={handlePublish}
                variant="PRIMARY"
                loading={loading}
                loadingLabel="Publish"
              >
                {deploysViewText.labels.publish}
              </Button>
            </StyledModalActions>
          </StyledModalBody>
        </StyledModal>
      </ModalCard>
    </Modal>
  )
}

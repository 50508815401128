import * as React from "react"
import IconSkeleton from "./IconSkeleton"
import { IconProps } from "./types"

export default function ProfessionalPlanIcon(props: IconProps) {
  return (
    <IconSkeleton {...props} iconName="ProfessionalPlanIcon" stroke="none">
      <g clipPath="url(#clip0_3159:2574)">
        <g clipPath="url(#clip1_3159:2574)">
          <path
            d="M3.28484 6.87293C3.53246 6.44404 3.97942 6.08259 4.59956 5.91642C5.29117 5.79153 6.19723 5.79045 7.13351 5.90211C9.13391 6.15161 11.6689 7.04336 14.1144 8.50291C16.6163 9.94736 18.5846 11.6556 19.8572 13.2482C20.4221 14.0032 20.8742 14.7884 21.0554 15.4649C21.2216 16.085 21.1884 16.6377 20.9408 17.0666C20.6932 17.4955 20.2462 17.857 19.5697 18.0382C18.9345 18.148 18.0435 18.2055 17.0358 18.0525C14.5847 17.6199 12.2361 16.7327 10.1112 15.4366C7.60938 13.9922 5.64107 12.2839 4.38352 10.7478C3.7472 9.95149 3.36659 9.20754 3.18532 8.53103C3.00405 7.85453 3.03722 7.30182 3.28484 6.87293V6.87293Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.112 3.22835C17.5409 3.47597 17.9023 3.92293 18.0836 4.59944C18.2085 5.29105 18.2509 6.12563 18.0979 7.13339C17.6768 9.60295 16.7945 11.9712 15.4971 14.1143C14.0376 16.5598 12.3444 18.5845 10.7931 19.7856C9.99687 20.422 9.25292 20.8026 8.57641 20.9838C7.8999 21.1651 7.36231 21.1883 6.93342 20.9407C6.50453 20.6931 6.14307 20.2461 5.9618 19.5696C5.83691 18.878 5.85093 18.0283 5.94749 17.0357C6.19699 15.0353 7.10385 12.5566 8.54829 10.0547C9.99274 7.55288 11.701 5.58457 13.3087 4.3683C14.0485 3.74708 14.8489 3.35136 15.5254 3.17009C16.1455 3.00393 16.6831 2.98073 17.112 3.22835V3.22835Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13.9999C13.1046 13.9999 14 13.1045 14 11.9999C14 10.8954 13.1046 9.99994 12 9.99994C10.8954 9.99994 10 10.8954 10 11.9999C10 13.1045 10.8954 13.9999 12 13.9999Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3159:2574">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_3159:2574">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(-1.47217 4.2218) rotate(-15)"
          />
        </clipPath>
      </defs>
    </IconSkeleton>
  )
}

import * as React from "react"
import { billing as text } from "@modules/locales/default.js"
import {
  ThemeCss,
  LinkButton,
  AnchorButton,
  Text,
  Heading,
  CheckIcon,
  Theme,
  VisuallyHidden,
} from "gatsby-interface"
import { PlanProfile, PlanSignup, SignupLabel } from "../constants/planProfiles"
import { MachinePricingTier, BillingInterval } from "@modules/graphql/types"
import { Interpolation } from "@emotion/core"
import { useTracker, SegmentEventType } from "@modules/analytics"

export type CardActionProps = {
  plan: PlanProfile
}

/* styles */

const actionCss: ThemeCss = theme => ({
  marginBottom: theme.space[6],
})

/* end for CardAction styles */

export type CardHeaderProps = {
  plan: PlanProfile
  billingInterval?: BillingInterval
  isRecommended?: boolean
  isCurrentPlan?: boolean
  currentPlanOnTrial?: boolean
  isStatic?: boolean
}

export function CardHeader({
  plan,
  billingInterval,
  isRecommended,
  isCurrentPlan,
  currentPlanOnTrial,
  isStatic,
}: CardHeaderProps) {
  const { trackSegment } = useTracker()

  const Icon = plan?.icon

  const isEnterprise = plan?.tier === MachinePricingTier.Enterprise

  const price = billingInterval && plan?.price?.[billingInterval]

  const nextStep =
    plan.signupType === PlanSignup.ViaSales
      ? `/contact-sales`
      : `https://app.netlify.com/signup`

  const signupLabel = SignupLabel?.[plan?.signupType]

  const formattedPrice =
    price && typeof price === "number"
      ? price % 100 === 0 // add decimal if price is not on the dollar
        ? price / 100
        : (price / 100).toFixed(2)
      : 0

  const isEmphasized = isRecommended

  const handleClick = () => {
    trackSegment({
      type: SegmentEventType.Track,
      event: `${signupLabel} Clicked`,
      properties: {
        location: "Pricing Page > Plan Selector",
        plan: plan.name,
      },
    })
  }

  return (
    <div css={headerWrapperCss}>
      <header css={headerCss}>
        <div>
          <h3 css={theme => nameCss(theme, isRecommended)}>
            <strong>{plan?.name}</strong>{" "}
            <VisuallyHidden>plan -</VisuallyHidden>
            {isRecommended && (
              <span css={badgeCss}>{text.labels.mostPopular} </span>
            )}
            {isCurrentPlan && (
              <span css={badgeCss}>
                {text.labels.current} {currentPlanOnTrial && text.labels.trial}
              </span>
            )}
          </h3>

          <div css={priceCss}>
            {isEnterprise ? (
              `Custom`
            ) : (
              <React.Fragment>
                <strong>${formattedPrice}</strong>{" "}
                <small> /{text.morphemes.month}</small>
              </React.Fragment>
            )}
          </div>
        </div>
        <Icon css={theme => iconCss({ theme, isEmphasized })} />
      </header>

      <Text size="S" css={descriptionCss}>
        {plan?.description}
      </Text>

      {isStatic && (
        <>
          {plan.signupType === PlanSignup.Netlify ? (
            <AnchorButton
              variant={`PRIMARY`}
              href="https://app.netlify.com/signup"
              css={actionCss}
              onClick={handleClick}
            >
              {signupLabel}
            </AnchorButton>
          ) : (
            <LinkButton
              variant={`PRIMARY`}
              to={nextStep}
              css={actionCss}
              onClick={handleClick}
            >
              {signupLabel}
            </LinkButton>
          )}
        </>
      )}
    </div>
  )
}

/*  styles */

const headerWrapperCss: ThemeCss = theme => ({
  padding: theme.space[7],
  paddingBottom: 0,
})

const headerCss: ThemeCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `1fr auto`,
  gap: theme.space[3],
  fontSize: theme.fontSizes[4],
  minHeight: `6rem`,

  strong: {
    opacity: `var(--cardOpacity)`,
  },

  span: {
    display: `block`,
  },
})

const nameCss = (theme: Theme, isEmphasized?: boolean): Interpolation => [
  {
    margin: 0,
    fontWeight: theme.fontWeights.semiBold,
    position: `relative`,
  },
  isEmphasized && {
    color: theme.colors.purple[50],
  },
]

const badgeCss: ThemeCss = theme => ({
  position: `absolute`,
  top: 0,
  transform: `translateY(-3.4rem)`,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.body,
  color: theme.colors.purple[60],
})

const iconCss = ({
  theme,
  isEmphasized,
}: {
  theme: Theme
  isEmphasized?: boolean
}) => [
  {
    width: theme.space[8],
    height: `auto`,
  },
  isEmphasized && {
    color: theme.colors.purple[50],
  },
]

const priceCss: ThemeCss = theme => ({
  opacity: `var(--cardOpacity)`,
  color: theme.colors.grey[50],

  strong: {
    color: theme.colors.grey[90],
  },

  small: {
    color: theme.colors.grey[60],
    fontSize: theme.fontSizes[0],
  },
})

const trialCss: ThemeCss = theme => ({
  margin: 0,
  marginTop: theme.space[2],
})

const descriptionCss: ThemeCss = theme => ({
  opacity: `var(--cardOpacity)`,
  margin: 0,
  minHeight: `4rem`,
  marginBottom: theme.space[6],
  color: theme.colors.grey[80],

  a: {
    display: `inline`,
  },
})

/* end of CardHeader styles */

export type CardDetailsProps = {
  heading?: string
  qualities?: string[]
  isEmphasized?: boolean
}

export function CardDetails({
  heading,
  qualities,
  isEmphasized,
}: CardDetailsProps) {
  if (!qualities) {
    return null
  }

  return (
    <div css={theme => qualitiesCss({ theme, isEmphasized })}>
      <Heading css={detailsHeadingCss} as="h4">
        {heading}:
      </Heading>

      <ul>
        {qualities.map((quality, idx: number) => {
          return (
            <li key={idx}>
              <CheckIcon /> <span>{quality}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

/* styles */

const detailsHeadingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
})

const qualitiesCss = ({
  theme,
  isEmphasized,
}: {
  theme: Theme
  isEmphasized?: Boolean
}): Interpolation => ({
  opacity: `var(--cardOpacity)`,
  color: theme.colors.grey[80],
  background: isEmphasized ? theme.colors.purple[10] : theme.colors.grey[10],
  padding: theme.space[7],
  paddingBottom: theme.space[8],
  borderRadius: `0 0 ${theme.radii[2]} ${theme.radii[2]}`,

  ul: {
    listStyle: `none`,
    padding: 0,
    margin: 0,
    marginTop: theme.space[4],
    fontSize: theme.fontSizes[1],
    lineHeight: theme.lineHeights.default,
    display: `grid`,
    gap: theme.space[3],
  },

  li: {
    margin: 0,
    display: `grid`,
    gridTemplateColumns: `auto 1fr`,
  },

  svg: {
    position: `relative`,
    transform: `translate(-25%, 10%)`,
    width: theme.space[6],
    height: `auto`,
  },
})

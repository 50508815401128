import { useSubscription } from "react-apollo"
import { BuildStatus } from "@modules/graphql/types"
import { BUILD_CHANGED_SUBSCRIPTION } from "@modules/build/shared/queries"

export default function useBuildChangedSubscription(
  id,
  runnerType,
  onBuildSucceed
) {
  useSubscription(BUILD_CHANGED_SUBSCRIPTION, {
    variables: {
      id,
      runnerType,
    },
    skip: !runnerType,
    onSubscriptionData: data => {
      const test =
        data?.subscriptionData?.data?.buildChanged?.buildStatus ===
          BuildStatus.Success &&
        data?.subscriptionData?.data.buildChanged?.deployEndedAt

      if (onBuildSucceed && test) {
        onBuildSucceed()
      }
    },
  })
}

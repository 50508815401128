// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import { BuildFieldsFragment } from '../../build/shared/fragments.generated';
import gql from 'graphql-tag';
import { BuildFieldsFragmentDoc } from '../../build/shared/fragments.generated';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type GetBuildQueueItemsForWorkspaceQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['UUID'];
  pagination?: Types.Maybe<Types.CursorPagination>;
}>;


export type GetBuildQueueItemsForWorkspaceQuery = (
  { __typename?: 'Query' }
  & { getBuildQueueItemsForWorkspace?: Types.Maybe<(
    { __typename?: 'QueueItemResult' }
    & { buildQueueItems?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'QueueItem' }
      & Pick<Types.QueueItem, 'id'>
      & { site?: Types.Maybe<(
        { __typename?: 'Site' }
        & Pick<Types.Site, 'id' | 'publicName'>
      )>, build?: Types.Maybe<(
        { __typename?: 'Build' }
        & BuildFieldsFragment
      )> }
    )>>>, pageInfo?: Types.Maybe<(
      { __typename?: 'CursorPageInfo' }
      & Pick<Types.CursorPageInfo, 'hasNextPage' | 'endCursor'>
    )> }
  )> }
);

export type WorkspaceHasQueuedItemsSubscriptionVariables = Types.Exact<{
  workspaceId: Types.Scalars['UUID'];
}>;


export type WorkspaceHasQueuedItemsSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Types.Subscription, 'workspaceHasQueuedItems'>
);


export const GetBuildQueueItemsForWorkspaceDocument = gql`
    query getBuildQueueItemsForWorkspace($workspaceId: UUID!, $pagination: CursorPagination) {
  getBuildQueueItemsForWorkspace(
    workspaceId: $workspaceId
    pagination: $pagination
  ) {
    buildQueueItems {
      id
      site {
        id
        publicName
      }
      build {
        ...buildFields
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${BuildFieldsFragmentDoc}`;

/**
 * __useGetBuildQueueItemsForWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetBuildQueueItemsForWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildQueueItemsForWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildQueueItemsForWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBuildQueueItemsForWorkspaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBuildQueueItemsForWorkspaceQuery, GetBuildQueueItemsForWorkspaceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBuildQueueItemsForWorkspaceQuery, GetBuildQueueItemsForWorkspaceQueryVariables>(GetBuildQueueItemsForWorkspaceDocument, baseOptions);
      }
export function useGetBuildQueueItemsForWorkspaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBuildQueueItemsForWorkspaceQuery, GetBuildQueueItemsForWorkspaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBuildQueueItemsForWorkspaceQuery, GetBuildQueueItemsForWorkspaceQueryVariables>(GetBuildQueueItemsForWorkspaceDocument, baseOptions);
        }
export type GetBuildQueueItemsForWorkspaceQueryHookResult = ReturnType<typeof useGetBuildQueueItemsForWorkspaceQuery>;
export type GetBuildQueueItemsForWorkspaceLazyQueryHookResult = ReturnType<typeof useGetBuildQueueItemsForWorkspaceLazyQuery>;
export type GetBuildQueueItemsForWorkspaceQueryResult = ApolloReactCommon.QueryResult<GetBuildQueueItemsForWorkspaceQuery, GetBuildQueueItemsForWorkspaceQueryVariables>;
export const WorkspaceHasQueuedItemsDocument = gql`
    subscription workspaceHasQueuedItems($workspaceId: UUID!) {
  workspaceHasQueuedItems(workspaceId: $workspaceId)
}
    `;

/**
 * __useWorkspaceHasQueuedItemsSubscription__
 *
 * To run a query within a React component, call `useWorkspaceHasQueuedItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceHasQueuedItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceHasQueuedItemsSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceHasQueuedItemsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<WorkspaceHasQueuedItemsSubscription, WorkspaceHasQueuedItemsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<WorkspaceHasQueuedItemsSubscription, WorkspaceHasQueuedItemsSubscriptionVariables>(WorkspaceHasQueuedItemsDocument, baseOptions);
      }
export type WorkspaceHasQueuedItemsSubscriptionHookResult = ReturnType<typeof useWorkspaceHasQueuedItemsSubscription>;
export type WorkspaceHasQueuedItemsSubscriptionResult = ApolloReactCommon.SubscriptionResult<WorkspaceHasQueuedItemsSubscription>;

import React from "react"

// Implements Net Promoter Surveys using Vitally.
// Docs: https://docs.vitally.io/using-vitallys-nps-surveys/installing-and-configuring-nps-surveys
const useNetPromoterSurvey = (organizationDetails, currentUser) => {
  const showSurvey =
    currentUser &&
    process.env.GATSBY_NPS_SETTING &&
    typeof window.Vitally != "undefined" &&
    organizationDetails

  React.useEffect(() => {
    if (!showSurvey) return

    const accountTraits = {
      name: organizationDetails?.name,
      buildsTier: organizationDetails?.billing?.plan?.buildsTier,
      hostingTier: organizationDetails?.billing?.plan?.hostingTier,
      inTrial:
        organizationDetails?.billing?.status === `TRIALING` ? true : false,
    }

    // Send account info to Vitally.
    window.Vitally.account({
      accountId: organizationDetails.Id,
      accountTraits,
    })

    // Send user info to Vitally.
    window.Vitally.user({
      userId: currentUser.id,
      accountId: organizationDetails?.id,
      traits: {
        name: currentUser.name,
        email: currentUser.email,
        createdAt: currentUser.createdAt,
      },
    })

    // Display the Net Promoter Survey.
    window.Vitally.nps(process.env.GATSBY_NPS_SETTING, {
      productName: "Gatsby Cloud",
      delay: 5000,
    })
  }, [currentUser, organizationDetails?.name])

  return null
}

export default useNetPromoterSurvey

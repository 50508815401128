import { ThemeCss } from "gatsby-interface"

export const twoColumnsCss: ThemeCss = theme => ({
  gap: theme.space[9],
  columnGap: theme.space[15],
  alignItems: `start`,

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `auto auto`,
  },
})

export const oneColumnCss: ThemeCss = theme => ({
  gap: theme.space[9],
  justifyItems: `center`,
})

import * as React from "react"
import {
  Heading,
  Text,
  ThemeCss,
  Button,
  LinkButton,
  RadioButtonFieldBlock,
  Link,
  Spacer,
} from "gatsby-interface"
import { PlanTag } from "@modules/billing/shared/components/PlanTag"
import { getPathToUpgradeWorkspacePlan } from "@modules/organization/shared/utils"
import { SimplyfiedOrg } from "../hooks/useAcceptSiteTransfer"
import { CustomOrg } from "../hooks/useUserOrganizationsBySites"
import { siteTransfer as text } from "@modules/locales/default.js"

export type TitleProps = {
  siteName?: string | null
  orgName?: string | null
}

export function Title({ siteName, orgName }: TitleProps) {
  return (
    <Heading as="h1" css={headingCss}>
      Transfer billing for {siteName ? <strong>{siteName}</strong> : `site`}
      {orgName && ` from `} {orgName && <strong>{orgName}</strong>}
    </Heading>
  )
}

export type IntroProps = {
  hasNoOrg?: boolean
  hasNoPaidOrg?: boolean
  hasNoPaidOrgWithRoom?: boolean
  senderEmail?: string | null
  senderName?: string | null
}

export function Intro({
  hasNoOrg = false,
  hasNoPaidOrg = false,
  hasNoPaidOrgWithRoom = false,
  senderEmail,
  senderName,
}: IntroProps) {
  return (
    <React.Fragment>
      <Text>
        The Gatsby Cloud user{" "}
        {senderName && (
          <React.Fragment>
            <strong>{senderName}</strong>{" "}
          </React.Fragment>
        )}{" "}
        {senderEmail && (
          <React.Fragment>
            (<Link href={`mailto:${senderEmail}`}>{senderEmail}</Link>)
          </React.Fragment>
        )}{" "}
        wants to transfer billing for a site to you.
      </Text>

      {hasNoOrg && (
        <Text>
          However, you do not own a Gatsby Cloud workspace to which you can
          transfer the billing of the site to yet. Create your first one to
          unblock the transfer.
        </Text>
      )}

      {hasNoPaidOrg && !hasNoOrg && (
        <Text>
          However, you do not have a single workspace eligible to transfer the
          site billing to. Upgrade or create a new one.
        </Text>
      )}

      {hasNoPaidOrgWithRoom && (
        <Text>
          However, any of your eligible workspaces has room for another site.
          Make some space, upgrade plan or create a new workspace.
        </Text>
      )}
    </React.Fragment>
  )
}

export type WorkspacesProps = {
  selectedOrganization?: SimplyfiedOrg
  withRoom?: CustomOrg[]
  withNoRoom?: CustomOrg[]
  free?: SimplyfiedOrg[]
  onSelect: (org: string) => void
  invitationId: string
}

export function Workspaces({
  selectedOrganization,
  withRoom = [],
  withNoRoom = [],
  free = [],
  onSelect,
  invitationId,
}: WorkspacesProps) {
  const upgradableOrgs = [...withNoRoom, ...free]

  return (
    <React.Fragment>
      {withRoom?.length > 0 && (
        <RadioButtonFieldBlock
          id="targetOrganization"
          name="targetOrganization"
          options={withRoom.map(item => ({
            label: (
              <div css={orgCss}>
                <strong>{item.name}</strong>
                <PlanTag name={item.billing?.plan?.name} />
              </div>
            ),
            value: item.name,
          }))}
          onChange={e => onSelect(e.target.value)}
          label="Select the workspace you want to transfer the site billing to:"
          variant="framed"
          value={selectedOrganization?.name}
          labelSize="L"
        />
      )}

      {withRoom.length > 0 && (withNoRoom.length > 0 || free.length > 0) && (
        <Divider />
      )}

      {upgradableOrgs.length > 0 && (
        <div css={upgradableCss}>
          {withNoRoom.map(org => (
            <div key={org.id} css={optionCss}>
              <div css={orgCss}>
                <strong>{org.name}</strong>
                <PlanTag name={org.billing?.plan?.name} />

                <Spacer size={10} direction="horizontal" />
                <Text size="S" noMarginBottom={true}>
                  no room for another site ({org?.usage?.taken}/
                  {org?.usage?.limit})
                </Text>
              </div>
              <LinkButton
                to={`${getPathToUpgradeWorkspacePlan(
                  org.id
                )}&siteTransfer=${invitationId}`}
                variant="SECONDARY"
                size="M"
              >
                {text.labels.upgrade}
              </LinkButton>
            </div>
          ))}

          {free.map(org => (
            <div key={org.id} css={optionCss}>
              <div css={orgCss}>
                <strong>{org.name}</strong>
                <PlanTag name={org.billing?.plan?.name} />
              </div>
              <LinkButton
                to={`${getPathToUpgradeWorkspacePlan(
                  org.id
                )}&siteTransfer=${invitationId}`}
                variant="SECONDARY"
                size="M"
              >
                {text.labels.upgrade}
              </LinkButton>
            </div>
          ))}
        </div>
      )}

      {(withRoom.length > 0 || withNoRoom.length > 0 || free.length > 0) && (
        <Divider />
      )}

      <div css={optionCss}>
        {text.labels.createNewOne}{" "}
        <LinkButton
          to={`/dashboard/organization/create?siteTransfer=${invitationId}`}
          variant="SECONDARY"
          size="M"
        >
          {text.labels.createWorkspace}
        </LinkButton>
      </div>
    </React.Fragment>
  )
}

export type NotesProps = {
  senderName?: string | null
  orgName?: string | null
}

export function Notes({ senderName, orgName }: NotesProps) {
  return (
    <div css={notesCss}>
      <Heading as="h3">
        Before you accept the transfer you should be aware of:
      </Heading>
      <ul>
        <li>The transfer will cause no downtime to the site.</li>
        <li>
          <b>{senderName}</b> and other members from{" "}
          <b>{orgName ? orgName : `the source workspace`}</b> will retain access
          to the site, enabling them to make changes on your behalf.
        </li>
      </ul>
    </div>
  )
}

export type ActionsProps = {
  selectedOrganization?: SimplyfiedOrg
  onDecline: () => void
  onAccept?: () => void
  onDismiss?: () => void
  transfering?: boolean
}

export function Actions({
  selectedOrganization,
  onDecline,
  onAccept,
  onDismiss,
  transfering,
}: ActionsProps) {
  return (
    <div css={actionsCss}>
      <LinkButton
        to="/dashboard/sites"
        onClick={onDismiss}
        variant="SECONDARY"
        tone="NEUTRAL"
        css={dismissActionCss}
      >
        {text.labels.dismissForNow}
      </LinkButton>

      <Button
        variant="SECONDARY"
        tone="DANGER"
        onClick={onDecline}
        css={declineActionCss}
      >
        {text.labels.declineTransfer}
      </Button>

      {onAccept && (
        <Button
          variant="PRIMARY"
          onClick={onAccept}
          css={acceptActionCss}
          disabled={!selectedOrganization || transfering}
          loading={transfering}
          loadingLabel="Transfering"
        >
          {text.labels.acceptTransfer}
        </Button>
      )}
    </div>
  )
}

export type WrapperProps = {
  children: React.ReactNode
}

export function Wrapper({ children }: WrapperProps) {
  return <div css={wrapperCss}>{children}</div>
}

function Divider() {
  return (
    <div css={dividerCss}>
      <span>{text.labels.or}</span>
    </div>
  )
}

/* styles */

export const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[5],
  marginTop: theme.space[13],
  marginBottom: theme.space[12],

  strong: {
    color: theme.colors.purple[60],
  },
})

const wrapperCss: ThemeCss = theme => ({
  maxWidth: `50rem`,
  lineHeight: theme.lineHeights.loose,

  p: {
    lineHeight: theme.lineHeights.loose,
  },
})

const actionsCss: ThemeCss = theme => ({
  marginTop: theme.space[10],
  display: `flex`,
})

const dismissActionCss: ThemeCss = theme => ({
  marginRight: theme.space[5],
})

const declineActionCss: ThemeCss = theme => ({
  marginLeft: `auto`,
})

const acceptActionCss: ThemeCss = theme => ({
  marginLeft: theme.space[5],
})

const optionCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
})

const orgCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,

  strong: {
    marginRight: theme.space[3],
  },
})

const upgradableCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[4],
})

const dividerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
  position: `relative`,
  padding: theme.space[3],

  span: {
    display: `inline-block`,
    padding: `0 ${theme.space[4]}`,
    backgroundColor: theme.colors.white,
    position: `relative`,
  },

  "&:before": {
    content: `""`,
    position: `absolute`,
    width: `100%`,
    height: `1px`,
    backgroundColor: theme.colors.grey[30],
    top: `50%`,
  },
})

const notesCss: ThemeCss = theme => ({
  marginTop: theme.space[12],

  h3: {
    fontSize: theme.fontSizes[2],
    marginBottom: theme.space[3],
  },

  li: {
    marginBottom: 0,
    lineHeight: theme.lineHeights.loose,
  },
})

import { BillingInterval } from "@modules/graphql/types"
import { planInformation as planInformationText } from "@modules/locales/default.js"

export enum SupportedPlanTier {
  Free = `Free`,
  Individual = `Individual`,
  Team = `Team`,
  Enterprise = `Enterprise`,
}

export type PlanInformation = {
  tier: SupportedPlanTier
  name: string
  description: string
  monthlyAmount: Record<BillingInterval, number | null>
  featuresIntro: string | null
  features: string[]
  cta: string
}

export const supportedPlansOrder = [
  SupportedPlanTier.Free,
  SupportedPlanTier.Individual,
  SupportedPlanTier.Team,
  SupportedPlanTier.Enterprise,
] as const

export const trialOptionPlansOrder = [
  SupportedPlanTier.Individual,
  SupportedPlanTier.Team,
  SupportedPlanTier.Free,
] as const

export const supportedPlans: Record<SupportedPlanTier, PlanInformation> = {
  Free: {
    tier: SupportedPlanTier.Free,
    name: planInformationText.morphemes.tier1PlanName,
    description: planInformationText.messages.tier1PlanDescription,
    featuresIntro: null,
    features: planInformationText.messages.tier1PlanFeatures,
    monthlyAmount: {
      MONTHLY: 0,
      ANNUAL: 0,
    },
    cta: planInformationText.actions.pickTier1,
  },
  Individual: {
    tier: SupportedPlanTier.Individual,
    name: planInformationText.morphemes.tier2PlanName,
    description: planInformationText.messages.tier2PlanDescription,
    featuresIntro: null,
    features: planInformationText.messages.tier2PlanFeatures,
    monthlyAmount: {
      MONTHLY: 1900,
      ANNUAL: 19400 / 12,
    },
    cta: planInformationText.actions.pickTier2,
  },
  Team: {
    tier: SupportedPlanTier.Team,
    name: planInformationText.morphemes.tier3PlanName,
    description: planInformationText.messages.tier3PlanDescription,
    featuresIntro: null,
    features: planInformationText.messages.tier3PlanFeatures,
    monthlyAmount: {
      MONTHLY: 9900,
      ANNUAL: 100980 / 12,
    },
    cta: planInformationText.actions.pickTier3,
  },
  Enterprise: {
    tier: SupportedPlanTier.Enterprise,
    name: planInformationText.morphemes.tier4PlanName,
    description: planInformationText.messages.tier4PlanDescription,
    featuresIntro: null,
    features: planInformationText.messages.tier4PlanFeatures,
    monthlyAmount: {
      MONTHLY: null,
      ANNUAL: null,
    },
    cta: planInformationText.actions.pickTier4,
  },
}

export const TRIAL_DAYS = 14

export const planFeatureCategories = {
  Features: `Features`,
  Collaboration: `Collaboration`,
  Login: `Login`,
  Provider: `Provider`,
  Deploy: `Deploy`,
}

export const planFeatures = [
  {
    title: planInformationText.headers.planFeatureCategoryFeature,
    id: planFeatureCategories.Features,
    items: [
      {
        title: planInformationText.messages.planFeatureRte,
        id: "rte",
        [SupportedPlanTier.Free]:
          planInformationText.messages.planRteIntervalsFree,
        [SupportedPlanTier.Individual]:
          planInformationText.messages.planRteIntervalsTeam,
        [SupportedPlanTier.Team]:
          planInformationText.messages.planRteIntervalsEnterprise,
        [SupportedPlanTier.Enterprise]: planInformationText.messages.byQuote,
      },
      {
        title: planInformationText.messages.planFeatureContentPreviews,
        id: "contentpreviews",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureDeployPreviews,
        id: "deploypreviews",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureLighthouseReports,
        id: "lighthouseReports",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureIncrementalBuilds,
        id: "incrementalBuilds",
        [SupportedPlanTier.Free]: false,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
    ],
  },
  {
    title: planInformationText.headers.planFeatureCategoryCollaboration,
    id: planFeatureCategories.Collaboration,
    items: [
      {
        title: planInformationText.messages.planFeatureEditors,
        id: "editors",
        [SupportedPlanTier.Free]: 1,
        [SupportedPlanTier.Individual]: 1,
        [SupportedPlanTier.Team]: 5,
        [SupportedPlanTier.Enterprise]: planInformationText.messages.byQuote,
      },
      {
        title: planInformationText.messages.planFeatureViewers,
        id: "viewers",
        [SupportedPlanTier.Free]: 0,
        [SupportedPlanTier.Individual]: 5,
        [SupportedPlanTier.Team]: 20,
        [SupportedPlanTier.Enterprise]: planInformationText.messages.byQuote,
      },

      {
        title: planInformationText.messages.planFeaturePrivateUrls,
        id: "privateurls",
        [SupportedPlanTier.Free]: false,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureCustomUrls,
        id: "customurls",
        [SupportedPlanTier.Free]: false,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
    ],
  },
  {
    title: planInformationText.headers.planFeatureCategoryLogin,
    id: planFeatureCategories.Login,
    items: [
      {
        title: planInformationText.messages.planFeatureGithub,
        id: "githublogin",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureGitlab,
        id: "gitlablogin",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureBitbucket,
        id: "bitbucketlogin",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureGoogle,
        id: "googlelogin",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
    ],
  },
  {
    title: planInformationText.headers.planFeatureCategoryProviders,
    id: planFeatureCategories.Provider,
    items: [
      {
        title: planInformationText.messages.planFeatureGithub,
        id: "githubprovider",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureGitlab,
        id: "gitlabprovider",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureBitbucket,
        id: "bitbucketprovider",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureGithubEnterprise,
        id: "githubenterprise",
        [SupportedPlanTier.Free]: false,
        [SupportedPlanTier.Individual]: false,
        [SupportedPlanTier.Team]: false,
        [SupportedPlanTier.Enterprise]: true,
      },

      {
        title: planInformationText.messages.planFeatureGitlabEnterprise,
        id: "gitlabenterprise",
        [SupportedPlanTier.Free]: false,
        [SupportedPlanTier.Individual]: false,
        [SupportedPlanTier.Team]: false,
        [SupportedPlanTier.Enterprise]: true,
      },
    ],
  },
  {
    title: planInformationText.headers.planFeatureCategoryDeploy,
    id: planFeatureCategories.Deploy,
    items: [
      {
        title: planInformationText.messages.planFeatureNetlify,
        id: "netlifydeploy",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureFastly,
        id: "fastlydeploy",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureFirebase,
        id: "firebasedeploy",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureAws,
        id: "awsdeploy",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureGoogleStorage,
        id: "googlestoragedeploy",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
      {
        title: planInformationText.messages.planFeatureZeit,
        id: "zeitdeploy",
        [SupportedPlanTier.Free]: true,
        [SupportedPlanTier.Individual]: true,
        [SupportedPlanTier.Team]: true,
        [SupportedPlanTier.Enterprise]: true,
      },
    ],
  },
]

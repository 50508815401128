import * as React from "react"
import * as Yup from "yup"
import { MdArrowForward } from "react-icons/md"
import { useTracker } from "@modules/analytics"
import { FullStoryClassName } from "../../../fullStory/constants"
import { UserWelcomeInput } from "@modules/graphql/types"
import {
  Button,
  InputConnectedField,
  RadioButtonConnectedField,
  SelectConnectedField,
  ThemeCss,
  Card,
  Heading,
} from "gatsby-interface"
import Form from "@modules/form/components/Form"
import { leadCapture as text, ui as uiText } from "@modules/locales/default.js"
import { Regions } from "../constants/regions"
import { projectTypeOptions } from "../constants/projectTypes"
import { getRegionOptions } from "../utils/getRegionOptions"
import { getCountryOptions } from "../utils/getCountryOptions"
import { boxCss } from "@modules/user/shared/stylesheets/box"
import { useUpdateUserWelcomeFieldsMutation } from "@modules/user/signup/queries.generated"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { useUtmFields } from "../hooks/useUtmFields"
import { getReferrerUrl } from "../utils/getReferrerUrl"
import { navigate } from "gatsby"
import { CurrentUserDocument } from "@modules/auth/queries.generated"
import {
  createLoginRedirectUrlObject,
  createLoginRedirectQuery,
} from "@modules/auth/components/AuthRedirect"
import * as qs from "query-string"
import { useLocation } from "@gatsbyjs/reach-router"

export type UserFormProps = {
  className?: string
  isRetake?: boolean
}

export const validationSchema = Yup.object<UserWelcomeInput>().shape({
  firstName: Yup.string().required(uiText.messages.validationIsRequired),
  lastName: Yup.string().required(uiText.messages.validationIsRequired),
  companyEmail: Yup.string()
    .email(uiText.messages.validationInvalidEmail)
    .required(uiText.messages.validationIsRequired),
  projectType: Yup.string().required(uiText.messages.validationIsRequired),
  country: Yup.string().required(uiText.messages.validationIsRequired),
  region: Yup.string().when("country", (country: string) => {
    // Only required if region is available for selected country
    if (Regions[country]) {
      return Yup.string().required(uiText.messages.validationIsRequired)
    }
  }),
})

export function UserForm({ className, isRetake = false }: UserFormProps) {
  const [updateUserWelcomeFieldsMutation] = useUpdateUserWelcomeFieldsMutation()
  const { trackButtonClicked } = useTracker()
  const [setError, errorAlert] = useTriggerErrorAlert()
  const { search, origin } = useLocation()

  const utmFields = useUtmFields()
  const referrerUrl = getReferrerUrl()

  // value is defined in context of existence of the loginRedirectUrl query param
  let nextStepPage: string

  const { loginRedirectUrl } = qs.parse(search)

  if (loginRedirectUrl) {
    const { search: redirectSearch, pathname } = createLoginRedirectUrlObject(
      origin,
      loginRedirectUrl
    )
    const stringifiedQuery = createLoginRedirectQuery(redirectSearch, true)

    nextStepPage = `${pathname}?${stringifiedQuery}`
  } else {
    nextStepPage = `/dashboard/sites?loggedin=1&new_user=1`
  }

  return (
    <Card css={boxCss} className={className} as="div">
      <Heading as="h2">Enter your details</Heading>

      {errorAlert}

      <Form<UserWelcomeInput>
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={{
          firstName: ``,
          lastName: ``,
          companyEmail: ``,
          projectType: ``,
          country: ``,
          region: ``,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          trackButtonClicked(`update welcome fields`, {
            uiSource: `Welcome form`,
          })

          updateUserWelcomeFieldsMutation({
            variables: {
              user: {
                ...values,
                ...utmFields,
                referrerUrl,
              },
            },
            refetchQueries: [
              {
                query: CurrentUserDocument,
              },
            ],
            awaitRefetchQueries: true,
          })
            .then(({ data }) => {
              if (data?.updateUserWelcomeFields?.success) {
                navigate(nextStepPage)
              }
            })
            .catch(err => setError(err))
        }}
      >
        {({ values, isSubmitting }) => (
          <Form.FormElement css={formCss} applySpacing noValidate>
            <InputConnectedField
              className={FullStoryClassName.Block}
              label={text.labels.firstName}
              name="firstName"
              required
            />
            <InputConnectedField
              className={FullStoryClassName.Block}
              label={text.labels.lastName}
              name="lastName"
              required
            />
            <InputConnectedField
              className={FullStoryClassName.Block}
              label={text.labels.workEmail}
              name="companyEmail"
              type="email"
              required
            />
            <SelectConnectedField
              className={FullStoryClassName.Block}
              label={text.labels.country}
              name="country"
              options={getCountryOptions()}
              required
            />
            {values.country && Regions[values.country] && (
              <SelectConnectedField
                className={FullStoryClassName.Block}
                label={text.labels.region}
                name="region"
                options={getRegionOptions(values.country)}
                required
              />
            )}

            <RadioButtonConnectedField
              name="projectType"
              options={projectTypeOptions}
              label={text.labels.iAmUsingGatsbyFor}
              css={typeFieldCss}
            />

            <Button
              type="submit"
              rightIcon={<MdArrowForward />}
              size="XL"
              width="FIT_CONTAINER"
              loading={isSubmitting}
              loadingLabel="Completing..."
            >
              {isRetake ? `Complete` : `Complete sign up`}
            </Button>
          </Form.FormElement>
        )}
      </Form>
    </Card>
  )
}

/* styles */

const formCss: ThemeCss = theme => ({
  rowGap: theme.space[5],
})

const typeFieldCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
  marginBottom: theme.space[2],
})

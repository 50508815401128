import { ThemeCss } from "gatsby-interface"

export const boxCss: ThemeCss = theme => ({
  display: `grid`,
  width: `100%`,
  padding: theme.space[6],
  gap: theme.space[7],
  borderRadius: theme.radii[4],
  boxShadow: theme.shadows.dialog,
  alignItems: `start`,

  "& > h1": {
    fontSize: theme.fontSizes[5],
    marginBottom: theme.space[4],

    "& > strong": {
      display: `block`,
      color: theme.colors.gatsby,
    },
  },

  "& > hr": {
    background: theme.colors.grey[30],
  },

  [theme.mediaQueries.desktop]: {
    width: `29rem`,
    padding: theme.space[8],
  },
})

export const btnCss: ThemeCss = theme => ({
  paddingTop: theme.space[4],
  paddingBottom: theme.space[4],
  borderRadius: theme.radii[3],
})

import * as React from "react"
import { Button, ThemeCss, Link, Text } from "gatsby-interface"
import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { MdArrowForward } from "react-icons/md"
import {
  createOrganization as createOrgText,
  ui as uiText,
} from "@modules/locales/default.js"
import { FormattedMessage } from "@modules/locales"
import {
  PlanInfo,
  SupportedPricingTier,
} from "@modules/billing/shared/constants/tiers"
import { NewPlanTierInfo } from "@modules/billing/shared/components/PlanTierInfo"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { PlanSelector } from "@modules/billing/shared/components/PlanSelector"
import { useAvailableBillingPlans } from "@modules/billing/shared/hooks/useAvailableBillingPlans"

const selectorCss: ThemeCss = theme => ({
  marginBottom: theme.space[10],
})

const selectionPreviewCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
  columnGap: theme.space[5],
  paddingTop: theme.space[8],
  marginBottom: `-${theme.space[12]}`,
  flexDirection: `column`,
  alignItems: `center`,

  [theme.mediaQueries.desktop]: {
    flexDirection: `row`,
  },
})

export type PickOrganizationPlanProps = {
  selectedPlanId: string | null
  selectedPlanName: string | null
  selectedWorkspaceName: string | null
  selectedBillingInterval: BillingInterval
  onGoNext: (planInfo: {
    [P in keyof PlanInfo]: Exclude<PlanInfo[P], null>
  }) => void
  onGoBack: () => void
}

export function PickOrganizationPlan({
  selectedPlanId,
  selectedPlanName,
  selectedWorkspaceName,
  onGoNext,
  onGoBack,
}: PickOrganizationPlanProps) {
  const [planInfo, setPlanInfo] = React.useState<PlanInfo>({
    planId: selectedPlanId,
    planName: selectedPlanName,
    billingInterval: BillingInterval.Annual,
  })

  const billingPlans = useAvailableBillingPlans()

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onGoNext({
          planId: planInfo.planId as string,
          planName: planInfo.planName as string,
          billingInterval: planInfo.billingInterval,
        })
      }}
    >
      <div
        css={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
        }}
      >
        <WizardStepHeader
          title={`Pick a plan for ${selectedWorkspaceName}`}
        ></WizardStepHeader>
        <StandardSingleColumn>
          <PlanSelector
            selectedPlan={planInfo}
            onChange={setPlanInfo}
            billingPlans={billingPlans.plans}
            freeIsUnavailable={true}
            professionalIsUnavailable={true}
            css={selectorCss}
          />
        </StandardSingleColumn>
        <Text>
          <FormattedMessage<never, "link">
            message={createOrgText.messages.customPlan}
            tags={{
              link: function (linkText) {
                return <Link to="/contact-sales">{linkText}</Link>
              },
            }}
          />
        </Text>
      </div>
      <WizardFooter
        goBackButton={
          <Button variant="SECONDARY" onClick={() => onGoBack()}>
            {uiText.actions.back}
          </Button>
        }
      ></WizardFooter>
    </form>
  )
}

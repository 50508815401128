import * as React from "react"
import { BuildStatus, BuildType } from "@modules/graphql/types"
import { useDebouncedInput } from "@modules/toolkit/hooks/useDebouncedInput"

export function useBuildsFilters() {
  const [
    buildStartDateFilter,
    setBuildStartDateFilter,
  ] = React.useState<Date | null>(null)
  const [
    buildEndDateFilter,
    setBuildEndDateFilter,
  ] = React.useState<Date | null>(null)
  const [
    buildStatusFilter,
    setBuildStatusFilter,
  ] = React.useState<BuildStatus | null>(null)
  const [
    buildTypeFilter,
    setBuildTypeFilter,
  ] = React.useState<BuildType | null>(null)
  const [
    buildKeywordFilter,
    debouncedBuildKeywordFilter,
    setBuildKeywordFilter,
    buildKeywordFilterIsDebouncing,
  ] = useDebouncedInput()

  const filteringIsActive =
    buildStartDateFilter ||
    buildEndDateFilter ||
    buildStatusFilter ||
    buildTypeFilter ||
    buildKeywordFilter

  return {
    buildStartDateFilter,
    setBuildStartDateFilter,
    buildEndDateFilter,
    setBuildEndDateFilter,
    buildStatusFilter,
    setBuildStatusFilter,
    buildTypeFilter,
    setBuildTypeFilter,
    buildKeywordFilter,
    debouncedBuildKeywordFilter,
    setBuildKeywordFilter,
    buildKeywordFilterIsDebouncing,
    filteringIsActive,
  }
}

// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import { BuildFieldsFragment } from '../../build/shared/fragments.generated';
import gql from 'graphql-tag';
import { BuildFieldsFragmentDoc } from '../../build/shared/fragments.generated';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type PullRequestsForSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
  pagination?: Types.Maybe<Types.CursorPagination>;
}>;


export type PullRequestsForSiteQuery = (
  { __typename?: 'Query' }
  & { pullRequestsForSite?: Types.Maybe<(
    { __typename?: 'PullRequestResults' }
    & { pullRequests?: Types.Maybe<Array<Types.Maybe<(
      { __typename: 'PullRequest' }
      & Pick<Types.PullRequest, 'id'>
      & { latestBuild?: Types.Maybe<(
        { __typename?: 'Build' }
        & BuildFieldsFragment
      )> }
    )>>>, pageInfo?: Types.Maybe<(
      { __typename?: 'CursorPageInfo' }
      & Pick<Types.CursorPageInfo, 'hasNextPage' | 'endCursor'>
    )> }
  )> }
);

export type PullRequestsForSiteWithSearchQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
  pagination?: Types.Maybe<Types.CursorPagination>;
  searchText?: Types.Maybe<Types.Scalars['String']>;
  status?: Types.Maybe<Types.PullRequestStatus>;
}>;


export type PullRequestsForSiteWithSearchQuery = (
  { __typename?: 'Query' }
  & { pullRequestsForSite?: Types.Maybe<(
    { __typename?: 'PullRequestResults' }
    & { pullRequests?: Types.Maybe<Array<Types.Maybe<(
      { __typename: 'PullRequest' }
      & Pick<Types.PullRequest, 'id'>
      & { latestBuild?: Types.Maybe<(
        { __typename?: 'Build' }
        & BuildFieldsFragment
      )> }
    )>>>, pageInfo?: Types.Maybe<(
      { __typename?: 'CursorPageInfo' }
      & Pick<Types.CursorPageInfo, 'hasNextPage' | 'endCursor'>
    )> }
  )> }
);


export const PullRequestsForSiteDocument = gql`
    query pullRequestsForSite($siteId: UUID!, $pagination: CursorPagination) {
  pullRequestsForSite(siteId: $siteId, pagination: $pagination) {
    pullRequests {
      __typename
      id
      latestBuild {
        ...buildFields
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${BuildFieldsFragmentDoc}`;

/**
 * __usePullRequestsForSiteQuery__
 *
 * To run a query within a React component, call `usePullRequestsForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullRequestsForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullRequestsForSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePullRequestsForSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PullRequestsForSiteQuery, PullRequestsForSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<PullRequestsForSiteQuery, PullRequestsForSiteQueryVariables>(PullRequestsForSiteDocument, baseOptions);
      }
export function usePullRequestsForSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PullRequestsForSiteQuery, PullRequestsForSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PullRequestsForSiteQuery, PullRequestsForSiteQueryVariables>(PullRequestsForSiteDocument, baseOptions);
        }
export type PullRequestsForSiteQueryHookResult = ReturnType<typeof usePullRequestsForSiteQuery>;
export type PullRequestsForSiteLazyQueryHookResult = ReturnType<typeof usePullRequestsForSiteLazyQuery>;
export type PullRequestsForSiteQueryResult = ApolloReactCommon.QueryResult<PullRequestsForSiteQuery, PullRequestsForSiteQueryVariables>;
export const PullRequestsForSiteWithSearchDocument = gql`
    query pullRequestsForSiteWithSearch($siteId: UUID!, $pagination: CursorPagination, $searchText: String, $status: PullRequestStatus) {
  pullRequestsForSite(
    siteId: $siteId
    pagination: $pagination
    searchText: $searchText
    status: $status
  ) {
    pullRequests {
      __typename
      id
      latestBuild {
        ...buildFields
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${BuildFieldsFragmentDoc}`;

/**
 * __usePullRequestsForSiteWithSearchQuery__
 *
 * To run a query within a React component, call `usePullRequestsForSiteWithSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullRequestsForSiteWithSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullRequestsForSiteWithSearchQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePullRequestsForSiteWithSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PullRequestsForSiteWithSearchQuery, PullRequestsForSiteWithSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<PullRequestsForSiteWithSearchQuery, PullRequestsForSiteWithSearchQueryVariables>(PullRequestsForSiteWithSearchDocument, baseOptions);
      }
export function usePullRequestsForSiteWithSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PullRequestsForSiteWithSearchQuery, PullRequestsForSiteWithSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PullRequestsForSiteWithSearchQuery, PullRequestsForSiteWithSearchQueryVariables>(PullRequestsForSiteWithSearchDocument, baseOptions);
        }
export type PullRequestsForSiteWithSearchQueryHookResult = ReturnType<typeof usePullRequestsForSiteWithSearchQuery>;
export type PullRequestsForSiteWithSearchLazyQueryHookResult = ReturnType<typeof usePullRequestsForSiteWithSearchLazyQuery>;
export type PullRequestsForSiteWithSearchQueryResult = ApolloReactCommon.QueryResult<PullRequestsForSiteWithSearchQuery, PullRequestsForSiteWithSearchQueryVariables>;

import * as React from "react"
import { SelectFieldBlock } from "gatsby-interface"
import {
  BuildType,
  SourceControlProvider,
  CmsProvider,
} from "@modules/graphql/types"
import { sourceControlProviderLabels } from "@modules/sourceControlProvider"

function getOptions({
  sourceProvider,
  cmsIntegrations,
}: {
  sourceProvider?: SourceControlProvider | null
  cmsIntegrations?: CmsProvider[]
}) {
  const sourceUpdateNamedLabel =
    sourceProvider && sourceControlProviderLabels[sourceProvider]

  return [
    {
      label: "All",
      value: ``,
    },

    {
      label: "Triggered manually",
      value: BuildType.ManualUpdate,
    },

    {
      label: sourceUpdateNamedLabel || "Source update",
      value: BuildType.SourceUpdate,
    },

    {
      label: "Data update",
      value: BuildType.DataUpdate,
    },
  ]
}

export type BuildTypeFilterProps = {
  value: BuildType | null
  onChange: React.Dispatch<React.SetStateAction<BuildType | null>>
  className?: string
  sourceProvider?: SourceControlProvider | null
  cmsIntegrations?: CmsProvider[]
}

export function BuildTypeFilter(props: BuildTypeFilterProps) {
  const { value, onChange, className, sourceProvider, cmsIntegrations } = props

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === `` ? null : (e.target.value as BuildType)
    return onChange(value)
  }

  const options = getOptions({ sourceProvider, cmsIntegrations })

  return (
    <SelectFieldBlock
      id="build-type-filter"
      label="Build type"
      labelSize="S"
      options={options}
      value={value === null ? `` : value}
      onChange={handleChange}
      className={className}
    />
  )
}

import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { MdTune, MdAutorenew } from "react-icons/md"
import { keyframes } from "@emotion/core"

export type LoadingIndicatorProps = {
  loading: boolean
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { loading = false } = props

  return (
    <div
      css={theme => [loadingIndicatorCss(theme), loading && loadingCss(theme)]}
    >
      {loading ? <MdAutorenew /> : <MdTune />}
    </div>
  )
}

/* styles */

const loadingIndicatorCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
  color: theme.colors.grey[50],
  marginRight: theme.space[2],
  borderRadius: `50%`,
  flexShrink: 0,
  width: `1.5rem`,
  height: `1.5rem`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  marginTop: `1.6rem`,
})

const rotate = keyframes`
  to {
    transform:rotate(360deg);
  }
`

const loadingCss: ThemeCss = theme => ({
  color: theme.colors.purple[60],
  background: theme.colors.purple[20],
  boxShadow: `0 0 5px 1px ${theme.colors.purple[20]}, 0 0 8px 0 ${theme.colors.purple[20]}`,

  svg: {
    animation: `${rotate} 1s ease infinite`,
  },
})

import React from "react"
import {
  Heading,
  Button,
  Spacer,
  StyledPanelBoilerplate,
  StyledPanelBodySection,
  InputConnectedField,
  StyledModalActions,
} from "gatsby-interface"
import { useCreatePersonalAccessTokenMutation } from "@modules/user-settings/queries.generated"
import { Form, FormElement } from "@modules/form/components/Form"
type FormValues = {
  name: string
}
import { ui as uiText, userSettings as text } from "@modules/locales/default.js"
import CopyUrl from "@modules/ui/components/CopyUrl"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"

export function PersonalAccessTokenPanel({
  showPanel,
  onClose,
  onComplete,
  onCancel,
  showPersonalAccessForm,
  showPersonalAccessToken,
}: {
  showPanel: boolean
  onClose: () => void
  onComplete: () => void
  onCancel: () => void
  showPersonalAccessForm: boolean
  showPersonalAccessToken: boolean
}) {
  const [createPersonalAccessTokenMutation] =
    useCreatePersonalAccessTokenMutation()

  const [tokenValue, setTokenValue] = React.useState<string>("")
  const [tokenName, setTokenName] = React.useState<string>("")
  const [setError, errorAlert] = useTriggerErrorAlert()

  const handleToken = ({ token, name }: { token: string; name: string }) => {
    setTokenValue(token)
    setTokenName(name)
    onComplete()
  }

  return (
    <StyledPanelBoilerplate
      isOpen={showPanel}
      aria-label="Add Personal Access Token"
      onClose={onClose}
      header="Add Personal Access Token"
    >
      <StyledPanelBodySection>
        {showPersonalAccessForm && (
          <div>
            <Heading as="h2" css={theme => ({ fontSize: theme.fontSizes[4] })}>
              {text.personalAccessTokens.addHeading}
            </Heading>
            <Spacer size={4} direction="vertical" />
            <Form<FormValues>
              initialValues={{
                name: ``,
              }}
              onSubmit={values => {
                return createPersonalAccessTokenMutation({
                  variables: {
                    name: values.name,
                  },
                })
                  .then(({ data }) => {
                    const token = data?.createPersonalAccessToken?.token
                    if (token) {
                      handleToken({ token, name: values.name })
                    } else {
                      setError({
                        message: text.personalAccessTokens.tokenCreationError,
                      })
                    }
                  })
                  .catch(err => {
                    return setError(err)
                  })
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <FormElement applySpacing noValidate>
                    <InputConnectedField
                      label="Name of Token"
                      name="name"
                      required
                    />
                    {errorAlert}
                    <StyledModalActions>
                      <Button
                        variant="SECONDARY"
                        type="reset"
                        onClick={onCancel}
                      >
                        {uiText.actions.cancel}
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingLabel={uiText.messages.saving}
                      >
                        {uiText.actions.save}
                      </Button>
                    </StyledModalActions>
                  </FormElement>
                )
              }}
            </Form>
          </div>
        )}

        {showPersonalAccessToken && tokenValue && (
          <div>
            <p>
              {text.personalAccessTokens.tokenCreatedContent}
              <strong>{tokenName}</strong>.
            </p>
            <p>{text.personalAccessTokens.tokenCreatedWarning}</p>
            <Spacer size={3} direction="vertical" />
            <CopyUrl url={tokenValue} data-cy-name={`token`} />
          </div>
        )}
      </StyledPanelBodySection>
    </StyledPanelBoilerplate>
  )
}

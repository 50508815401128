import * as React from "react"
import IconSkeleton from "./IconSkeleton"
import { IconProps } from "./types"

export default function AgencyPlanIcon(props: IconProps) {
  return (
    <IconSkeleton {...props} iconName="AgencyPlanIcon">
      <path
        d="M19.214 4.94203C19.541 5.26797 19.7298 5.74908 19.6835 6.47042C19.6783 7.12167 19.479 7.94306 19.1269 8.8645C18.0605 11.0778 16.5845 13.0764 14.7755 14.7567C12.7974 16.7637 10.6157 18.2344 8.71865 18.979C7.86873 19.384 7.07152 19.5385 6.37131 19.608C5.64157 19.5672 5.12061 19.4114 4.79366 19.0855C4.45195 18.7044 4.26314 18.2233 4.32423 17.5571C4.31466 16.8507 4.51388 16.0293 4.88077 15.163C5.69678 13.2902 7.16869 11.1825 9.21745 9.2157C11.1956 7.20873 13.3772 5.73798 15.2184 5.00833C16.139 4.64351 16.9773 4.41888 17.6364 4.41951C18.3514 4.40517 18.8723 4.56096 19.214 4.94203V4.94203Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.18151 9.44506C2.30294 8.99968 2.67849 8.60416 3.25201 8.27355C3.8817 7.9279 4.66793 7.71722 5.65213 7.57146C7.63528 7.33497 10.2713 7.51332 13.04 8.1869C15.7674 8.93057 18.1044 10.0147 19.6818 11.1844C20.506 11.7893 21.0908 12.3993 21.4215 12.9595C21.823 13.5596 21.8996 14.0699 21.7781 14.5153C21.6153 15.0307 21.2959 15.4112 20.6662 15.7569C20.0927 16.0875 19.2503 16.3132 18.2514 16.4039C16.283 16.6954 13.6469 16.5171 10.8782 15.8435C8.50837 15.2493 6.27551 14.2279 4.29258 12.8309C3.52456 12.211 2.88361 11.616 2.55294 11.0559C2.20753 10.4407 2.07483 9.94547 2.23767 9.43001L2.18151 9.44506Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8634 10.9647C16.6029 13.7245 16.8059 16.2731 16.5368 18.1791C16.3817 19.1672 16.1262 20.0047 15.7968 20.5662C15.4821 21.183 15.0376 21.5387 14.5898 21.6587C14.0861 21.7937 13.5792 21.6929 12.9984 21.3161C12.3763 21.0095 11.8071 20.4521 11.1639 19.6188C9.74811 17.6321 8.72249 15.3989 8.13985 13.0342C7.40036 10.2744 7.19737 7.72584 7.46647 5.81982C7.62161 4.83171 7.82112 4.00919 8.20652 3.43265C8.52116 2.81592 8.90974 2.47516 9.41346 2.34019C9.8612 2.22022 10.4241 2.30603 11.0049 2.68282C11.571 3.00441 12.1962 3.54679 12.8394 4.38014C14.0255 5.89625 15.0828 8.27509 15.8075 10.9797L15.8634 10.9647Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14C13.1046 14 14 13.1045 14 12C14 10.8954 13.1046 9.99997 12 9.99997C10.8954 9.99997 10 10.8954 10 12C10 13.1045 10.8954 14 12 14Z"
        fill="currentColor"
      />
    </IconSkeleton>
  )
}

import { useUserOrganizationsQuery } from "@modules/header/queries.generated"
import { CloudRole } from "@modules/graphql/types"
import { SimplyfiedOrg } from "./useAcceptSiteTransfer"

export function useUserOrganizationsByPlan() {
  const { data, loading, error } = useUserOrganizationsQuery({
    fetchPolicy: `cache-and-network`,
  })

  const organizationsUserBlongsTo = data?.currentOrganizations || []

  const organizationsUserOwns =
    organizationsUserBlongsTo?.filter(
      org => org.permissions?.role === CloudRole.Owner
    ) || []

  const organizationsOnPaidPlanUserOwns =
    organizationsUserOwns.filter(org => org.billing?.plan?.name !== `Free`) ||
    []

  const organizationsOnFreePlanUserOwns =
    organizationsUserOwns.filter(org => org.billing?.plan?.name === `Free`) ||
    []

  return {
    userBlongsTo: organizationsUserBlongsTo as SimplyfiedOrg[],
    userOwns: organizationsUserOwns as SimplyfiedOrg[],
    onPaidPlanUserOwns: organizationsOnPaidPlanUserOwns as SimplyfiedOrg[],
    onFreePlanUserOwns: organizationsOnFreePlanUserOwns as SimplyfiedOrg[],
    loading,
    error,
  }
}

import * as React from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import * as qs from "query-string"

export type AuthError = {
  message: string
}

enum QueryError {
  InvitationError = `invitation-error`,
  InvitationMismatch = `invitation-mismatch`,
  OauthError = `oauth-error`,
  InvitationRequired = `invitation-required`,
  UserBlocked = `blocked`,
  InvitationExpired = `invitation_expired`,
  InvitationAccepted = `invitation_accepted`,
  PreviewAccessError = `preview-access`,
  TicketNotFoundError = `ticket-not-found`,
}

const Errors: Record<QueryError, AuthError> = {
  [QueryError.OauthError]: {
    message: `<strong>Failed to connect.</strong> Try again or <button>contact support</button>.`,
  },
  [QueryError.InvitationError]: {
    message: `<strong>No matching invite found.</strong> Talk to the invitation sender or <button>contact support</button>.`,
  },
  [QueryError.InvitationMismatch]: {
    message: `<strong>No matching invite found.</strong> Talk to the invitation sender or <button>contact support</button>.`,
  },
  [QueryError.InvitationRequired]: {
    message: `<strong>No matching invite found.</strong> Talk to the invitation sender or <button>contact support</button>.`,
  },
  [QueryError.UserBlocked]: {
    message: `<strong>This account has been blocked.</strong> If you think you have been blocked by mistake, <button>contact support</button>.`,
  },
  [QueryError.InvitationExpired]: {
    message: `<strong>Your invitation has expired.</strong> Talk to the invitation sender or <button>contact support</button>.`,
  },
  [QueryError.InvitationAccepted]: {
    message: `<strong>Your invitation has already been accepted.</strong> Log in below or <button>contact support</button>.`,
  },
  [QueryError.PreviewAccessError]: {
    message: `<strong>You do not have permissions to view this Preview.</strong> Talk to your workspace admin or <button>contact support</button>.`,
  },
  [QueryError.TicketNotFoundError]: {
    message: `<strong>Your login has expired.</strong> Talk to your workspace admin or <button>contact support</button>`,
  },
}

export function useAuthError() {
  const [error, setError] = React.useState<AuthError | null>(null)
  const { search } = useLocation()
  const { error: queryError } = qs.parse(search)

  React.useEffect(() => {
    const err = queryError as QueryError

    if (Errors[err]) {
      setError(Errors[err])
    } else {
      setError(null)
    }
  }, [queryError])

  return error
}

import * as React from "react"
import IconSkeleton from "./IconSkeleton"
import { IconProps } from "./types"
import { useTheme } from "../ThemeProvider"

export default function EnterprisePlanIcon(props: IconProps) {
  const theme = useTheme()

  return (
    <IconSkeleton {...props} iconName="EnterprisePlanIcon" stroke="none">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={theme.colors.purple[20]}
      />
      <g clipPath="url(#clip0_174_474)">
        <path
          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
          fill="currentColor"
        />
        <path
          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
          fill="currentColor"
        />
        <path
          d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
          stroke="currentColor"
        />
        <path
          d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12Z"
          stroke="currentColor"
        />
        <path
          d="M21.0618 16.0775C21.0618 16.7921 20.6806 17.4523 20.0618 17.8096L12.9996 21.887C12.3808 22.2443 11.6184 22.2443 10.9996 21.887L3.93733 17.8096C3.31852 17.4523 2.93733 16.7921 2.93733 16.0775L2.93733 7.92275C2.93733 7.20822 3.31853 6.54796 3.93733 6.1907L10.9996 2.1133C11.6184 1.75603 12.3808 1.75603 12.9996 2.1133L20.0618 6.1907C20.6807 6.54796 21.0618 7.20822 21.0618 7.92275L21.0618 16.0775Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_174_474">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </IconSkeleton>
  )
}

import React from "react"

export function DeploymentFail({ title = `Failed to deploy`, ...rest }) {
  return (
    <svg
      data-testid="deployment-fail"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>{title}</title>
      <rect width="20" height="20" fill="transparent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C13.0333 1 15.5583 3.15833 16.125 6.03333C18.2917 6.18333 20 7.96667 20 10.1667C20 12.4108 18.2228 14.2425 16 14.3301V12.6612C17.3053 12.5758 18.3333 11.494 18.3333 10.1667C18.3333 8.78333 17.2167 7.66667 15.8333 7.66667H14.5833V7.25C14.5833 4.71667 12.5333 2.66667 10 2.66667C7.9 2.66667 6.14167 4.075 5.59167 6H5C3.15833 6 1.66667 7.49167 1.66667 9.33333C1.66667 10.8266 2.64729 12.0897 4 12.5142V14.233C1.71969 13.7689 0 11.7493 0 9.33333C0 6.75833 1.95 4.63333 4.45833 4.36667C5.5 2.36667 7.59167 1 10 1Z"
        fill="#B7B5BD"
      />
      <line
        x1="7.32843"
        y1="15.8283"
        x2="12.9853"
        y2="10.1715"
        stroke="#FA2915"
        strokeWidth="1.5"
      />
      <line
        x1="12.9855"
        y1="15.8282"
        x2="7.32868"
        y2="10.1713"
        stroke="#FA2915"
        strokeWidth="1.5"
      />
    </svg>
  )
}

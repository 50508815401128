import * as React from "react"
import { WindowLocation } from "@gatsbyjs/reach-router"
import Loading from "@modules/ui/components/Loading"
import {
  EmptyState,
  Link,
  Spacer,
  ThemeCss,
  Heading,
  Badge,
  Tooltip,
} from "gatsby-interface"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { MdArrowBack, MdSettings } from "react-icons/md"
import {
  getPathToBuildDetails,
  getPathToSiteDetails,
} from "@modules/site/details/utils"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { Waypoint } from "react-waypoint"
import { FormattedMessage } from "@modules/locales"
import { buildsByBranchView as buildsByBranchViewText } from "@modules/locales/default.js"
import { useBuilds } from "../hooks/useDeploys"
import { useLatestBuildByStatus } from "@modules/build/shared/hooks/useLatestBuildByStatus"
import { BuildStatus, CdnVendor } from "@modules/graphql/types"
import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"
import { useOrganizationBaseDetailsQuery } from "@modules/organization/queries.generated"
import { LastBuildInfo } from "@modules/site/buildsView/components/LastBuildInfo"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import {
  deploysView as deploysViewText,
  ui as uiText,
} from "@modules/locales/default.js"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails"
import { PublishBuildConfirmation } from "@modules/build/shared/components/PublishBuildConfirmation"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import { useRollbacksAndManualDeploysAvailable } from "@modules/organization/shared/hooks/useRollbacksAndManualDeploysAvailable"
import {
  useTriggerErrorAlert,
  ErrorAlert,
} from "@modules/ui/components/ErrorAlert"
import { BuildsFilterBar } from "@modules/site/builds/components/BuildsFilterBar"
import { useFlags } from "@modules/featureFlags"
import { useFilteredBuilds } from "@modules/site/builds/hooks/useFilteredBuilds"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { isProperlyConnected } from "@modules/site/cdnIntegrations/helpers"
import { useCmsIntegration } from "@modules/cms/shared/hooks/useCmsIntegration"
import { isCmsConnected } from "@modules/cms/shared/utils"
import { useBuildsFilters } from "../../builds/hooks/useBuildsFilters"
import { useCurrentUser } from "@modules/auth"
import useNetPromoterSurvey from "@modules/netPromoterSurvey/useNetPromoterSurvey"
import { useWorkspaceBuildQueue } from "@modules/organization/builds/hooks/useWorkspaceBuildQueue"
import { WorkspaceBuildQueue } from "@modules/organization/builds/components/WorkspaceBuildQueue"
import { BuildsBreadcrumbs } from "@modules/build/details/components/BuildsBreadcrumbs"

/**
 * When modifying this file, make sure you modify the PullRequestBuildsPage accordingly
 * to have the same UI for the builds by branches and builds by pull requests
 */

export type InnerBuildsListPageProps = BuildsListPageProps & {
  onViewBuildQueue?: (id: string) => void
}

export function InnerBuildsListPage({
  branch: rawBranch,
  siteId,
  organizationId,
  onViewBuildQueue,
}: InnerBuildsListPageProps) {
  const { flags } = useFlags()
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [triggerBuildError, setTriggerBuildError] =
    React.useState<JSX.Element | null>(null)

  const {
    data: siteDetailsData,
    error: errorSiteDetails,
    refetch: refetchSiteDetials,
  } = useSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: "cache-and-network",
  })

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  // In the event that a branch name contains a `/`, the
  // routing is disturbed unless encoded (and decoded).
  const branch = decodeURIComponent(rawBranch)

  const siteDetails = siteDetailsData?.siteDetails
  const cdnIntegrations = siteDetails?.cdnIntegrations
  const connectedCdn = cdnIntegrations?.find(isProperlyConnected)?.vendor
  const sourceProvider = siteDetails?.repository?.provider

  const { cmsIntegrations } = useCmsIntegration(siteId)
  const connectedCmsIntegrations = (cmsIntegrations || []).filter(
    isCmsConnected
  )

  const siteDetailsFromCache = useLocalSiteDetailsForBuilds(siteId)
  const latestHostingDeployVersion =
    siteDetailsFromCache?.latestHostingDeployVersion
  const manualHostingDeploysEnabled =
    siteDetailsFromCache?.manualHostingDeploysEnabled

  const [buildToPublish, setBuildToPublish] = React.useState<string | null>(
    null
  )

  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )

  const repositoryUrl = siteDetails?.repository?.url

  const [loadingLastBuild, lastBuild] = useLatestBuildByStatus({
    siteId,
    siteBranch: branch,
    status: BuildStatus.Success,
    setError: setError,
  })

  const {
    buildStartDateFilter,
    setBuildStartDateFilter,
    buildEndDateFilter,
    setBuildEndDateFilter,
    buildStatusFilter,
    setBuildStatusFilter,
    buildTypeFilter,
    setBuildTypeFilter,
    buildKeywordFilter,
    debouncedBuildKeywordFilter,
    setBuildKeywordFilter,
    filteringIsActive,
  } = useBuildsFilters()

  const [
    filteredBuilds,
    {
      loading: filteredBuildsLoading,
      loadingMore: filteredBuildsLoadingMore,
      updating: filteredBuildsUpdating,
      loadMore: filteredBuildsLoadMore,
      error: filteredBuildsError,
    },
  ] = useFilteredBuilds({
    siteId,
    branch,
    startDate: buildStartDateFilter,
    endDate: buildEndDateFilter,
    buildStatus: buildStatusFilter,
    buildType: buildTypeFilter,
    keyword: debouncedBuildKeywordFilter,
    skip: !flags.buildsFilterBar,
  })

  const [
    allBuilds,
    cdnVendor,
    {
      loading: allBuildsLoading,
      error: allBuildsError,
      loadingMore: allBuildsLoadingMore,
      loadMore: allBuildsLoadMore,
    },
  ] = useBuilds(siteId, branch, flags.buildsFilterBar)

  const builds = flags.buildsFilterBar ? filteredBuilds : allBuilds
  const loading = flags.buildsFilterBar
    ? filteredBuildsLoading
    : allBuildsLoading
  const error = flags.buildsFilterBar ? filteredBuildsError : allBuildsError
  const loadingMore = flags.buildsFilterBar
    ? filteredBuildsLoadingMore
    : allBuildsLoadingMore
  const loadMore = flags.buildsFilterBar
    ? filteredBuildsLoadMore
    : allBuildsLoadMore

  const gatsbyHostingOn = flags.buildsFilterBar
    ? connectedCdn === CdnVendor.CloudCdn
    : cdnVendor === CdnVendor.CloudCdn

  const { data: orgData } = useOrganizationBaseDetailsQuery({
    variables: { id: organizationId },
  })

  const organization = orgData?.organizationDetails
  const isEligiblePlan =
    useRollbacksAndManualDeploysAvailable(organizationId).result

  const { currentUser } = useCurrentUser()
  useNetPromoterSurvey(organization, currentUser)

  if (loading || loadingLastBuild) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <Loading message={buildsByBranchViewText.messages.loadingBuilds} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const actualError = error || errorSiteDetails
  if (actualError) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <ErrorAlert data-testid="builds-list-page-error">
            <FormattedMessage<"error">
              message={buildsByBranchViewText.messages.errorLoadingBuilds}
              values={{ error: actualError?.message }}
            />
          </ErrorAlert>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  if (!flags.buildsFilterBar && allBuilds.length === 0) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <EmptyState
            variant="BORDERED"
            heading={buildsByBranchViewText.headers.noBuilds}
            text={buildsByBranchViewText.messages.noBuildsForBranch}
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  return (
    <main>
      <PageWithTabsContentSection>
        <StandardSingleColumn id="builds">
          <BuildsBreadcrumbs
            organizationId={organizationId}
            siteId={siteId}
            build={null}
          />
          <Spacer size={7} />
          <div>
            <div css={topCss}>
              <div css={infosCss}>
                <Heading as="h1" css={headingCss}>
                  <FormattedMessage<"branch", "strong">
                    message={buildsByBranchViewText.headers.listOfBuilds}
                    values={{ branch }}
                    tags={{
                      strong: function (content) {
                        return <strong>{content}</strong>
                      },
                    }}
                  />
                </Heading>

                {siteDetails?.stableBuildURL ? (
                  <ExternalLink
                    href={siteDetails?.stableBuildURL}
                    truncate
                    size={2}
                  />
                ) : (
                  <LastBuildInfo buildUrl={lastBuild?.url} />
                )}

                <div css={rowCss}>
                  <SiteBranch>
                    <strong>{branch}</strong>
                  </SiteBranch>

                  <Spacer size={5} direction="horizontal" />

                  {lastBuild?.endedAt && (
                    <React.Fragment>
                      <BuildPublishedDate
                        buildPublishedDate={lastBuild.endedAt}
                      />
                      <Spacer size={5} direction="horizontal" />
                    </React.Fragment>
                  )}

                  {gatsbyHostingOn && isEligiblePlan && (
                    <React.Fragment>
                      <Spacer size={4} direction="horizontal" />
                      <span css={deploysCss}>
                        <GatsbyCloudLogo />{" "}
                        {deploysViewText.labels.automaticDeploys}
                        :
                        <Spacer size={3} direction="horizontal" />
                        <Badge
                          tone={
                            siteDetails?.manualHostingDeploysEnabled
                              ? `BRAND`
                              : `SUCCESS`
                          }
                        >
                          {siteDetails?.manualHostingDeploysEnabled
                            ? uiText.labels.disabled
                            : uiText.labels.enabled}
                        </Badge>
                        <Tooltip label={uiText.actions.editSettings}>
                          <span css={settingsLinkCss}>
                            <Link
                              to={`${linkToSiteSettings}/builds`}
                              variant="SIMPLE"
                            >
                              <MdSettings />
                            </Link>
                          </span>
                        </Tooltip>
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>

              {organization && (
                <div css={triggerBuildCss}>
                  <TriggerBuild
                    siteId={siteId}
                    selectedBranch={branch}
                    orgStatus={organization?.status}
                    size={`M`}
                    triggerBuildError={triggerBuildError}
                    setTriggerBuildError={setTriggerBuildError}
                  />
                </div>
              )}
            </div>
            {/* TODO: refactor the way that both types of error use the same shared errorAlert */}
            {errorAlert}
            {triggerBuildError}
          </div>
          <Spacer size={5} />
          <h2 css={visuallyHiddenCss}>
            <FormattedMessage<"productionBranch">
              message={deploysViewText.headers.allBuildsOfBranch}
              values={{
                productionBranch: branch,
              }}
            />
          </h2>

          {flags.buildsFilterBar && (
            <React.Fragment>
              <BuildsFilterBar
                endDate={buildEndDateFilter}
                onEndDateChange={setBuildEndDateFilter}
                startDate={buildStartDateFilter}
                onStartDateChange={setBuildStartDateFilter}
                type={buildTypeFilter}
                onTypeChange={setBuildTypeFilter}
                status={buildStatusFilter}
                onStatusChange={setBuildStatusFilter}
                keyword={buildKeywordFilter}
                onKeywordChange={setBuildKeywordFilter}
                loading={filteredBuildsUpdating}
                sourceProvider={sourceProvider}
                cmsIntegrations={connectedCmsIntegrations}
              />

              <Spacer size={6} />
            </React.Fragment>
          )}
          {filteringIsActive &&
            !filteredBuildsUpdating &&
            builds?.length === 0 && (
              <EmptyState
                heading={`No matching builds`}
                text={`Sorry, there is no one build matching the filter values`}
                headingAs="h2"
                graphic={<EmptyStateGraphic />}
                variant="BORDERED"
              />
            )}
          {flags.buildsFilterBar && filteredBuildsUpdating ? (
            <div css={updatingCss}>
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
                delay={750}
              />
            </div>
          ) : (
            <Feed
              loading={loadingMore}
              labelledBy="builds"
              count={builds?.length || 0}
            >
              <div>
                {builds?.map((build, index) => (
                  <div key={`build-${build.id}`}>
                    <FeedItem
                      labelledBy={`build-${build.id}`}
                      describedBy={`deploy-status-${build.id}`}
                      position={index + 1}
                      id={build.id}
                    >
                      <BuildCard
                        as="div"
                        id={`build-${build.id}`}
                        siteId={siteId}
                        organizationId={organizationId}
                        pullRequestId={build?.pullRequest?.id}
                        title={build.commit?.message || build.branch || ``}
                        buildId={build.id}
                        status={build.buildStatus}
                        createdAt={build.createdAt}
                        startedAt={build.startedAt}
                        latestHostingDeployVersion={
                          latestHostingDeployVersion ?? undefined
                        }
                        manualHostingDeploysEnabled={
                          manualHostingDeploysEnabled
                        }
                        onPublish={setBuildToPublish}
                        gatsbyHostingOn={gatsbyHostingOn}
                        isEligiblePlan={isEligiblePlan}
                        /* backend allows for null. Fallbacking to undefined for safe types in child components */
                        duration={build.duration || undefined}
                        endedAt={build.endedAt}
                        branch={build.branch || ``}
                        /* backend allows for null. Fallbacking to undefined for safe types in child components */
                        commit={build.commit || undefined}
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                        runnerType={build.runnerType!}
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                        buildType={build.buildType!}
                        /* backend allows for null. Fallbacking to undefined for safe types in child components */
                        source={build.source || undefined}
                        /* backend allows for null. Fallbacking to undefined for safe types in child components */
                        author={build.author || undefined}
                        isProductionBranch={branch === build.branch}
                        viewDetailsHref={getPathToBuildDetails(
                          build.id,
                          siteId,
                          organizationId
                        )}
                        deployStartedAt={build?.deployStartedAt}
                        deployEndedAt={build?.deployEndedAt}
                        onBuildSucceed={refetchSiteDetials}
                        repositoryUrl={repositoryUrl}
                        routeMetadata={build?.routeMetadata || undefined}
                        buildMetadata={build?.metadata || undefined}
                        onViewBuildQueue={onViewBuildQueue}
                        buildUrl={build.url}
                      />
                    </FeedItem>
                    <Spacer size={5} />
                  </div>
                ))}
              </div>
            </Feed>
          )}
          <Waypoint onEnter={loadMore} />
          <div css={loadingMoreCss}>
            {loadingMore && (
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
                delay={1}
              />
            )}
          </div>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
      {!!buildToPublish && (
        <PublishBuildConfirmation
          siteId={siteId}
          buildId={buildToPublish}
          onDismiss={() => setBuildToPublish(null)}
          onComplete={() => {
            setBuildToPublish(null)
          }}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled ?? undefined}
        />
      )}
    </main>
  )
}

export type BuildsListPageProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
  branch: string
}

/**
 * A wrapper component which renders BuildQueueComponent (modal-panel)
 * as a sibling not a child of the main component to avaoid re-triggering the modal animations
 * every time the subscriptions re-render the main component (the Inner... one)
 */
export function BuildsListPage(props: BuildsListPageProps) {
  const { organizationId } = props

  // gets all stuff related to manage build queue functionality
  const buildQueue = useWorkspaceBuildQueue(organizationId)

  return (
    <React.Fragment>
      <InnerBuildsListPage onViewBuildQueue={buildQueue?.onOpen} {...props} />

      <WorkspaceBuildQueue
        organizationId={organizationId}
        isOpen={buildQueue.isOpen}
        onClose={buildQueue.onClose}
        originId={buildQueue.originId}
      />
    </React.Fragment>
  )
}

/* styles */

const topCss: ThemeCss = theme => ({
  alignItems: `end`,
  display: `grid`,
  gap: theme.space[7],
  marginBottom: theme.space[6],

  [theme.mediaQueries.desktop]: {
    marginBottom: theme.space[8],
    gridTemplateColumns: `1fr auto`,
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
  color: theme.colors.grey[80],
  fontWeight: theme.fontWeights.body,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[5],
  },
})

const infosCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[4],
  gridTemplateColumns: `minmax(0, 1fr)`,
})

const rowCss: ThemeCss = theme => ({
  display: `grid`,

  "& > span": {
    minHeight: theme.space[7],
  },

  [theme.mediaQueries.desktop]: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `row`,
  },
})

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
})

const deploysCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  display: `inline-flex`,
  alignItems: `center`,

  svg: {
    width: `16px`,
    height: `auto`,
    marginRight: theme.space[3],
  },
})

const settingsLinkCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,

  svg: {
    fontSize: `.8em`,
    marginLeft: theme.space[2],
    color: theme.colors.grey[40],
  },

  "&:hover svg": {
    color: theme.colors.purple[40],
  },
})

const triggerBuildCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
  marginLeft: "auto",

  [theme.mediaQueries.tablet]: {
    marginTop: 0,
    marginLeft: 0,
  },
})

const loadingMoreCss: ThemeCss = theme => ({
  textAlign: "center",
  minHeight: theme.space[8],
})

const updatingCss: ThemeCss = theme => ({
  textAlign: "center",
  minHeight: theme.space[8],
  marginTop: theme.space[10],
})

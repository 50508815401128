import * as React from "react"

import { Link, Spacer, ThemeCss, Heading } from "gatsby-interface"
import { MdArrowBack } from "react-icons/md"
import { getPathToBuildDetails } from "@modules/site/details/utils"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import Loading from "@modules/ui/components/Loading"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { useFunctionEntryQuery } from "../queries.generated"
import { BuildFunctionCard } from "./BuildFunctionCard"
import { FunctionLogs } from "./FunctionLogs"
import { functionsView as text } from "@modules/locales/default"

export type BuildFunctionDetailsProps = {
  organizationId: string
  siteId: string
  functionId: string
  buildId: string
}

export function BuildFunctionDetails({
  organizationId,
  siteId,
  functionId,
  buildId,
}: BuildFunctionDetailsProps) {
  const { data, loading, error } = useFunctionEntryQuery({
    variables: {
      siteId,
      functionId,
    },
  })
  const functionEntry = data?.functionEntry

  if (loading) {
    return (
      <StandardSingleColumn>
        <Loading bufferSize="padded" />
      </StandardSingleColumn>
    )
  }

  if (error) {
    return (
      <StandardSingleColumn>
        <ErrorAlert>{error.message}</ErrorAlert>
      </StandardSingleColumn>
    )
  }

  if (!functionEntry) {
    return null
  }

  return (
    <StandardSingleColumn css={rootCss}>
      <Link
        variant="SIMPLE"
        to={`${getPathToBuildDetails(
          buildId,
          siteId,
          organizationId
        )}#functions`}
      >
        <MdArrowBack />
        <Spacer size={3} direction="horizontal" />
        {text.actions.backToFunctionsList}
      </Link>
      <Spacer size={5} />

      <BuildFunctionCard
        siteId={siteId}
        buildId={buildId}
        {...functionEntry}
        organizationId={organizationId}
        noLink={true}
      >
        <Spacer size={5} />

        <Heading css={headingCss}>
          {text.headers.functionInvocationsLog}
        </Heading>

        <FunctionLogs
          siteId={siteId}
          buildId={buildId}
          functionId={functionId}
        />
      </BuildFunctionCard>
      <Spacer size={10} />
    </StandardSingleColumn>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  marginTop: theme.space[9],
})

const headingCss: ThemeCss = theme => ({
  color: theme.colors.grey[80],
  fontSize: theme.fontSizes[2],
  marginTop: theme.space[6],
  marginBottom: theme.space[5],
})

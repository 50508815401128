// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type AcceptResourceInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['UUID'];
  organizationId?: Types.Maybe<Types.Scalars['UUID']>;
}>;


export type AcceptResourceInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptResourceInvitation?: Types.Maybe<(
    { __typename?: 'AcceptInvitationResult' }
    & Pick<Types.AcceptInvitationResult, 'redirectPath'>
    & { mutationResult?: Types.Maybe<(
      { __typename?: 'MutationResult' }
      & Pick<Types.MutationResult, 'success' | 'message'>
    )> }
  )> }
);

export type InvitationByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type InvitationByIdQuery = (
  { __typename?: 'Query' }
  & { invitationById?: Types.Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Types.Invitation, 'id' | 'invitationUserId' | 'invitationName' | 'invitationEmail' | 'senderUserId' | 'senderEmail' | 'senderName' | 'accepted' | 'expired' | 'sent' | 'resourceType' | 'resourceId' | 'role' | 'type' | 'invitationUrl'>
    & { resources?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'InvitationResource' }
      & Pick<Types.InvitationResource, 'resourceType' | 'resourceId' | 'role'>
    )>>> }
  )> }
);


export const AcceptResourceInvitationDocument = gql`
    mutation AcceptResourceInvitation($invitationId: UUID!, $organizationId: UUID) {
  acceptResourceInvitation(
    invitationId: $invitationId
    organizationId: $organizationId
  ) {
    mutationResult {
      success
      message
    }
    redirectPath
  }
}
    `;
export type AcceptResourceInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptResourceInvitationMutation, AcceptResourceInvitationMutationVariables>;

/**
 * __useAcceptResourceInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptResourceInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptResourceInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptResourceInvitationMutation, { data, loading, error }] = useAcceptResourceInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAcceptResourceInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptResourceInvitationMutation, AcceptResourceInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptResourceInvitationMutation, AcceptResourceInvitationMutationVariables>(AcceptResourceInvitationDocument, baseOptions);
      }
export type AcceptResourceInvitationMutationHookResult = ReturnType<typeof useAcceptResourceInvitationMutation>;
export type AcceptResourceInvitationMutationResult = ApolloReactCommon.MutationResult<AcceptResourceInvitationMutation>;
export type AcceptResourceInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptResourceInvitationMutation, AcceptResourceInvitationMutationVariables>;
export const InvitationByIdDocument = gql`
    query InvitationById($id: UUID!) {
  invitationById(id: $id) {
    id
    invitationUserId
    invitationName
    invitationEmail
    senderUserId
    senderEmail
    senderName
    accepted
    expired
    sent
    resourceType
    resourceId
    role
    type
    resources {
      resourceType
      resourceId
      role
    }
    invitationUrl
  }
}
    `;

/**
 * __useInvitationByIdQuery__
 *
 * To run a query within a React component, call `useInvitationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvitationByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvitationByIdQuery, InvitationByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<InvitationByIdQuery, InvitationByIdQueryVariables>(InvitationByIdDocument, baseOptions);
      }
export function useInvitationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvitationByIdQuery, InvitationByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvitationByIdQuery, InvitationByIdQueryVariables>(InvitationByIdDocument, baseOptions);
        }
export type InvitationByIdQueryHookResult = ReturnType<typeof useInvitationByIdQuery>;
export type InvitationByIdLazyQueryHookResult = ReturnType<typeof useInvitationByIdLazyQuery>;
export type InvitationByIdQueryResult = ApolloReactCommon.QueryResult<InvitationByIdQuery, InvitationByIdQueryVariables>;

import * as React from "react"
import { Modal, ModalPanel, StyledPanel, EmptyState } from "gatsby-interface"
import { QueueItem } from "@modules/graphql/types"
import { WorkspaceBuildQueueList } from "./WorkspaceBuildQueueList"
import { WorkspaceBuildQueueHeader } from "./WorkspaceBuildQueueHeader"
import { workspaceBuildQueue as text } from "@modules/locales/default.js"
import { useWorkspaceDetailsShortcuts } from "../hooks/useWorkspaceDetailsShortcuts"
import { useWorkspaceQueuedBuilds } from "../hooks/useWorkspaceQueuedBuilds"
import Loading from "@modules/ui/components/Loading"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"

/**
 * Modal Panel component presents the list of builds in the Workspace build queue
 */

export type WorkspaceBuildQueueProps = {
  organizationId: string
  isOpen?: boolean
  onClose: () => void
  originId?: string | null
}

export function WorkspaceBuildQueue({
  organizationId,
  isOpen,
  onClose,
  originId,
}: WorkspaceBuildQueueProps) {
  const [animationFinished, setAnimationFinished] = React.useState(false)

  // delays rendering the content of panel till the moment it completely side out
  React.useEffect(() => {
    let delay: ReturnType<typeof setTimeout>

    if (isOpen) {
      delay = setTimeout(() => {
        setAnimationFinished(true)
        clearTimeout(delay)
      }, 500)
    }

    return () => {
      if (delay) {
        clearTimeout(delay)
      }
    }
  }, [isOpen])

  const workspace = useWorkspaceDetailsShortcuts(organizationId)
  const queue = useWorkspaceQueuedBuilds(organizationId, {
    isOpen,
  })

  const isLoading = workspace.loading || queue.loading

  const hasBuilds = Boolean(queue?.data?.length && queue.data.length > 0)

  return (
    <Modal
      aria-label={`Build queue for ${workspace.name}`}
      isOpen={isOpen}
      onDismiss={onClose}
    >
      <ModalPanel size="WIDE">
        <StyledPanel>
          {isLoading || !animationFinished ? (
            <Loading delay={500} />
          ) : (
            <React.Fragment>
              <WorkspaceBuildQueueHeader
                organizationId={organizationId}
                onClose={onClose}
              />
              {hasBuilds ? (
                <WorkspaceBuildQueueList
                  items={queue.data as QueueItem[]}
                  originId={originId}
                  organizationId={organizationId}
                />
              ) : (
                <EmptyState
                  heading={text.messages.noBuildsInQueue}
                  text=""
                  graphic={<EmptyStateGraphic />}
                />
              )}
            </React.Fragment>
          )}
        </StyledPanel>
      </ModalPanel>
    </Modal>
  )
}

import React from "react"
import gql from "graphql-tag"
import { useMutation } from "react-apollo"
import PropTypes from "prop-types"
import { BuildRunnerType, BuildStatus } from "@modules/graphql/types"
import { useShowSuccessToast, Button } from "gatsby-interface"
import { SitePermissions } from "@modules/site/permissions"
import { GetBuildQueueItemsForWorkspaceDocument } from "@modules/organization/builds/queries.generated"

export const CANCEL_BUILD_MUTATION = gql`
  mutation cancelBuild(
    $buildId: UUID!
    $siteId: UUID!
    $runnerType: BuildRunnerType!
  ) {
    cancelBuild(buildId: $buildId, siteId: $siteId, runnerType: $runnerType) {
      success
    }
  }
`

const propTypes = {
  siteId: PropTypes.string.isRequired,
  buildId: PropTypes.string.isRequired,
  runnerType: PropTypes.oneOf(Object.values(BuildRunnerType)).isRequired,
  status: PropTypes.oneOf(Object.values(BuildStatus)).isRequired,
  variant: PropTypes.oneOf([`PRIMARY`, `GHOST`]),
  organizationId: PropTypes.string,
  refetchBuildQueue: PropTypes.bool,
}

export function CancelBuildButton({
  siteId,
  organizationId,
  buildId,
  runnerType,
  status,
  error,
  setError,
  variant = `PRIMARY`,
  refetchBuildQueue = false,
}) {
  const showSuccessToast = useShowSuccessToast()

  const queriesToRefetch =
    refetchBuildQueue && organizationId
      ? [
          {
            query: GetBuildQueueItemsForWorkspaceDocument,
            variables: { workspaceId: organizationId },
          },
        ]
      : []

  const [cancelBuild, { loading }] = useMutation(CANCEL_BUILD_MUTATION, {
    variables: {
      siteId,
      buildId,
      runnerType,
    },
    refetchQueries: queriesToRefetch,
  })

  const handleCancelBuild = async e => {
    e.preventDefault()

    if (error) {
      setError(null)
    }

    try {
      const result = await cancelBuild()

      if (result?.data?.cancelBuild?.success) {
        showSuccessToast("Build successfully canceled.")
      } else {
        setError({
          message: "We can't cancel your build. Please try later.",
        })
      }
    } catch (err) {
      setError({ message: err.message })
    }
  }

  if (BuildStatus.Queued !== status && BuildStatus.Building !== status) {
    return null
  }

  return (
    <SitePermissions id={siteId} resource="sites" action="edit">
      <span aria-live="polite">
        <Button
          data-testid="btn-cancel-build"
          onClick={handleCancelBuild}
          size="S"
          tone="DANGER"
          disabled={loading}
          variant={variant}
        >
          {loading ? "Canceling build..." : "Cancel build"}
        </Button>
      </span>
    </SitePermissions>
  )
}

CancelBuildButton.propTypes = propTypes

import * as React from "react"

export type BurstIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  a11yId?: string
}

export function BurstIcon({ a11yId, ...rest }: BurstIconProps) {
  const id = a11yId ? a11yId : `ProfileRequestIcon`

  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      {...rest}
    >
      <path
        d="M7.96702 13.6434L9.90937 8.30683L6.94272 7.22705L14.2273 0.475228L12.2849 5.8118L15.1408 6.85124L7.96702 13.6434Z"
        fill="#60B9F9"
      />
      <path
        d="M1.88499 13.6043L3.82734 8.26777L0.860685 7.18799L8.14525 0.436166L6.2029 5.77274L9.05873 6.81217L1.88499 13.6043Z"
        fill="#60B9F9"
      />
    </svg>
  )
}

import * as React from "react"
import { InputFieldBlock, Button, ThemeCss, Spacer } from "gatsby-interface"
import datesInputBg from "../assets/datesInputBg.svg"

export type CustomInputProps = {
  inputValue: string
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleOnBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handleOnFocus: () => void
  onClick?: () => void
}

export const CustomInput = React.forwardRef((props: CustomInputProps, ref) => {
  const {
    inputValue,
    handleOnChange,
    handleOnBlur,
    handleOnKeyDown,
    handleOnFocus,
    onClick,
  } = props

  return (
    <InputFieldBlock
      id="build-dates-filter"
      onClick={onClick}
      label="Dates"
      labelSize="S"
      value={inputValue}
      autoComplete="off"
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      css={customInputCss}
      onFocus={handleOnFocus}
    />
  )
})

export type SelectionActionsProps = {
  onClearClick: () => void
  onApplyClick: () => void
  startDate: Date | null | undefined
}

export function SelectionActions(props: SelectionActionsProps) {
  const { onClearClick, onApplyClick, startDate } = props

  return (
    <div>
      <div css={actionsCss}>
        <Button
          size="M"
          variant="GHOST"
          onClick={onClearClick}
          disabled={!startDate}
        >
          Clear
        </Button>
        <Spacer size={3} direction="horizontal" />
        <Button size="M" onClick={onApplyClick} disabled={!startDate}>
          Apply
        </Button>
      </div>
    </div>
  )
}

const customInputCss: ThemeCss = theme => ({
  input: {
    backgroundImage: `url(${datesInputBg})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `right 0.4rem center`,
    position: `relative`,
  },
})

const actionsCss: ThemeCss = theme => ({
  padding: `${theme.space[1]} ${theme.space[4]} ${theme.space[4]}`,
  display: `flex`,
  justifyContent: `flex-end`,
})

import * as React from "react"
import * as qs from "query-string"
import { WindowLocation } from "@gatsbyjs/reach-router"
import { Layout } from "@modules/user/shared/components/Layout"
import { HomeLink } from "@modules/user/shared/components/HomeLink"
import { Authentication } from "@modules/user/shared/components/Authentication"
import { AuthenticationLabels } from "@modules/user/shared/constants/authentication"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { oneColumnCss } from "@modules/user/shared/stylesheets/layouts"
import { processLoginAttempt, isAuthenticated } from "@modules/auth"
import { navigate } from "gatsby"

export type LoginProps = {
  location?: WindowLocation
}

export function Login({ location }: LoginProps) {
  const {
    trackSegment,
    trackButtonClicked,
    trackPageViewed,
    trackAction,
  } = useTracker()

  const paramsString = location?.search || ""
  const query = qs.parse(paramsString) || {}
  const { invitationId, invitationToken } = query
  const userIsAuthenticated = isAuthenticated()

  React.useEffect(() => {
    if (invitationId) {
      trackPageViewed(`Invitation Log In Page`)
    } else {
      trackPageViewed(`Log In Page`)
    }

    if (!userIsAuthenticated && invitationId) {
      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Invitation to Gatsby Cloud accepted, unauthenticated user`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: `Invitation to Gatsby Cloud accepted, unauthenticated user`,
        properties: {
          invitationId: invitationId as string,
        },
      })
    }

    if (userIsAuthenticated) {
      if (invitationId && invitationToken) {
        navigate(`/dashboard/invitations/${invitationId}`)

        return () => {
          return undefined
        }
      }

      navigate(`/dashboard/sites`)

      return () => {
        return undefined
      }
    }
  }, [userIsAuthenticated])

  // prevents auth buttons blinking
  if (invitationId && userIsAuthenticated) {
    return null
  }

  return (
    <React.Fragment>
      <Layout css={oneColumnCss}>
        <HomeLink />

        <Authentication
          paramsString={paramsString}
          onOptionClick={option => {
            trackButtonClicked(`Clicked to Sign In`, {
              name: AuthenticationLabels[option],
              uiSource: `Login screen`,
            })
            trackSegment({
              type: SegmentEventType.Track,
              event: `Clicked to Sign In`,
              properties: {
                loginType: AuthenticationLabels[option],
              },
            })
            processLoginAttempt(paramsString, option)
          }}
        />
      </Layout>
    </React.Fragment>
  )
}

import * as React from "react"
import { QueueItem } from "@modules/graphql/types"
import { GoGitBranch } from "react-icons/go"
import { ThemeCss, Theme, Heading, Text, Link } from "gatsby-interface"
import BuildStatusIndicator from "@modules/build/shared/components/BuildStatusIndicator"
import BuildCardStatusInfo from "@modules/build/card/components/BuildCardStatusInfo"
import { BuildEventText } from "@modules/build/shared/components/BuildEventText"
import { CancelBuildButton } from "@modules/build/details/components/CancelBuildButton"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { getPathToBuildDetails } from "@modules/site/details/utils"

/**
 * A child component of WorkspaceBuildQueue, it renders the list of queue items (builds)
 */

export type WorkspaceBuildQueueListProps = {
  items?: QueueItem[]
  originId?: string | null
  organizationId: string
}

export function WorkspaceBuildQueueList({
  items = [],
  originId,
  organizationId,
}: WorkspaceBuildQueueListProps) {
  const [setError, errorAlert] = useTriggerErrorAlert()

  if (!items) {
    return null
  }

  return (
    <React.Fragment>
      <div css={errorCss}>{errorAlert}</div>

      <ul css={listCss}>
        {items.map(item => {
          const build = item?.build
          const site = item?.site

          if (!site || !build) {
            return null
          }

          const isOriginBuild = originId === build.id

          const toBuildDetails = getPathToBuildDetails(
            build.id,
            site.id,
            organizationId
          )

          return (
            <li key={item.id} css={theme => listItemCss(theme, isOriginBuild)}>
              <div css={indicatorCss}>
                <BuildStatusIndicator
                  id={`deploy-status-${build.id}`}
                  buildStatus={build.buildStatus}
                  runnerType={build.runnerType}
                  branch={build.branch}
                />
              </div>

              <div>
                <div css={headerBarCss}>
                  <div css={headerCss}>
                    <Heading as="h3" css={siteNameCss}>
                      <b>{site.publicName}</b>
                    </Heading>

                    <span css={branchCss}>
                      <GoGitBranch />
                      <i>{build.branch}</i>
                    </span>
                  </div>

                  <CancelBuildButton
                    siteId={site.id}
                    organizationId={organizationId}
                    buildId={build.id}
                    runnerType={build.runnerType}
                    status={build.buildStatus}
                    error={errorAlert}
                    setError={setError}
                    variant="GHOST"
                    refetchBuildQueue={true}
                  />
                </div>

                <Text size="S" tone="BRAND" css={buildTitleCss}>
                  <Link to={toBuildDetails} variant="SIMPLE">
                    <BuildEventText
                      commit={build.commit || undefined}
                      buildType={build.buildType}
                      buildSource={build.source || undefined}
                      runnerType={build.runnerType || undefined}
                    />
                  </Link>
                </Text>

                <Text size="S" tone="BRAND" css={statusCss}>
                  <BuildCardStatusInfo
                    createdAt={build.createdAt}
                    buildStatus={build.buildStatus}
                    startedAt={build.startedAt}
                    endedAt={build.endedAt}
                    duration={build.duration}
                  />
                </Text>
              </div>
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

/*  styles */

const listCss: ThemeCss = _theme => ({
  listStyle: `none`,
  display: `flex`,
  flexDirection: `column`,
  margin: 0,
  padding: 0,
})

const listItemCss = (theme: Theme, emphesized: boolean) => [
  {
    display: `grid`,
    gridTemplateColumns: `auto 1fr`,
    gap: theme.space[5],
    margin: 0,
    padding: theme.space[7],
    paddingRight: theme.space[6],
    borderBottom: `1px solid ${theme.colors.standardLine}`,
  },
  emphesized && {
    background: theme.colors.purple[5],
  },
]

const indicatorCss: ThemeCss = theme => ({
  transform: `translateY(${theme.space[1]})`,
})

const headerBarCss: ThemeCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `1fr auto`,
  alignItems: `center`,
  marginBottom: theme.space[3],
  gap: theme.space[4],

  "& button": {
    whiteSpace: `nowrap`,
    marginBottom: theme.space[1],
  },
})

const headerCss: ThemeCss = _theme => ({
  display: `flex`,
  minWidth: 0,
  alignItems: `center`,
})

const siteNameCss: ThemeCss = theme => ({
  color: theme.colors.grey[90],
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes[3],
  whiteSpace: `nowrap`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
  maxWidth: `70%`,
  marginRight: theme.space[3],
  flexShrink: 0,
})

const branchCss: ThemeCss = theme => ({
  fontFamily: theme.fonts.monospace,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  position: `relative`,
  paddingLeft: theme.space[6],
  whiteSpace: `nowrap`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,

  svg: {
    color: theme.colors.grey[50],
    fontSize: theme.fontSizes[2],
    left: 0,
    position: `absolute`,
    top: 0,
    transform: `translateY(20%)`,
  },

  i: {
    fontStyle: `normal`,
  },
})

const buildTitleCss: ThemeCss = theme => ({
  marginBottom: theme.space[3],
})

const statusCss: ThemeCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[50],

  svg: {
    marginRight: theme.space[3],
  },
})

const errorCss: ThemeCss = theme => ({
  padding: `0 ${theme.space[7]}`,
})

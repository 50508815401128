import * as React from "react"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import { BuildFunctionDetails } from "@modules/functions/components/BuildFunctionDetails"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

export type BuildFunctionPageProps = RouteComponentProps<{
  organizationId: string
  siteId: string
  functionId: string
  buildId: string
}>

export function BuildFunctionPage({
  organizationId,
  siteId,
  functionId,
  buildId,
}: BuildFunctionPageProps) {
  if (!organizationId || !siteId || !functionId || !buildId) {
    return (
      <ErrorAlert>
        Error: missing organizationId, siteId , buildId or functionId
      </ErrorAlert>
    )
  }

  return (
    <BuildFunctionDetails
      organizationId={organizationId}
      siteId={siteId}
      functionId={functionId}
      buildId={buildId}
    />
  )
}

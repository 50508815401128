import * as React from "react"
import styled from "@emotion/styled"
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  StripeElementChangeEvent,
  Stripe,
  StripeCardNumberElement,
} from "@stripe/stripe-js"

import { billing as text } from "@modules/locales/default.js"
import {
  InputConnectedField,
  SelectConnectedField,
  Heading,
  ThemeCss,
  Spacer,
} from "gatsby-interface"
import Amex from "../assets/Amex"
import Discover from "../assets/Discover"
import Mastercard from "../assets/Mastercard"
import Visa from "../assets/Visa"
import { CC_VALID_VALUE, COUNTRY_CODES } from "./CheckoutForm.helpers"
import { StripeElementField } from "./StripeElements"
import Form from "../../form/components/Form"
import { useField, FormikConfig, FormikErrors } from "formik"

const headerCss: ThemeCss = _theme => ({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `space-between`,
})

const fieldsGroupHeaderCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.dense,
  margin: 0,
})

const cardIconsCss: ThemeCss = theme => ({
  alignSelf: `flex-end`,
  display: `flex`,
  "> *": {
    marginLeft: theme.space[3],
  },
})

const ContentBlock = styled.section({
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  width: `100%`,
})

type PaymentElements = Extract<
  stripe.elements.elementsType,
  "cardNumber" | "cardExpiry" | "cardCvc"
>

export type PaymentFormState = {
  name: string
  email?: string
  companyName?: string
  address_line1: string
  address_line2?: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
}

type FormValues = {
  creditCard: Record<PaymentElements, string>
  name: string
  email: string
  companyName: string
  address_line1: string
  address_line2?: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
}

export type PaymentFormProps = {
  onSubmit: (
    paymentInfo: PaymentFormState,
    stripe: Stripe,
    cardElement: StripeCardNumberElement
  ) => void
  children?: React.ReactNode
}

/** Used in the CreateOrganizationWizard flow (so when a new workspace is created or after first login) */
export function PaymentForm({ onSubmit, children }: PaymentFormProps) {
  const stripe = useStripe()
  const elements = useElements()

  const submitForm: FormikConfig<FormValues>["onSubmit"] = values => {
    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (!cardElement) {
      return
    }

    const {
      name,
      companyName,
      email,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_zip,
      address_country,
    } = values

    onSubmit(
      {
        name,
        email,
        companyName,
        address_line1,
        address_line2,
        address_city,
        address_state,
        address_zip,
        address_country,
      },
      stripe,
      cardElement
    )
  }

  return (
    <Form<FormValues>
      initialValues={{
        name: ``,
        creditCard: {
          cardNumber: ``,
          cardExpiry: ``,
          cardCvc: ``,
        },
        email: ``,
        companyName: ``,
        address_line1: ``,
        address_line2: ``,
        address_city: ``,
        address_state: ``,
        address_zip: ``,
        address_country: ``,
      }}
      validate={values => {
        const errors: FormikErrors<FormValues> = {}
        if (!values.name) errors.name = text.requiredField
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) &&
          values.email
        )
          errors.email = text.validEmail
        if (!values.address_line1) errors.address_line1 = text.requiredField
        if (!values.address_city) errors.address_city = text.requiredField
        if (!values.address_state) errors.address_state = text.requiredField
        if (!values.address_zip) errors.address_zip = text.requiredField
        if (!values.address_country) errors.address_country = text.requiredField

        const creditCardFields: PaymentElements[] = [
          "cardNumber",
          "cardExpiry",
          "cardCvc",
        ]

        for (const creditCardField of creditCardFields) {
          const ccFieldValue = values.creditCard[creditCardField]
          if (ccFieldValue !== CC_VALID_VALUE) {
            if (!errors.creditCard) {
              errors.creditCard = {}
            }
            errors.creditCard[creditCardField] =
              ccFieldValue.length === 0 ? text.requiredField : ccFieldValue
          }
        }

        return errors
      }}
      onSubmit={submitForm}
    >
      {children}
    </Form>
  )
}

export function PaymentFormElement(
  props: React.ComponentPropsWithoutRef<"form">
) {
  return <Form.FormElement {...props} />
}

export type PaymentFormFieldsProps = {}

export function PaymentFormFields(_props: PaymentFormFieldsProps) {
  return (
    <React.Fragment>
      <Spacer size={4} />
      <section css={headerCss}>
        <Heading as="h4" css={fieldsGroupHeaderCss}>
          {text.creditCardInformation}
        </Heading>
        <div css={cardIconsCss}>
          <Visa />
          <Mastercard />
          <Discover />
          <Amex />
        </div>
      </section>
      <Spacer size={4} />
      <InputConnectedField
        label={text.name}
        name="name"
        placeholder="Name"
        required
      />
      <ContentBlock className="fs-block">
        <ConnectedStripeElement name="cardNumber" />
        <ConnectedStripeElement name="cardExpiry" />
        <ConnectedStripeElement name="cardCvc" />
      </ContentBlock>
      <Spacer size={4} />
      <Heading as="h4" css={fieldsGroupHeaderCss}>
        {text.headers.billingAddress}
      </Heading>
      <InputConnectedField
        label={text.labels.billingAddress}
        name="address_line1"
        placeholder={text.labels.billingAddress}
        css={theme => ({
          paddingTop: theme.space[4],
        })}
        required
      />
      <InputConnectedField
        label={text.labels.billingAddressLineTwo}
        name="address_line2"
        placeholder={text.labels.billingAddressLineTwo}
        css={theme => ({
          paddingTop: theme.space[4],
        })}
      />
      <InputConnectedField
        label={text.labels.billingCity}
        name="address_city"
        placeholder={text.labels.billingCity}
        css={theme => ({
          paddingTop: theme.space[4],
        })}
        required
      />
      <ContentBlock className="fs-block">
        <InputConnectedField
          label={text.labels.billingState}
          name="address_state"
          placeholder={text.labels.billingState}
          css={theme => ({
            paddingTop: theme.space[4],
            width: `100%`,
            [theme.mediaQueries.tablet]: {
              paddingRight: theme.space[7],

              width: `50%`,
            },
          })}
          required
        />
        <InputConnectedField
          label={text.labels.billingZipCode}
          name="address_zip"
          placeholder={text.labels.billingZipCode}
          css={theme => ({
            paddingTop: theme.space[4],
            width: `100%`,
            [theme.mediaQueries.tablet]: {
              paddingRight: theme.space[7],

              width: `25%`,
            },
          })}
          required
        />
        <SelectConnectedField
          label={text.labels.billingCountry}
          name="address_country"
          options={[
            { value: ``, label: text.labels.selectCountry },
            ...COUNTRY_CODES.map(country => ({
              value: country.code,
              label: country.name,
            })),
          ]}
          css={theme => ({
            paddingTop: theme.space[4],
            width: `100%`,
            [theme.mediaQueries.tablet]: {
              width: `25%`,
            },
          })}
          required
        />
      </ContentBlock>
      <Spacer size={4} />
      <Heading as="h4" css={fieldsGroupHeaderCss}>
        {text.optionalInformation}
      </Heading>
      <Spacer size={4} />
      <ContentBlock className="fs-block">
        <InputConnectedField
          label={text.formEmail}
          name="email"
          placeholder="Email"
          css={theme => ({
            width: `100%`,
            [theme.mediaQueries.tablet]: {
              paddingRight: theme.space[7],

              width: `50%`,
            },
          })}
        />
        <InputConnectedField
          label={text.company}
          name="companyName"
          placeholder="Company"
          css={theme => ({
            width: `100%`,
            [theme.mediaQueries.tablet]: {
              width: `50%`,
            },
          })}
        />
      </ContentBlock>
    </React.Fragment>
  )
}

/**
 * Since credit card fields are controlled by Stripe, we cannot just spread Formik props on them
 * So we are going to update touched and error state manually using helpers from useField
 */
function ConnectedStripeElement({ name }: { name: PaymentElements }) {
  const [
    { value },
    { error, touched },
    { setValue, setError, setTouched },
  ] = useField<string>(`creditCard.${name}`)

  const validate = (e: StripeElementChangeEvent) => {
    setTouched(true)

    const stripeError = e?.error?.message
    if (stripeError) {
      setError(stripeError)
    }

    // Stripe controls field values, so we only keep track of whether the entered value is valid
    setValue(!e.empty && !e.error ? CC_VALID_VALUE : ``)
  }

  return (
    <StripeElementField
      elementName={name}
      value={value}
      error={error}
      touched={touched}
      onChange={validate}
      onBlur={() => setTouched(true)}
    />
  )
}

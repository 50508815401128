import React from "react"
import { BaseAnchor } from "gatsby-interface"
import { MdOpenInNew } from "react-icons/md"
import PropTypes from "prop-types"

export const ExternalLinkSizes = {
  S: 1,
  M: 3,
}

const propTypes = {
  href: PropTypes.string.isRequired,
  omitProtocol: PropTypes.bool,
  truncate: PropTypes.bool,
  size: PropTypes.number,
}

export function ExternalLink({
  href,
  size = ExternalLinkSizes.M,
  omitProtocol = true,
  truncate = false,
  ...props
}) {
  return (
    <BaseAnchor
      {...props}
      href={href}
      target="_blank"
      css={theme => ({
        color: theme.colors.purple[60],
        textDecoration: `none`,
        fontSize: theme.fontSizes[size],
        fontWeight: `600`,
        display: "flex",

        [theme.mediaQueries.desktop]: {
          display: "inline-flex",
        },

        "&:hover": {
          color: theme.colors.purple[40],
        },
      })}
    >
      <span
        css={
          truncate && {
            textOverflow: `ellipsis`,
            whiteSpace: `nowrap`,
            overflow: `hidden`,
          }
        }
      >
        {omitProtocol ? href.replace(/^(https?|ftp):\/\//g, "") : href}
      </span>

      <MdOpenInNew
        css={theme => ({
          marginLeft: `0.25em`,
          color: theme.colors.blackFade[30],
          alignSelf: "center",
          flexShrink: 0,
        })}
      />
    </BaseAnchor>
  )
}

ExternalLink.propTypes = propTypes

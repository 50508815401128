import * as React from "react"
import { auth as authText } from "@modules/locales/default.js"
import { SourceControlProviderIcon } from "@modules/sourceControlProvider"
import { SourceControlProvider } from "@modules/graphql/types"

export enum AuthenticationOption {
  Github = `GITHUB`,
  Gitlab = `GITLAB`,
  Bitbucket = `BITBUCKET`,
  Google = `GOOGLE`,
  Workos = "WORKOS",
}

export const AuthenticationLabels: Record<AuthenticationOption, string> = {
  GITHUB: authText.morphemes.github,
  GITLAB: authText.morphemes.gitlab,
  BITBUCKET: authText.morphemes.bitbucket,
  GOOGLE: authText.morphemes.google,
  WORKOS: authText.morphemes.workos,
}

export const AuthenticationOptions = [
  AuthenticationOption.Github,
  AuthenticationOption.Gitlab,
  AuthenticationOption.Bitbucket,
  AuthenticationOption.Google,
]

export const AuthenticationIcons: Record<
  AuthenticationOption,
  React.ReactNode
> = {
  GITHUB: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Github}
      color="initial"
    />
  ),
  GITLAB: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Gitlab}
      color="initial"
    />
  ),
  BITBUCKET: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Bitbucket}
      color="initial"
    />
  ),
  GOOGLE: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Google}
      color="initial"
    />
  ),
  WORKOS: "",
}

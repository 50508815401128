// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import { BuildFieldsFragment } from '../../build/shared/fragments.generated';
import gql from 'graphql-tag';
import { BuildFieldsFragmentDoc } from '../../build/shared/fragments.generated';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type BuildsForSiteByFilterParamsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
  pagination?: Types.Maybe<Types.CursorPagination>;
  branch?: Types.Maybe<Types.Scalars['String']>;
  runnerType?: Types.Maybe<Types.BuildRunnerType>;
  buildStatus?: Types.Maybe<Types.BuildStatus>;
  buildType?: Types.Maybe<Types.BuildType>;
  date?: Types.Maybe<Types.Scalars['DateTime']>;
  startDate?: Types.Maybe<Types.Scalars['DateTime']>;
  endDate?: Types.Maybe<Types.Scalars['DateTime']>;
  keyword?: Types.Maybe<Types.Scalars['String']>;
}>;


export type BuildsForSiteByFilterParamsQuery = (
  { __typename?: 'Query' }
  & { buildsForSiteByFilterParams?: Types.Maybe<(
    { __typename?: 'BuildResults' }
    & { builds?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Build' }
      & BuildFieldsFragment
    )>>>, pageInfo?: Types.Maybe<(
      { __typename?: 'CursorPageInfo' }
      & Pick<Types.CursorPageInfo, 'hasNextPage' | 'endCursor'>
    )> }
  )> }
);


export const BuildsForSiteByFilterParamsDocument = gql`
    query BuildsForSiteByFilterParams($siteId: UUID!, $pagination: CursorPagination, $branch: String, $runnerType: BuildRunnerType, $buildStatus: BuildStatus, $buildType: BuildType, $date: DateTime, $startDate: DateTime, $endDate: DateTime, $keyword: String) {
  buildsForSiteByFilterParams(
    siteId: $siteId
    pagination: $pagination
    branch: $branch
    runnerType: $runnerType
    buildStatus: $buildStatus
    buildType: $buildType
    date: $date
    startDate: $startDate
    endDate: $endDate
    keyword: $keyword
  ) {
    builds @connection(key: "filteredBuilds") {
      ...buildFields
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${BuildFieldsFragmentDoc}`;

/**
 * __useBuildsForSiteByFilterParamsQuery__
 *
 * To run a query within a React component, call `useBuildsForSiteByFilterParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildsForSiteByFilterParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildsForSiteByFilterParamsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      pagination: // value for 'pagination'
 *      branch: // value for 'branch'
 *      runnerType: // value for 'runnerType'
 *      buildStatus: // value for 'buildStatus'
 *      buildType: // value for 'buildType'
 *      date: // value for 'date'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useBuildsForSiteByFilterParamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BuildsForSiteByFilterParamsQuery, BuildsForSiteByFilterParamsQueryVariables>) {
        return ApolloReactHooks.useQuery<BuildsForSiteByFilterParamsQuery, BuildsForSiteByFilterParamsQueryVariables>(BuildsForSiteByFilterParamsDocument, baseOptions);
      }
export function useBuildsForSiteByFilterParamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BuildsForSiteByFilterParamsQuery, BuildsForSiteByFilterParamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BuildsForSiteByFilterParamsQuery, BuildsForSiteByFilterParamsQueryVariables>(BuildsForSiteByFilterParamsDocument, baseOptions);
        }
export type BuildsForSiteByFilterParamsQueryHookResult = ReturnType<typeof useBuildsForSiteByFilterParamsQuery>;
export type BuildsForSiteByFilterParamsLazyQueryHookResult = ReturnType<typeof useBuildsForSiteByFilterParamsLazyQuery>;
export type BuildsForSiteByFilterParamsQueryResult = ApolloReactCommon.QueryResult<BuildsForSiteByFilterParamsQuery, BuildsForSiteByFilterParamsQueryVariables>;

import * as React from "react"
import {
  InputFieldBlock,
  ThemeCss,
  Button,
  VisuallyHidden,
} from "gatsby-interface"
import keywordInputBg from "../assets/keywordInputBg.svg"
import { MdClose } from "react-icons/md"

export type BuildKeywordFilterProps = {
  value: string
  onChange: React.Dispatch<React.SetStateAction<string>>
  className?: string
}

export function BuildKeywordFilter({
  value,
  onChange,
  className,
}: BuildKeywordFilterProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const handleResetClick = () => {
    onChange(``)
  }

  return (
    <div css={wrapperCss} className={className}>
      <InputFieldBlock
        id="build-keywords-filter"
        label="Search keywords"
        labelSize="S"
        value={value}
        onChange={handleChange}
        css={customInputCss}
        autoComplete="off"
      />
      <Button
        css={resetBtnCss}
        size="S"
        variant="GHOST"
        tone="NEUTRAL"
        onClick={handleResetClick}
      >
        <MdClose />
        <VisuallyHidden>Clear Search input</VisuallyHidden>
      </Button>
    </div>
  )
}

/* styles */

const wrapperCss: ThemeCss = theme => ({
  position: `relative`,
})

const customInputCss: ThemeCss = theme => ({
  input: {
    paddingLeft: theme.space[8],
    backgroundImage: `url(${keywordInputBg})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `.4rem center`,
    position: `relative`,
  },
})

const resetBtnCss: ThemeCss = theme => ({
  position: `absolute`,
  right: theme.space[3],
  bottom: `.35rem`,
  zIndex: 1,
  padding: 0,

  svg: {
    width: theme.space[7],
    height: theme.space[7],
    color: theme.colors.grey[40],

    "&:hover": {
      animation: `none`,
    },
  },
})

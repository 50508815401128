import * as React from "react"
import { Redirect } from "@gatsbyjs/reach-router"
import Loading from "@modules/ui/components/Loading"
import { recordFirstTimeVisitor } from "@modules/fullStory"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { acceptInvitation as acceptInvitationText } from "@modules/locales/default.js"
import { useAcceptInvite } from "./InvitationRedirect.helpers"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { Spacer } from "gatsby-interface"
import { useAcceptSiteTransfer } from "@modules/site/transfer/hooks/useAcceptSiteTransfer"

export type InvitationRedirectProps = {
  invitationId: string
}

export function InvitationRedirect({ invitationId }: InvitationRedirectProps) {
  const { trackSegment, trackAction } = useTracker()
  const [redirectPath, setRedirectPath] = React.useState<string>(``)
  const [success, setSuccess] = React.useState<boolean>(false)
  const [setError, errorAlert] = useTriggerErrorAlert()

  const [acceptInvite] = useAcceptInvite()

  const siteTransfer = useAcceptSiteTransfer(invitationId, {
    setError,
  })

  React.useEffect(() => {
    // triggers the regular invitation acceptation only if the invitation's type is the regular one (not site transfer)
    if (siteTransfer.notValid) {
      acceptInvite(invitationId, setRedirectPath, setSuccess, setError)
    }
  }, [siteTransfer.notValid])

  if (errorAlert) {
    return (
      <StandardSingleColumn>
        <Spacer size={10} />
        {errorAlert}
      </StandardSingleColumn>
    )
  }

  // redirects to site transfer screen if the invitation if invitatiion's type fits
  if (siteTransfer.valid) {
    return <Redirect to={`/dashboard/site-transfer/${invitationId}`} noThrow />
  }

  if (success) {
    recordFirstTimeVisitor()
    trackAction({
      eventType: `TRACK_EVENT`,
      name: `Invitation to Gatsby Cloud accepted, authenticated user`,
      uiSource: `Dashboard header`,
    })
    trackSegment({
      type: SegmentEventType.Track,
      event: `Invitation to Gatsby Cloud accepted, authenticated user`,
      properties: {
        invitationId,
      },
    })

    if (redirectPath) {
      return <Redirect to={redirectPath} noThrow />
    }
    return <Redirect to="/dashboard/sites" noThrow />
  }

  return (
    <Loading
      delay={1000}
      message={
        siteTransfer
          ? acceptInvitationText.messages.loadingInvitation
          : acceptInvitationText.messages.acceptingInvitation
      }
    />
  )
}

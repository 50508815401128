import * as React from "react"
import { parse } from "query-string"

const isValidUtm = (
  queryParam: string | string[] | null | undefined
): queryParam is string => {
  return queryParam !== "" && typeof queryParam === "string"
}

const parseCookie = (cookie: string): { [key: string]: string } => {
  if (cookie === "") return {}

  return cookie
    .split(/;\s*/)
    .reduce((merged: { [key: string]: string }, values: string) => {
      const [key, value] = values.split("=")
      merged[key] = window.decodeURIComponent(value)
      return merged
    }, {})
}

export type UtmLeadCaptureValues = {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  utm_term: string
}

export const useUtmFields = (): UtmLeadCaptureValues => {
  // Use localStorage for utm values
  const [utmSource, setUtmSource] = React.useState("")
  const [utmMedium, setUtmMedium] = React.useState("")
  const [utmCampaign, setUtmCampaign] = React.useState("")
  const [utmContent, setUtmContent] = React.useState("")
  const [utmTerm, setUtmTerm] = React.useState("")

  // Save browser cookie to state in order to run effect hook on change
  const [latestCookie, setLatestCookie] = React.useState(
    window?.document?.cookie
  )

  /*
   * Checks browser cookie on an interval to see if it has been updated (by GTM
   * script, for example)
   */

  React.useEffect(() => {
    const detectCookieUpdate = () => {
      setLatestCookie(latest => {
        return window.document.cookie !== latest
          ? window.document.cookie
          : latest
      })
    }
    const interval = window.setInterval(detectCookieUpdate, 3000)
    return () => window.clearInterval(interval)
  }, [])

  /*
   * On mount, and on cookie updates, check cookie for utm fields (set via Google Tag
   * Manager)
   * This is used to attribute Marketing efforts (Twitter, Ads, etc.)
   */
  React.useEffect(() => {
    const cookies = parseCookie(latestCookie)

    if (cookies.__gtm_campaign_url) {
      // Grab only the query string of the Url in GTM cookie
      const queryString = cookies.__gtm_campaign_url.split("?")[1]

      // Parse the query string for utm fields
      const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      } = parse(queryString) as { [key: string]: string | undefined }

      // Only set utm fields if they exist and are valid
      if (utm_source && isValidUtm(utm_source)) {
        setUtmSource(utm_source)
      }
      if (utm_medium && isValidUtm(utm_medium)) {
        setUtmMedium(utm_medium)
      }
      if (utm_campaign && isValidUtm(utm_campaign)) {
        setUtmCampaign(utm_campaign)
      }
      if (utm_content && isValidUtm(utm_content)) {
        setUtmContent(utm_content)
      }
      if (utm_term && isValidUtm(utm_term)) {
        setUtmTerm(utm_term)
      }
    }
  }, [latestCookie])

  return {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
    utm_term: utmTerm,
  } as UtmLeadCaptureValues
}

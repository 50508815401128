import Alert from "@reach/alert"
import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { MdArrowUpward } from "react-icons/md"
import { keyframes } from "@emotion/core"
import { functionsView as text } from "@modules/locales/default"

export function SeeRecentLogsAlert({
  anchor,
}: {
  anchor: React.MutableRefObject<HTMLDivElement | null>
}) {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (anchor.current) {
      anchor.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }

  return (
    <div css={rootCss}>
      <Alert type="polite" css={alertCss}>
        <a href="#top" onClick={handleClick}>
          {text.actions.seeRecentLogs} <MdArrowUpward />
        </a>
      </Alert>
    </div>
  )
}

/* styles */

const entry = keyframes`
  0% {
    transform: translate3d(-50%,-100%,0);
  }
  30% {
    transform: translate3d(-50%,50%,0);
  }
  100% {
    transform: translate3d(-50%,0,0);
  }
`

const rootCss: ThemeCss = theme => ({
  height: 0,
  position: `sticky`,
  top: 10,
  zIndex: 1,
})

const alertCss: ThemeCss = theme => ({
  a: {
    animation: `${entry} .5s ease-out forwards`,
    padding: `${theme.space[2]} ${theme.space[6]}`,
    borderRadius: theme.radii[5],
    position: `absolute`,
    whiteSpace: `nowrap`,
    left: `50%`,
    transform: `translate3d(-50%, 0 ,0)`,
    textDecoration: `none`,
    display: `inline-flex`,
    alignItems: `center`,
    color: theme.colors.purple[60],
    background: theme.colors.white,
    fontWeight: theme.fontWeights.bold,
    transition: `.15s background-color`,

    "&:hover": {
      background: theme.colors.purple[60],
      color: theme.colors.white,
    },

    svg: {
      fontSize: theme.fontSizes[4],
      marginLeft: theme.space[3],
      marginRight: `-${theme.space[2]}`,
    },
  },
})

import React, { Fragment } from "react"

import {
  Heading,
  Button,
  Spacer,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  EmptyState,
} from "gatsby-interface"
import {
  Table,
  TableBody,
  TableRow,
  TableHeader,
} from "@modules/ui/components/Table"
import { PersonalAccessToken } from "@gatsbyjs/graphql"
import { PersonalAccessTokenRow } from "./PersonalAccessTokenRow"
import { userSettings as text, ui as uiText } from "@modules/locales/default.js"
import { useDeletePersonalAccessTokenMutation } from "@modules/user-settings/queries.generated"
import { MdArrowForward } from "react-icons/md"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import format from "date-fns/format"

export function PersonalAccessTokensTable({
  tokens,
  handleClick,
}: {
  tokens: PersonalAccessToken[]
  handleClick: () => void
}) {
  const [deletePersonalAccessTokenMutation, { loading, error }] =
    useDeletePersonalAccessTokenMutation()
  const [openDeleteAllModal, setOpenDeleteAllModal] = React.useState(false)
  const handleClose = () => setOpenDeleteAllModal(false)

  const openDeleteAllModalCallback = React.useCallback(() => {
    setOpenDeleteAllModal(true)
  }, [])

  const handleDeleteAllTokens = async () => {
    const deletePromise = tokens.map(token => {
      return deletePersonalAccessTokenMutation({
        variables: {
          id: token.id,
        },
        refetchQueries: [`PersonalAccessTokens`],
      })
    })

    try {
      await Promise.all(deletePromise)
      handleClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      <Heading
        as="h2"
        css={theme => ({
          fontSize: theme.fontSizes[4],
          marginBottom: theme.space[6],
        })}
      >
        {text.personalAccessTokens.header}
      </Heading>
      <p>{text.personalAccessTokens.description}</p>
      {tokens.length === 0 && (
        <EmptyState
          variant="WITH_BACKGROUND"
          heading={text.personalAccessTokens.emptyStateHeading}
          text={text.personalAccessTokens.emptyStateContent}
        />
      )}
      {tokens.length > 0 && (
        <Table>
          <TableBody>
            <Fragment>
              <TableRow>
                <TableHeader
                  css={theme => ({
                    fontSize: theme.fontSizes[2],
                  })}
                >
                  {text.personalAccessTokens.tableHeaderName}
                </TableHeader>
                <TableHeader
                  css={theme => ({
                    fontSize: theme.fontSizes[2],
                  })}
                >
                  {text.personalAccessTokens.tableHeaderDate}
                </TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
              {tokens.map(({ id, name, lastUsedAt }) => (
                <PersonalAccessTokenRow
                  key={id}
                  id={id}
                  name={name ? name : "Unnamed token"}
                  lastUsedAt={
                    lastUsedAt
                      ? format(new Date(lastUsedAt), `MMMM do, yyyy`)
                      : `Never`
                  }
                />
              ))}
            </Fragment>
          </TableBody>
        </Table>
      )}
      <Spacer size={3} direction="horizontal" />
      <div css={{ display: "flex" }}>
        <Button variant="PRIMARY" size="M" onClick={handleClick}>
          Add new token
        </Button>
        <Spacer size={3} direction="horizontal" />
        <Button
          variant="SECONDARY"
          tone="DANGER"
          size="M"
          onClick={openDeleteAllModalCallback}
        >
          {text.personalAccessTokens.deleteAllButton}
        </Button>
      </div>

      {error && <ErrorAlert>{error.message}</ErrorAlert>}
      <Modal
        aria-label={text.personalAccessTokens.deleteAllLabel}
        onDismiss={handleClose}
        isOpen={openDeleteAllModal}
        type="error"
      >
        <ModalCard>
          <StyledModal variant="WARNING">
            <StyledModalHeader onCloseButtonClick={handleClose}>
              {text.personalAccessTokens.deleteAllHeading}
            </StyledModalHeader>
            <StyledModalBody>
              {text.personalAccessTokens.deleteAllBody}
              <StyledModalActions>
                <Button
                  onClick={handleClose}
                  disabled={loading}
                  variant="SECONDARY"
                  tone="DANGER"
                >
                  {uiText.actions.cancel}
                </Button>

                <Button
                  rightIcon={<MdArrowForward />}
                  tone="DANGER"
                  onClick={handleDeleteAllTokens}
                  loading={loading}
                >
                  {text.personalAccessTokens.deleteAllConfirmation}
                </Button>
              </StyledModalActions>
            </StyledModalBody>
          </StyledModal>
        </ModalCard>
      </Modal>
    </div>
  )
}

import { leadCapture as text } from "@modules/locales/default.js"

export const ProjectType = {
  CompanyProject: `COMPANY_PROJECT`,
  ClientProject: `CLIENT_PROJECT`,
  PersonalProject: `PERSONAL_PROJECT`,
}

export const projectTypeOptions = [
  {
    label: text.labels.projectTypeCompany,
    value: ProjectType.CompanyProject,
  },
  {
    label: text.labels.projectTypeClient,
    value: ProjectType.ClientProject,
  },
  {
    label: text.labels.projectTypePersonal,
    value: ProjectType.PersonalProject,
  },
]

import * as React from "react"
import {
  MachinePricingTier,
  SubscriptionPlan,
  BillingInterval,
} from "@modules/graphql/types"
import { PlanInfo } from "@modules/billing/shared/constants/tiers"
import {
  Theme,
  ThemeCss,
  FormFieldset,
  FormLegend,
  visuallyHiddenCss,
  useAriaFormGroupField,
} from "gatsby-interface"
import { PlanSelectorCard } from "./PlanSelectorCard"
import {
  PlanProfile,
  PlanSignup,
  usePlanProfiles,
} from "../constants/planProfiles"
import { PlanSelectorInterval } from "./PlanSelectorInterval"
import {
  isPricingPage,
  isCreateOrgPage,
  checkDuplicatedBillingPlans,
  checkNotMatchingBillingPlans,
  findBillingPlan,
} from "./PlanSelector.helpers"
import { useLocation } from "@gatsbyjs/reach-router"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

const EXPECTED_BILLING_PLANS_AMOUNT = 7

export type PlanSelectorProps = {
  selectedPlan: PlanInfo
  onChange: (value: PlanInfo) => void
  currentPlan?: SubscriptionPlan
  currentPlanOnTrial?: boolean
  billingPlans?: SubscriptionPlan[]
  freeIsUnavailable?: boolean
  professionalIsUnavailable?: boolean
}

export function PlanSelector({
  selectedPlan,
  onChange,
  currentPlan,
  currentPlanOnTrial,
  billingPlans,
  freeIsUnavailable,
  professionalIsUnavailable,
  ...rest
}: PlanSelectorProps) {
  const location = useLocation()
  const planProfiles = usePlanProfiles()
  const isPricingPageSelector = isPricingPage(location)
  const isPickPlanForWorkspace = isCreateOrgPage(location)
  const [billingPlansBroken, setBillingPlansBroken] = React.useState(false)

  React.useEffect(() => {
    if (!selectedPlan.planId && billingPlans) {
      const billingPlan = findBillingPlan({ billingPlans, selectedPlan })

      if (billingPlan) {
        onChange({
          planId: billingPlan.id,
          planName: billingPlan.name,
          tier: billingPlan.baseFeatures?.tier,
          billingInterval: billingPlan.interval || selectedPlan.billingInterval,
          buildsTier: billingPlan.buildsTier,
          hostingTier: billingPlan.hostingTier,
        })
      }
    }
  }, [billingPlans])

  // for Create new workspace flow shows only DIY plan cards (currently Free and Professional)
  if (isPickPlanForWorkspace) {
    planProfiles.order = planProfiles.order.filter(
      plan => planProfiles.data?.[plan]?.signupType !== PlanSignup.ViaSales
    )
  }

  const { getLegendProps, getOptionControlProps, getOptionLabelProps } =
    useAriaFormGroupField(`plan`, {
      required: true,
    })

  React.useEffect(() => {
    if (billingPlans && billingPlans.length > 0) {
      // if ...

      // there is duplicated plan (with the same tier & interval)
      const isDuplicatedPlan = checkDuplicatedBillingPlans(billingPlans)

      // or there is more than expected number of billing plans
      const isTooManyPlans = billingPlans.length > EXPECTED_BILLING_PLANS_AMOUNT

      // or at least one of the provided billing plans has price (amount) not matching with the corresponding plan profile
      const isNotMatchingPlan = checkNotMatchingBillingPlans(
        billingPlans,
        planProfiles.data
      )

      // it hides the selector and render error component instead
      if (isDuplicatedPlan || isTooManyPlans || isNotMatchingPlan) {
        setBillingPlansBroken(true)
      }
    }
  }, [billingPlans])

  const handleTierChange = (val: PlanProfile) => {
    if (!billingPlans) {
      return
    }

    // map chosen planProfile to billingPlan
    const billingPlan = findBillingPlan({
      billingPlans,
      selectedPlan,
      profile: val,
    })

    if (billingPlan) {
      onChange({
        planId: billingPlan.id,
        planName: billingPlan.name,
        tier: billingPlan.baseFeatures?.tier,
        billingInterval: billingPlan.interval || selectedPlan.billingInterval,
        buildsTier: billingPlan.buildsTier,
        hostingTier: billingPlan.hostingTier,
      })
    }
  }

  const handleIntervalChange = (val: BillingInterval) => {
    // on Pricing page we do not need to map selected interval and profile to proper billing plan
    // so updating billingInterval value is enough
    if (isPricingPageSelector) {
      onChange({ ...selectedPlan, billingInterval: val })

      return
    }

    if (!billingPlans) {
      return
    }

    // map chosen planProfile & interval to billingPlan
    const billingPlan = findBillingPlan({
      billingPlans,
      selectedPlan,
      interval: val,
    })

    if (billingPlan) {
      onChange({
        planId: billingPlan.id,
        planName: billingPlan.name,
        tier: billingPlan.baseFeatures?.tier,
        billingInterval: billingPlan.interval || val,
        buildsTier: billingPlan.buildsTier,
        hostingTier: billingPlan.hostingTier,
      })
    } else {
      onChange({ ...selectedPlan, billingInterval: val })
    }
  }

  if (billingPlansBroken) {
    return (
      <ErrorAlert>
        Plan selector not available. Please contact Support!
      </ErrorAlert>
    )
  }

  return (
    <div css={theme => rootCss(theme, isPricingPageSelector)} {...rest}>
      <PlanSelectorInterval
        interval={selectedPlan?.billingInterval}
        onChange={handleIntervalChange}
        css={intervalCss}
      />

      <FormFieldset css={formFieldCss}>
        <FormLegend
          {...getLegendProps(`Select a plan`)}
          css={visuallyHiddenCss}
        />
        <div
          css={theme =>
            plansCss({ theme, numOfColumns: planProfiles.order?.length })
          }
        >
          {planProfiles.order.map(item => {
            const planProfile = planProfiles?.data?.[item]

            if (!planProfile) {
              return null
            }

            const isCurrentPlan =
              currentPlan?.baseFeatures?.tier === planProfile.tier &&
              (currentPlan?.interval === selectedPlan?.billingInterval ||
                planProfile.tier === MachinePricingTier.Free)

            const isFreePlan = planProfile.tier === MachinePricingTier.Free
            const isProfessional =
              planProfile.tier === MachinePricingTier.Professional

            const isDisabledCard = isCurrentPlan && !currentPlanOnTrial

            const isStaticCard =
              isPricingPage(location) ||
              (isFreePlan && freeIsUnavailable) ||
              (isProfessional &&
                professionalIsUnavailable &&
                currentPlan?.baseFeatures?.tier !==
                  MachinePricingTier.Professional)

            const isSelectedCard = planProfile.tier === selectedPlan?.tier

            return (
              <PlanSelectorCard
                key={planProfile?.name}
                plan={planProfile}
                isSelected={isSelectedCard}
                isCurrentPlan={isCurrentPlan}
                currentPlanOnTrial={currentPlanOnTrial}
                isDisabled={isDisabledCard}
                onSelect={handleTierChange}
                billingInterval={selectedPlan?.billingInterval}
                getOptionControlProps={getOptionControlProps}
                getOptionLabelProps={getOptionLabelProps}
                isStatic={isStaticCard}
              />
            )
          })}
        </div>
      </FormFieldset>
    </div>
  )
}

/* styles */

const rootCss = (theme: Theme, isPricingPageSelector?: boolean) => ({
  display: `grid`,
  gridGap: isPricingPageSelector ? theme.space[15] : theme.space[10],
})

const intervalCss: ThemeCss = _theme => ({
  display: `flex`,
  justifyContent: `center`,
  margin: 0,
})

const formFieldCss: ThemeCss = _theme => ({
  display: `block`,
  width: `100%`,
})

const plansCss = ({
  theme,
  numOfColumns,
}: {
  theme: Theme
  numOfColumns?: number
}) => ({
  display: `grid`,
  gridTemplateColumns: `minmax(250px, 400px)`,

  gap: theme.space[10],
  alignItems: `stretch`,
  justifyContent: `center`,

  [theme.mediaQueries.tablet]: {
    gridTemplateColumns:
      numOfColumns === 4 ? `repeat(2, minmax(250px, 350px))` : `1fr`,
  },

  [theme.mediaQueries.hd]: {
    gridTemplateColumns:
      numOfColumns === 2
        ? `repeat(${numOfColumns}, minmax(250px, 350px))`
        : `repeat(${numOfColumns}, minmax(250px, 320px))`,

    gap: theme.space[8],
  },
})

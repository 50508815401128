import * as React from "react"
import { Avatar, Theme, ThemeCss } from "gatsby-interface"
import { CmsVendorLogoIcon, CmsVendorName } from "@modules/cms/shared/constants"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import gatsbyLogoIcon from "../../../assets/icons/gatsby-icon.png"
import {
  BuildType,
  BuildSource,
  BuildCommit,
  User,
  CmsVendor,
} from "@modules/graphql/types"

const ICON_SIZE = `1rem`

export type AuthorInfo = {
  name?: string | null
  avatarUrl?: string | null
}

const baseCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  lineHeight: theme.lineHeights.solid,
  whiteSpace: `nowrap`,
})

const customAvatarCss: ThemeCss = theme => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
})

export type BuildAuthorProps = {
  buildType: BuildType
  source?: BuildSource
  commit?: Partial<BuildCommit>
  author?: Partial<User>
  className?: string
}

const AVATAR_SIZE = `XS`

export default function BuildAuthor({
  buildType,
  commit,
  author,
  source,
  className,
}: BuildAuthorProps) {
  const isSourceUpdate = buildType === BuildType.SourceUpdate

  let avatarOrLogo = null
  let name = ``

  // Check if an update was triggered via one of integrated CMS vendors or by Cloud

  if (source) {
    const SourceIcon = CmsVendorLogoIcon[(source as unknown) as CmsVendor]
    const sourceName = CmsVendorName[(source as unknown) as CmsVendor]

    if (sourceName) {
      name = `Via ${sourceName}`
    }

    if (SourceIcon) {
      avatarOrLogo = <SourceIcon width={ICON_SIZE} height={ICON_SIZE} />
    }

    if (source === BuildSource.GatsbyCloud) {
      name = `Gatsby Cloud`
      avatarOrLogo = (
        <Avatar
          size={AVATAR_SIZE}
          src={gatsbyLogoIcon}
          label={""}
          css={customAvatarCss}
        />
      )
    }
  }

  // Next we're going to check for build's or commit's author
  // If we find one, we can just replace CMS/Gatsby Cloud info from before with their avatar and name

  let authorInfo: AuthorInfo | undefined = author
  // "commit" has a shape similar to author: it has "avatarUrl" and "name" fields
  // hence, for source updates we can use "commit" as author info
  // with one change, instead of using github public name we use github login name
  if (isSourceUpdate && commit) {
    authorInfo = commit
    authorInfo.name = commit.login
  }

  if (authorInfo) {
    name = authorInfo.name || ``
    avatarOrLogo = (
      <Avatar
        size={AVATAR_SIZE}
        src={authorInfo.avatarUrl || ""}
        label={""}
        css={customAvatarCss}
      />
    )
  }

  if (!name) {
    return null
  }

  return (
    <div css={baseCss} className={className} data-cy="build-author">
      {avatarOrLogo}

      <span
        css={(theme: Theme) => ({
          marginLeft: theme.space[3],
        })}
        aria-hidden={true}
      >
        {name}
      </span>
      <span
        css={visuallyHiddenCss}
      >{`The author of the build is ${name}`}</span>
    </div>
  )
}

import * as React from "react"
import {
  ThemeCss,
  FreePlanIcon,
  ProfessionalPlanIcon,
  EnterprisePlanIcon,
  AgencyPlanIcon,
} from "gatsby-interface"

import { useTheme } from "gatsby-interface"

export type PlanTagProps = {
  name?: string
}

export function PlanTag({ name, ...rest }: PlanTagProps) {
  const { colors } = useTheme()

  if (!name) {
    return null
  }

  const Plans: {
    [key: string]: {
      icon?: JSX.Element
      color: string
    }
  } = {
    Free: {
      icon: <FreePlanIcon />,
      color: colors.blue[80],
    },
    Professional: {
      icon: <ProfessionalPlanIcon />,
      color: colors.magenta[50],
    },
    Agency: {
      icon: <AgencyPlanIcon />,
      color: colors.purple[60],
    },
    Enterprise: {
      icon: <EnterprisePlanIcon />,
      color: colors.grey[90],
    },
  }

  const color = name && Plans[name] ? Plans[name].color : colors.grey[80]
  const icon = name && Plans[name] ? Plans[name].icon : null

  return (
    <span
      css={theme => [
        rootCss(theme),
        {
          color: color,
        },
      ]}
      {...rest}
    >
      {icon} {name} Plan
    </span>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  display: `inline-flex`,
  alignItems: `center`,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.body,

  svg: {
    marginRight: theme.space[1],
  },
})

// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type FunctionsForSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
  buildId?: Types.Maybe<Types.Scalars['UUID']>;
}>;


export type FunctionsForSiteQuery = (
  { __typename?: 'Query' }
  & { functionsForSite?: Types.Maybe<Array<(
    { __typename?: 'SiteFunctionEntry' }
    & Pick<Types.SiteFunctionEntry, 'id' | 'creationBuildId' | 'path' | 'sha' | 'status'>
  )>> }
);

export type FunctionEntryQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
  functionId: Types.Scalars['UUID'];
}>;


export type FunctionEntryQuery = (
  { __typename?: 'Query' }
  & { functionEntry: (
    { __typename?: 'SiteFunctionEntry' }
    & Pick<Types.SiteFunctionEntry, 'id' | 'creationBuildId' | 'path' | 'sha' | 'status'>
  ) }
);

export type FunctionInvocationsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
  functionId: Types.Scalars['UUID'];
  buildId?: Types.Maybe<Types.Scalars['UUID']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  beforeStartedAt?: Types.Maybe<Types.Scalars['DateTime']>;
}>;


export type FunctionInvocationsQuery = (
  { __typename?: 'Query' }
  & { functionInvocations: Array<(
    { __typename?: 'FunctionInvocation' }
    & Pick<Types.FunctionInvocation, 'id' | 'functionId' | 'method' | 'status' | 'startedAt' | 'endedAt'>
    & { logs: Array<(
      { __typename?: 'Log' }
      & Pick<Types.Log, 'id' | 'timestamp' | 'message' | 'sourceStream' | 'command' | 'invocationId'>
    )> }
  )> }
);

export type FunctionInvocationCreatedSubscriptionVariables = Types.Exact<{
  functionId: Types.Scalars['UUID'];
  buildId: Types.Scalars['UUID'];
}>;


export type FunctionInvocationCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { functionInvocationCreated?: Types.Maybe<(
    { __typename?: 'FunctionInvocation' }
    & Pick<Types.FunctionInvocation, 'id' | 'functionId' | 'method' | 'status' | 'startedAt' | 'endedAt'>
    & { logs: Array<(
      { __typename?: 'Log' }
      & Pick<Types.Log, 'id' | 'timestamp' | 'message' | 'sourceStream' | 'command' | 'invocationId'>
    )> }
  )> }
);


export const FunctionsForSiteDocument = gql`
    query FunctionsForSite($siteId: UUID!, $buildId: UUID) {
  functionsForSite(siteId: $siteId, buildId: $buildId) {
    id
    creationBuildId
    path
    sha
    status
  }
}
    `;

/**
 * __useFunctionsForSiteQuery__
 *
 * To run a query within a React component, call `useFunctionsForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionsForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionsForSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      buildId: // value for 'buildId'
 *   },
 * });
 */
export function useFunctionsForSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FunctionsForSiteQuery, FunctionsForSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<FunctionsForSiteQuery, FunctionsForSiteQueryVariables>(FunctionsForSiteDocument, baseOptions);
      }
export function useFunctionsForSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FunctionsForSiteQuery, FunctionsForSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FunctionsForSiteQuery, FunctionsForSiteQueryVariables>(FunctionsForSiteDocument, baseOptions);
        }
export type FunctionsForSiteQueryHookResult = ReturnType<typeof useFunctionsForSiteQuery>;
export type FunctionsForSiteLazyQueryHookResult = ReturnType<typeof useFunctionsForSiteLazyQuery>;
export type FunctionsForSiteQueryResult = ApolloReactCommon.QueryResult<FunctionsForSiteQuery, FunctionsForSiteQueryVariables>;
export const FunctionEntryDocument = gql`
    query FunctionEntry($siteId: UUID!, $functionId: UUID!) {
  functionEntry(siteId: $siteId, functionId: $functionId) {
    id
    creationBuildId
    path
    sha
    status
  }
}
    `;

/**
 * __useFunctionEntryQuery__
 *
 * To run a query within a React component, call `useFunctionEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionEntryQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      functionId: // value for 'functionId'
 *   },
 * });
 */
export function useFunctionEntryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FunctionEntryQuery, FunctionEntryQueryVariables>) {
        return ApolloReactHooks.useQuery<FunctionEntryQuery, FunctionEntryQueryVariables>(FunctionEntryDocument, baseOptions);
      }
export function useFunctionEntryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FunctionEntryQuery, FunctionEntryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FunctionEntryQuery, FunctionEntryQueryVariables>(FunctionEntryDocument, baseOptions);
        }
export type FunctionEntryQueryHookResult = ReturnType<typeof useFunctionEntryQuery>;
export type FunctionEntryLazyQueryHookResult = ReturnType<typeof useFunctionEntryLazyQuery>;
export type FunctionEntryQueryResult = ApolloReactCommon.QueryResult<FunctionEntryQuery, FunctionEntryQueryVariables>;
export const FunctionInvocationsDocument = gql`
    query FunctionInvocations($siteId: UUID!, $functionId: UUID!, $buildId: UUID, $limit: Int, $beforeStartedAt: DateTime) {
  functionInvocations(
    siteId: $siteId
    functionId: $functionId
    buildId: $buildId
    limit: $limit
    beforeStartedAt: $beforeStartedAt
  ) {
    id
    functionId
    logs {
      id
      timestamp
      message
      sourceStream
      command
      invocationId
    }
    method
    status
    startedAt
    endedAt
  }
}
    `;

/**
 * __useFunctionInvocationsQuery__
 *
 * To run a query within a React component, call `useFunctionInvocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionInvocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionInvocationsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      functionId: // value for 'functionId'
 *      buildId: // value for 'buildId'
 *      limit: // value for 'limit'
 *      beforeStartedAt: // value for 'beforeStartedAt'
 *   },
 * });
 */
export function useFunctionInvocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FunctionInvocationsQuery, FunctionInvocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<FunctionInvocationsQuery, FunctionInvocationsQueryVariables>(FunctionInvocationsDocument, baseOptions);
      }
export function useFunctionInvocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FunctionInvocationsQuery, FunctionInvocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FunctionInvocationsQuery, FunctionInvocationsQueryVariables>(FunctionInvocationsDocument, baseOptions);
        }
export type FunctionInvocationsQueryHookResult = ReturnType<typeof useFunctionInvocationsQuery>;
export type FunctionInvocationsLazyQueryHookResult = ReturnType<typeof useFunctionInvocationsLazyQuery>;
export type FunctionInvocationsQueryResult = ApolloReactCommon.QueryResult<FunctionInvocationsQuery, FunctionInvocationsQueryVariables>;
export const FunctionInvocationCreatedDocument = gql`
    subscription FunctionInvocationCreated($functionId: UUID!, $buildId: UUID!) {
  functionInvocationCreated(functionId: $functionId, buildId: $buildId) {
    id
    functionId
    logs {
      id
      timestamp
      message
      sourceStream
      command
      invocationId
    }
    method
    status
    startedAt
    endedAt
  }
}
    `;

/**
 * __useFunctionInvocationCreatedSubscription__
 *
 * To run a query within a React component, call `useFunctionInvocationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFunctionInvocationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionInvocationCreatedSubscription({
 *   variables: {
 *      functionId: // value for 'functionId'
 *      buildId: // value for 'buildId'
 *   },
 * });
 */
export function useFunctionInvocationCreatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<FunctionInvocationCreatedSubscription, FunctionInvocationCreatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<FunctionInvocationCreatedSubscription, FunctionInvocationCreatedSubscriptionVariables>(FunctionInvocationCreatedDocument, baseOptions);
      }
export type FunctionInvocationCreatedSubscriptionHookResult = ReturnType<typeof useFunctionInvocationCreatedSubscription>;
export type FunctionInvocationCreatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<FunctionInvocationCreatedSubscription>;

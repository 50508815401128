import * as React from "react"
import * as qs from "query-string"
import { Redirect } from "@gatsbyjs/reach-router"
import { WindowLocation } from "@gatsbyjs/reach-router"
import { Layout } from "@modules/user/shared/components/Layout"
import { Promotion } from "./Promotion"
import { UserForm } from "./UserForm"
import {
  twoColumnsCss,
  oneColumnCss,
} from "@modules/user/shared/stylesheets/layouts"
import {
  createLoginRedirectUrlObject,
  createLoginRedirectQuery,
} from "@modules/auth/components/AuthRedirect"
import { HomeLink } from "@modules/user/shared/components/HomeLink"
import { User } from "@modules/graphql/types"

export type WelcomeProps = {
  location?: WindowLocation
  user?: User
}

export function Welcome({ location, user }: WelcomeProps) {
  const query = qs.parse(location?.search || ``)

  if (user?.name && user?.lastName) {
    const { loginRedirectUrl } = query

    if (loginRedirectUrl) {
      const { search, pathname } = createLoginRedirectUrlObject(
        origin,
        loginRedirectUrl
      )
      const stringifiedQuery = createLoginRedirectQuery(search)

      return <Redirect to={`${pathname}?${stringifiedQuery}`} noThrow />
    }

    return <Redirect to={`/dashboard/sites?loggedin=1`} noThrow />
  }

  const isRetake = Boolean(query.retake)

  return (
    <Layout css={isRetake ? oneColumnCss : twoColumnsCss}>
      {isRetake ? <HomeLink /> : <Promotion />}
      <UserForm isRetake={isRetake} />
    </Layout>
  )
}

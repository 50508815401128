import * as React from "react"
import { navigate } from "gatsby"
import { useInvitationByIdQuery } from "@modules/invitation/queries.generated"
import { WatchQueryFetchPolicy } from "apollo-client"
import { useCurrentUser } from "@modules/auth"
import { useAcceptResourceInvitationMutation } from "@modules/invitation/queries.generated"
import { InvitationType, Organization } from "@modules/graphql/types"
import { useRevokeInvitationMutation } from "@modules/organization/mutations.generated"
import { useWaitingSiteTransferInvitation } from "./useWaitingSiteTransferInvitation"
import { useSiteTransferDetailsQuery } from "../../queries.generated"
import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"
import useTracker from "@modules/analytics/hooks/useTracker"
import { SegmentEventType } from "@modules/analytics"

export type Options = {
  fetchPolicy?: WatchQueryFetchPolicy
  setError?: React.Dispatch<React.SetStateAction<{ message: string } | null>>
}

export type SimplyfiedOrg = Pick<Organization, "name" | "id" | "billing">

export function useAcceptSiteTransfer(invitationId: string, options?: Options) {
  const { fetchPolicy = `cache-and-network`, setError } = options || {}

  const { trackSegment } = useTracker()

  const [targetWorkspace, setTargetWorkspace] = React.useState<
    SimplyfiedOrg | undefined
  >()
  const [transfering, setTransfering] = React.useState(false)
  const [declineInitialized, setDeclineInitialized] = React.useState(false)

  const { currentUser } = useCurrentUser()

  const {
    data,
    loading: invitationDataLoading,
    error,
  } = useInvitationByIdQuery({
    variables: {
      id: invitationId,
    },
    fetchPolicy,
  })

  const invitation = data?.invitationById

  const {
    data: siteTransferData,
    loading: siteTransferDataLoding,
  } = useSiteTransferDetailsQuery({
    variables: { id: invitation?.resourceId },
    skip: !invitation?.resourceId || Boolean(invitation.accepted),
  })

  const {
    data: siteDetailsData,
    loading: siteDetailsDataLoading,
  } = useSiteDetailsQuery({
    variables: { id: invitation?.resourceId },
    skip: !invitation?.resourceId || !invitation?.accepted,
  })

  const loading =
    invitationDataLoading || siteTransferDataLoding || siteDetailsDataLoading

  React.useEffect(() => {
    if (siteDetailsData && siteDetailsData?.siteDetails?.organization) {
      setTargetWorkspace(siteDetailsData.siteDetails.organization)
    }
  })

  const [acceptResourceInvitation] = useAcceptResourceInvitationMutation()
  const [revokeInvitation] = useRevokeInvitationMutation()

  const waitingInvitation = useWaitingSiteTransferInvitation()

  const handleAccept = (org?: SimplyfiedOrg) => {
    if (!org?.id) {
      return
    }

    setTransfering(true)

    acceptResourceInvitation({
      variables: {
        invitationId: invitationId,
        organizationId: org.id,
      },
    })
      .then(({ data }) => {
        setTransfering(false)
        const resultData = data?.acceptResourceInvitation
        const mutationResult = resultData?.mutationResult

        if (mutationResult?.success) {
          waitingInvitation.clean()

          trackSegment({
            type: SegmentEventType.Track,
            event: `Site Transfer Accepted`,
            properties: {
              organizationId: org?.id,
              siteId: invitation?.resourceId,
            },
          })

          return navigate(
            `/dashboard/${targetWorkspace?.id}/sites/${invitation?.resourceId}`
          )
        }

        if (mutationResult?.message) {
          return setError
            ? setError({ message: mutationResult.message })
            : console.error(mutationResult.message)
        }
      })
      .catch(e => {
        setTransfering(false)
        setError ? setError(e) : console.error(e)
      })
  }

  const handleDecline = () => {
    revokeInvitation({
      variables: {
        id: invitationId,
      },
    })
      .then(({ data }) => {
        const mutationResult = data?.revokeInvitation
        const success = mutationResult?.success

        if (success) {
          trackSegment({
            type: SegmentEventType.Track,
            event: `Site Transfer Declined`,
            properties: {
              siteId: invitation?.resourceId,
            },
          })

          return navigate(`/dashboard/sites`)
        }

        if (mutationResult?.message) {
          setDeclineInitialized(false)

          return setError
            ? setError({ message: mutationResult.message })
            : console.log(mutationResult.message)
        }
      })
      .catch(e => {
        setError ? setError(e) : console.error(e)
      })
    //
  }

  return {
    notValid:
      !loading &&
      (invitation?.invitationEmail !== currentUser?.email ||
        invitation?.type !== InvitationType.ParentOrganization),
    valid:
      !loading &&
      invitation?.invitationEmail === currentUser?.email &&
      invitation?.type === InvitationType.ParentOrganization,
    senderEmail: invitation?.senderEmail,
    senderName: invitation?.senderName,
    accepted: invitation?.accepted,
    expired: invitation?.expired,
    pending: invitation?.expired === false && invitation?.accepted === false,
    target: targetWorkspace,
    setTarget: setTargetWorkspace,
    accept: handleAccept,
    decline: () => setDeclineInitialized(true),
    confirmDecline: handleDecline,
    declineInitialized,
    cancelDecline: () => setDeclineInitialized(false),
    dismiss: () => waitingInvitation.store(invitationId),
    loading,
    transfering,
    error,
    invitation,
    siteName: siteTransferData?.siteTransferDetails?.siteName,
    sourceOrgName: siteTransferData?.siteTransferDetails?.orgName,
  }
}

import * as React from "react"
import { InputFieldBlock, Button } from "gatsby-interface"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { MdArrowForward } from "react-icons/md"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import {
  createOrganization as createOrgText,
  ui as uiText,
} from "@modules/locales/default.js"
import { useUserOrganizationsQuery } from "@modules/header/queries.generated"

export type SetUpOrganizationProps = {
  initialName: string
  onGoBack: () => void
  onGoNext: (name: string) => void
}

export function SetUpOrganization({
  initialName,
  onGoBack,
  onGoNext,
}: SetUpOrganizationProps) {
  const [error, setError] = React.useState<string>()
  const { data } = useUserOrganizationsQuery()
  const hasOrganizations = (data?.currentOrganizations || []).length > 0

  const validate = (value: string): boolean => {
    if (!value) {
      setError("Name is required")
      return false
    }
    setError(undefined)
    return true
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    const formElements = (e.currentTarget.elements as unknown) as {
      orgName: HTMLInputElement
    }
    const name = formElements["orgName"].value

    if (!validate(name) || error) {
      return
    }

    onGoNext(name)
  }

  const onBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    validate(e.target.value)
  }

  return (
    <form onSubmit={onSubmit}>
      <WizardStepHeader title={createOrgText.headers.setup}>
        {createOrgText.messages.setupDescription}
      </WizardStepHeader>
      <NarrowSingleColumn>
        <InputFieldBlock
          id="orgName"
          name="orgName"
          defaultValue={initialName}
          onBlur={onBlur}
          label={createOrgText.labels.orgName}
          required
          error={error}
        />
      </NarrowSingleColumn>
      <WizardFooter
        goBackButton={
          hasOrganizations ? (
            <Button variant="SECONDARY" onClick={() => onGoBack()}>
              {uiText.actions.cancel}
            </Button>
          ) : (
            <div />
          )
        }
        goNextButton={
          <Button type="submit" rightIcon={<MdArrowForward />}>
            {uiText.actions.next}
          </Button>
        }
      />
    </form>
  )
}

import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import format from "date-fns/format"
import { Log } from "@modules/graphql/types"

export type InvocationLogsProps = {
  logs: Log[]
}

export function InvocationLogs({ logs }: InvocationLogsProps) {
  return (
    <ol css={logsCss}>
      {logs.map(({ id, timestamp, message }, idx) => {
        let key: React.Key = idx

        if (id) {
          key = id
        } else if (message && timestamp) {
          key = `${message}--${timestamp}`
        }

        const timestampDate = timestamp ? new Date(timestamp) : null

        return (
          <li key={key} css={itemCss}>
            {timestampDate && (
              <React.Fragment>
                <time dateTime={timestampDate.toString()}>
                  {format(timestampDate, "HH:mm:ss aa:")}
                </time>
              </React.Fragment>
            )}
            {formatMessage(message as string)}
          </li>
        )
      })}
    </ol>
  )
}

/* helpers */

export function formatMessage(message: string): React.ReactNode {
  if (!message) {
    return
  }

  const formated = message.replace(
    /(^|\n)(Invocation )*(started|finished|\[ERROR\])/gi,
    (_: string, match1: string, match2: string, match3: string) => {
      const match = match3.replace(/\[|\]/gi, ``).toLowerCase()

      return `${match1}<span class="${match}">${match}</span>`
    }
  )

  return <p dangerouslySetInnerHTML={{ __html: formated }} />
}

/* styles */

const logsCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  marginLeft: theme.space[5],
  marginTop: theme.space[4],
})

const itemCss: ThemeCss = theme => [
  {
    margin: 0,
    lineHeight: 1.8,
    color: theme.colors.grey[30],

    time: {
      whiteSpace: `nowrap`,
      marginRight: theme.space[3],
      color: theme.colors.grey[40],
      fontStyle: `italic`,
    },

    p: {
      margin: 0,
      wordBreak: `break-word`,
      marginLeft: theme.space[3],

      span: {
        fontWeight: `bold`,

        "&.started": {
          color: theme.colors.green[40],
        },
        "&.finished": {
          color: theme.colors.blue[40],
        },

        "&.error": {
          color: theme.colors.red[30],
        },
      },
    },

    [theme.mediaQueries.desktop]: {
      display: `flex`,

      p: {
        marginLeft: 0,
      },
    },
  },
]

import * as React from "react"
import { ThemeCss, visuallyHiddenCss, Button } from "gatsby-interface"
import { BuildDateFilter } from "./BuildDateFilter"
import { BuildStatusFilter } from "./BuildStatusFilter"
import { BuildTypeFilter } from "./BuildTypeFilter"
import { BuildKeywordFilter } from "./BuildKeywordFilter"
import { MdErrorOutline } from "react-icons/md"
import {
  BuildStatus,
  BuildType,
  SourceControlProvider,
  CmsProvider,
} from "@modules/graphql/types"
import { LoadingIndicator } from "./BuildsFilterBar.parts"

export type BuildsFilterBarProps = {
  endDate: Date | null
  onEndDateChange: React.Dispatch<React.SetStateAction<Date | null>>
  startDate: Date | null
  onStartDateChange: React.Dispatch<React.SetStateAction<Date | null>>
  status: BuildStatus | null
  onStatusChange: React.Dispatch<React.SetStateAction<BuildStatus | null>>
  type: BuildType | null
  onTypeChange: React.Dispatch<React.SetStateAction<BuildType | null>>
  keyword: string
  onKeywordChange: React.Dispatch<React.SetStateAction<string>>
  sourceProvider?: SourceControlProvider | null
  cmsIntegrations?: CmsProvider[]
  loading: boolean
}

export function BuildsFilterBar({
  endDate,
  onEndDateChange,
  startDate,
  onStartDateChange,
  status,
  onStatusChange,
  type,
  onTypeChange,
  keyword,
  onKeywordChange,
  sourceProvider,
  cmsIntegrations,
  loading,
}: BuildsFilterBarProps) {
  const [error, setError] = React.useState<React.ReactNode>(``)

  const handleOnResetClick = () => {
    onStartDateChange(null)
    onEndDateChange(null)
    onStatusChange(null)
    onTypeChange(null)
    onKeywordChange(``)
  }

  const allFiltersAreEmpty =
    !endDate && !startDate && !type && !status && !keyword

  return (
    <div css={rootCss}>
      <h2 css={visuallyHiddenCss}>Build filters</h2>

      <div css={rowCss}>
        <LoadingIndicator loading={loading} />

        <BuildDateFilter
          startValue={startDate}
          onStartValueChange={onStartDateChange}
          endValue={endDate}
          onEndValueChange={onEndDateChange}
          css={dateCss}
          setError={setError}
        />

        <BuildStatusFilter
          value={status}
          onChange={onStatusChange}
          css={selectCss}
        />

        <BuildTypeFilter
          value={type}
          onChange={onTypeChange}
          css={selectCss}
          sourceProvider={sourceProvider}
          cmsIntegrations={cmsIntegrations}
        />

        <BuildKeywordFilter
          value={keyword}
          onChange={onKeywordChange}
          css={keywordCss}
        />

        <Button
          variant="SECONDARY"
          size="L"
          onClick={handleOnResetClick}
          disabled={allFiltersAreEmpty}
          css={resetBtnCss}
        >
          Reset all
        </Button>

        {error && (
          <div css={errorCss}>
            <MdErrorOutline /> {error}
          </div>
        )}
      </div>
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  background: theme.colors.grey[10],
  display: `grid`,
  padding: `${theme.space[5]} ${theme.space[5]} ${theme.space[2]} ${theme.space[4]}`,
})

const rowCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `start`,
  flexWrap: `wrap`,

  "& > *": {
    margin: `0 ${theme.space[3]} ${theme.space[4]} ${theme.space[3]}`,
  },

  label: {
    color: theme.colors.grey[60],
  },
})

const dateCss: ThemeCss = theme => ({
  minWidth: `15rem`,
  flexGrow: 2,
  zIndex: theme.zIndices.dropdowns,
})

const selectCss: ThemeCss = _theme => ({
  minWidth: `10rem`,
  flexGrow: 1,
})

const keywordCss: ThemeCss = _theme => ({
  flexGrow: 10,
})

const resetBtnCss: ThemeCss = theme => ({
  alignSelf: `flex-end`,
  marginTop: theme.space[2],
})

const errorCss: ThemeCss = theme => ({
  width: `100%`,
  color: theme.colors.red[80],
  fontSize: theme.fontSizes[0],
  paddingLeft: `2.3rem`,
  marginTop: `-${theme.space[2]}`,

  b: {
    fontWeight: theme.fontWeights.semiBold,
  },

  svg: {
    fontSize: theme.fontSizes[1],
    verticalAlign: `text-top`,
  },
})

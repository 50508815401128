import * as React from "react"
import { SelectFieldBlock } from "gatsby-interface"
import { BuildStatus } from "@modules/graphql/types"

const OPTIONS: { label: string; value: BuildStatus | `` }[] = [
  {
    label: "All",
    value: ``,
  },
  {
    label: "Successful",
    value: BuildStatus.Success,
  },
  {
    label: "Failed",
    value: BuildStatus.Error,
  },
]

export type BuildStatusFilterProps = {
  value: BuildStatus | null
  onChange: React.Dispatch<React.SetStateAction<BuildStatus | null>>
  className?: string
}

export function BuildStatusFilter(props: BuildStatusFilterProps) {
  const { value, onChange, className } = props

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === `` ? null : (e.target.value as BuildStatus)
    return onChange(value)
  }

  return (
    <SelectFieldBlock
      id="build-status-filter"
      label="Build status"
      labelSize="S"
      options={OPTIONS}
      value={value === null ? `` : value}
      onChange={handleChange}
      className={className}
    />
  )
}

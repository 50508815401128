import * as React from "react"
import { WatchQueryFetchPolicy } from "apollo-client"
import { useGetBuildQueueItemsForWorkspaceQuery } from "../queries.generated"

const INTERVAL = 3000

/**
 * A hook provides a list of builds in the workspace build queue
 */

export type Options = {
  fetchPolicy?: WatchQueryFetchPolicy
  isOpen?: boolean
}

export const useWorkspaceQueuedBuilds = (
  organizationId: string | null,
  options?: Options
) => {
  const { fetchPolicy = `network-only`, isOpen = false } = options || {}

  const variables = {
    workspaceId: organizationId,
  }

  const {
    data,
    loading,
    error,
    stopPolling,
    startPolling,
  } = useGetBuildQueueItemsForWorkspaceQuery({
    variables,
    fetchPolicy,
    skip: !isOpen,
  })

  React.useEffect(() => {
    if (isOpen) {
      startPolling(INTERVAL)
    } else {
      stopPolling()
    }
  }, [isOpen])

  const builds = data?.getBuildQueueItemsForWorkspace?.buildQueueItems

  return {
    data: builds,
    loading,
    error,
  }
}

import * as React from "react"
import * as qs from "query-string"
import { WindowLocation } from "@gatsbyjs/reach-router"
import { Layout } from "@modules/user/shared/components/Layout"
import { Authentication } from "@modules/user/shared/components/Authentication"
import { AuthenticationLabels } from "@modules/user/shared/constants/authentication"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { processSignupAttempt } from "@modules/auth/utils"
import { Promotion } from "./Promotion"
import { twoColumnsCss } from "@modules/user/shared/stylesheets/layouts"
import { SubscriptionStatus, MachinePricingTier } from "@modules/graphql/types"

export type SignupProps = {
  location?: WindowLocation
}

export function Signup({ location }: SignupProps) {
  const paramsString = location?.search || ""
  const params = qs.parse(paramsString)
  const { trackSegment, trackButtonClicked, trackAction } = useTracker()

  // set plan selection for users who get round the pricing page
  if (!params.selectedPlan || !params.selectedTier) {
    params.selectedPlan = "Professional"
    params.selectedTier = "PROFESSIONAL"
  }

  const planName = params.selectedPlan
  const isProfessional = planName === `Professional`

  return (
    <React.Fragment>
      <Layout css={twoColumnsCss}>
        <Promotion />

        <Authentication
          context="signup"
          paramsString={paramsString}
          onOptionClick={option => {
            trackButtonClicked(`Clicked to Sign In`, {
              name: AuthenticationLabels[option],
              uiSource: `Signup screen`,
            })
            trackSegment({
              type: SegmentEventType.Track,
              event: `Clicked to Sign In`,
              properties: {
                loginType: AuthenticationLabels[option],
              },
            })

            if (isProfessional) {
              trackAction({
                eventType: `TRACK_EVENT`,
                name: `Plan selected`,
                valueString: `${planName} (trial)`,
                uiSource: `Upgrade Page`,
              })
              trackSegment({
                type: SegmentEventType.Track,
                event: "Plan Selected",
                properties: {
                  location: "Upgrade Page",
                  plan: `${planName} (trial)`,
                  subscriptionState: `TRIAL`,
                },
              })
            }

            processSignupAttempt(option, params, {})
          }}
        />
      </Layout>
    </React.Fragment>
  )
}

import * as React from "react"
import { Heading, Text, ThemeCss } from "gatsby-interface"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"

const baseCss: ThemeCss = theme => ({
  marginTop: theme.space[8],
  marginBottom: theme.space[11],
  textAlign: `center`,
})

const headerCss: ThemeCss = theme => ({
  marginBottom: theme.space[5],
  fontSize: theme.fontSizes[4],
})

export type WizardStepHeaderProps = {
  title: React.ReactNode
  children?: React.ReactNode
}

export function WizardStepHeader({ title, children }: WizardStepHeaderProps) {
  return (
    <NarrowSingleColumn css={baseCss}>
      <Heading css={headerCss}>{title}</Heading>
      {children && <Text>{children}</Text>}
    </NarrowSingleColumn>
  )
}

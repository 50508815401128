import * as React from "react"
import { Link } from "gatsby"
import { ThemeCss } from "gatsby-interface"

export function HomeLink() {
  return (
    <Link to="/" aria-label="Link to home" css={rootCss}>
      <svg
        width="145"
        height="23"
        viewBox="0 0 145 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.6429 10.9167H13.6429V12.3453H17.0714C16.5714 14.4881 15 16.2739 12.9286 16.9881L4.71428 8.77386C5.57142 6.27386 8 4.48814 10.7857 4.48814C12.9286 4.48814 14.8571 5.55957 16.0714 7.20243L17.1429 6.27386C15.7143 4.34528 13.4286 3.05957 10.7857 3.05957C7.07142 3.05957 3.92857 5.70243 3.14285 9.20243L12.5714 18.631C16 17.7739 18.6429 14.631 18.6429 10.9167Z"
          fill="white"
        />
        <path
          d="M2.92856 10.9878C2.92856 12.9878 3.71427 14.9164 5.21427 16.4164C6.71427 17.9164 8.71427 18.7021 10.6428 18.7021L2.92856 10.9878Z"
          fill="white"
        />
        <path
          d="M10.7857 0.916504C5.28571 0.916504 0.785706 5.4165 0.785706 10.9165C0.785706 16.4165 5.28571 20.9165 10.7857 20.9165C16.2857 20.9165 20.7857 16.4165 20.7857 10.9165C20.7857 5.4165 16.2857 0.916504 10.7857 0.916504ZM5.21428 16.4879C3.71428 14.9879 2.92856 12.9879 2.92856 11.0594L10.7143 18.7736C8.71428 18.7022 6.71428 17.9879 5.21428 16.4879ZM12.5 18.5594L3.14285 9.20222C3.92856 5.70222 7.07142 3.05936 10.7857 3.05936C13.4286 3.05936 15.7143 4.34508 17.1428 6.27365L16.0714 7.20222C14.8571 5.55936 12.9286 4.48793 10.7857 4.48793C7.99999 4.48793 5.64285 6.27365 4.71428 8.77365L12.9286 16.9879C15 16.2736 16.5714 14.4879 17.0714 12.3451H13.6428V10.9165H18.6428C18.6428 14.6308 16 17.7736 12.5 18.5594Z"
          fill="#7026B9"
        />
        <path
          d="M107.697 14.4799C106.917 15.3999 105.817 15.9399 104.577 15.9399C102.337 15.9399 100.057 14.3399 100.057 11.3799C100.057 8.91992 101.797 6.71992 104.477 6.71992C104.957 6.71992 106.477 6.81992 107.697 8.21992V5.17992C106.477 4.51992 105.357 4.35992 104.417 4.35992C102.517 4.35992 100.857 4.85992 99.5169 6.11992C98.3769 7.17992 97.3769 8.91992 97.3769 11.3399C97.3769 13.2999 97.9769 14.9599 99.5169 16.4399C100.637 17.4999 102.057 18.2999 104.397 18.2999C105.837 18.2999 106.897 17.9399 107.697 17.5199V14.4799Z"
          fill="#8241C1"
        />
        <path
          d="M109.327 3.41992V17.9999H111.767V3.41992H109.327Z"
          fill="#8241C1"
        />
        <path
          d="M118.213 9.05992C116.573 9.05992 115.453 9.59992 114.713 10.2799C113.853 11.0599 113.253 12.2599 113.253 13.6799C113.253 15.0799 113.853 16.2799 114.713 17.0599C115.453 17.7399 116.573 18.2799 118.213 18.2799C119.853 18.2799 120.973 17.7399 121.713 17.0599C122.573 16.2799 123.173 15.0799 123.173 13.6799C123.173 12.2599 122.573 11.0599 121.713 10.2799C120.973 9.59992 119.853 9.05992 118.213 9.05992ZM118.213 16.1199C116.793 16.1199 115.773 14.9999 115.773 13.6799C115.773 12.3199 116.813 11.2199 118.213 11.2199C119.613 11.2199 120.653 12.3199 120.653 13.6799C120.653 14.9999 119.633 16.1199 118.213 16.1199Z"
          fill="#8241C1"
        />
        <path
          d="M124.66 9.33992V14.6399C124.66 15.4199 124.76 16.4599 125.72 17.2999C126.52 17.9999 127.8 18.2799 128.86 18.2799C129.92 18.2799 131.2 17.9999 132 17.2999C132.96 16.4599 133.06 15.4199 133.06 14.6399V9.33992H130.62V14.2399C130.62 14.6999 130.58 15.3199 130.1 15.7599C129.8 16.0199 129.32 16.1999 128.86 16.1999C128.4 16.1999 127.92 16.0199 127.62 15.7599C127.14 15.3199 127.1 14.6999 127.1 14.2399V9.33992H124.66Z"
          fill="#8241C1"
        />
        <path
          d="M141.623 10.4599C140.803 9.17992 139.503 9.05992 138.843 9.05992C136.283 9.05992 134.543 11.0999 134.543 13.6599C134.543 16.1999 136.303 18.2799 138.963 18.2799C139.583 18.2799 140.743 18.1399 141.623 16.8799V17.9999H144.063V3.41992H141.623V10.4599ZM139.463 11.2199C140.863 11.2199 141.863 12.2799 141.863 13.6599C141.863 15.0399 140.863 16.1199 139.463 16.1199C138.063 16.1199 137.063 15.0399 137.063 13.6599C137.063 12.2799 138.063 11.2199 139.463 11.2199Z"
          fill="#8241C1"
        />
        <path
          d="M40.78 13.0999H44.22C44 14.0599 43.48 14.7399 42.96 15.1399C42.22 15.6999 41.22 15.9399 40.34 15.9399C38.92 15.9399 37.98 15.3799 37.38 14.7999C36.82 14.2599 36.06 13.1999 36.06 11.4199C36.06 9.91992 36.64 8.63992 37.4 7.89992C38.22 7.09992 39.42 6.71992 40.48 6.71992C41.1 6.71992 41.8 6.85992 42.36 7.13992C42.78 7.35992 43.46 7.81992 43.92 8.71992L46.18 7.49992C45.48 6.29992 44.54 5.49992 43.68 5.05992C42.66 4.51992 41.6 4.35992 40.6 4.35992C38.02 4.35992 36.34 5.37992 35.34 6.39992C34.54 7.19992 33.38 8.77992 33.38 11.3599C33.38 14.3599 34.9 16.0399 35.36 16.4999C36.82 17.9599 38.54 18.2999 40.2 18.2999C41.86 18.2999 43.56 17.9199 44.94 16.5399C46.78 14.7199 46.9 11.9199 46.9 11.0199V10.8599H40.78V13.0999Z"
          fill="black"
        />
        <path
          d="M55.1734 10.4599C54.3534 9.17992 53.0534 9.05992 52.3934 9.05992C49.8334 9.05992 48.0934 11.0999 48.0934 13.6599C48.0934 16.1999 49.8534 18.2799 52.5134 18.2799C53.1334 18.2799 54.2934 18.1399 55.1734 16.8799V17.9999H57.6134V9.33992H55.1734V10.4599ZM53.0134 11.2199C54.4134 11.2199 55.4134 12.2799 55.4134 13.6599C55.4134 15.0399 54.4134 16.1199 53.0134 16.1199C51.6134 16.1199 50.6134 15.0399 50.6134 13.6599C50.6134 12.2799 51.6134 11.2199 53.0134 11.2199Z"
          fill="black"
        />
        <path
          d="M63.6597 11.4199V9.33992H61.9797V6.37992H59.5397V9.33992H58.5797V11.4199H59.5397V17.9999H61.9797V11.4199H63.6597Z"
          fill="black"
        />
        <path
          d="M70.4503 9.97992C69.3503 9.13992 68.3303 9.05992 67.6903 9.05992C66.9903 9.05992 66.1103 9.13992 65.4303 9.67992C64.9303 10.0999 64.5303 10.7999 64.5303 11.6399C64.5303 12.3399 64.7903 12.8799 65.1903 13.2999C65.7103 13.8399 66.4303 14.1199 67.0503 14.3399L67.6103 14.5399C67.9303 14.6599 68.3703 14.8799 68.3703 15.4199C68.3703 16.0199 67.8503 16.3599 67.1703 16.3599C66.4503 16.3599 65.7303 15.9199 65.2503 15.3599L63.9703 16.8399C64.5103 17.4199 65.5303 18.2799 67.3303 18.2799C68.3903 18.2799 69.2303 17.9999 69.9303 17.3599C70.6303 16.7199 70.8903 15.9399 70.8903 15.1599C70.8903 14.5999 70.7103 14.0799 70.4503 13.7199C69.9503 13.0399 68.9303 12.6999 68.2503 12.4799L67.6703 12.2999C66.9903 12.0799 66.8903 11.7399 66.8903 11.5199C66.8903 11.0999 67.2503 10.8199 67.8303 10.8199C68.0903 10.8199 68.6703 10.8599 69.2503 11.3999L70.4503 9.97992Z"
          fill="black"
        />
        <path
          d="M74.6563 3.41992H72.2163V17.9999H74.6563V16.8799C75.5963 18.1799 76.8563 18.2799 77.4163 18.2799C79.9763 18.2799 81.7363 16.2999 81.7363 13.6599C81.7363 11.0399 80.0363 9.05992 77.4763 9.05992C76.7363 9.05992 75.5163 9.25992 74.6563 10.4599V3.41992ZM76.8163 11.2199C78.2163 11.2199 79.2163 12.2799 79.2163 13.6599C79.2163 15.0399 78.2163 16.1199 76.8163 16.1199C75.4163 16.1199 74.4163 15.0399 74.4163 13.6599C74.4163 12.2799 75.4163 11.2199 76.8163 11.2199Z"
          fill="black"
        />
        <path
          d="M82.8472 22.5799H85.6072L92.5872 9.33992H89.8272L87.4272 14.2599L84.7472 9.33992H81.9072L86.0272 16.6999L82.8472 22.5799Z"
          fill="black"
        />
      </svg>
    </Link>
  )
}

/* styles */

const rootCss: ThemeCss = _theme => ({
  display: `flex`,
})

import * as React from "react"

export function LambdaGraphic() {
  return (
    <svg
      width="106"
      height="100"
      viewBox="0 0 106 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48" cy="48" r="48" fill="#F6EDFA" />
      <circle cx="71.5" cy="22.5" r="7.5" fill="#F1DEFA" />
      <circle cx="17.5" cy="64.5" r="5.5" fill="#F1DEFA" />
      <circle cx="48" cy="48" r="34.75" stroke="#D9BAE8" strokeWidth="0.5" />
      <path
        d="M21 99L44.5733 41.5725L39.9267 32.5H32.3333V23H43.6667C46.0467 23 48.0867 24.235 48.9367 25.9925L81.4067 89.5H89V99H77.6667C75.23 99 73.19 97.7175 72.3967 95.96L51.2033 54.5875L33.0133 99H21Z"
        fill="white"
        stroke="#7026B9"
        strokeWidth="0.5"
      />
      <circle cx="102" cy="71" r="4" fill="#F1DEFA" />
      <circle cx="5" cy="10" r="3" fill="#F1DEFA" />
    </svg>
  )
}

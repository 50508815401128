import * as React from "react"
import DatePicker from "react-datepicker"
import {
  translateDatesToInputString,
  validateInputValue,
  extractDatesFromInputValue,
} from "./BuildDateFilter.helpers"
import { CustomInput, SelectionActions } from "./BuildDateFilter.parts"
import "../stylesheets/datePicker.css"

export type BuildDateFilterProps = {
  startValue?: Date | null
  endValue?: Date | null
  onStartValueChange: React.Dispatch<React.SetStateAction<Date | null>>
  onEndValueChange: React.Dispatch<React.SetStateAction<Date | null>>
  className?: string
  setError: React.Dispatch<React.SetStateAction<React.ReactNode>>
}

export function BuildDateFilter({
  className,
  startValue,
  endValue,
  onStartValueChange,
  onEndValueChange,
  setError,
}: BuildDateFilterProps) {
  const [inputValue, setInputValue] = React.useState(`All`)
  const [isOpen, setIsOpen] = React.useState(false)

  // creates local variants of filter values to isolate the calendar interactions
  // this way we can define under what circumstances we want to invoke onValueChange callbacks
  // otherwise every single callendar interaction would trigger the build list refetching
  const [localStartValue, setLocalStartValue] = React.useState(startValue)
  const [localEndValue, setLocalEndValue] = React.useState(endValue)

  React.useEffect(() => {
    if (!localStartValue && !localEndValue) {
      return setInputValue(`All`)
    }

    setInputValue(translateDatesToInputString(localStartValue, localEndValue))
  }, [localStartValue, localEndValue])

  React.useEffect(() => {
    if (localStartValue !== startValue) {
      setLocalStartValue(startValue)
    }

    if (localEndValue !== endValue) {
      setLocalEndValue(endValue)
    }
  }, [startValue, endValue])

  /* event handlers */

  function handleCalendarChange([startValue, endValue]: [
    Date | null,
    Date | null
  ]) {
    setLocalStartValue(startValue)
    setLocalEndValue(endValue)
    setInputValue(translateDatesToInputString(startValue, endValue))
    setError(``)
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value)

    validateInputValue(e.target.value).then(isValid => {
      if (isValid) {
        setError(``)
      } else {
        return setError(
          <React.Fragment>
            Please update date format to pattern <b>mm/dd/yyyy</b> for a single
            day or <b>mm/dd/yyyy - mm/dd/yyyy</b> for a range of dates
          </React.Fragment>
        )
      }
    })
  }

  function handleInputBlur(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === ``) {
      resetAndClose()
    } else {
      const [
        startValueFromInput,
        endValueFromInput,
      ] = extractDatesFromInputValue(e.target.value)

      if (startValueFromInput) {
        onStartValueChange(new Date(startValueFromInput))

        if (endValueFromInput) {
          onEndValueChange(new Date(endValueFromInput))
        } else {
          setInputValue(`${startValueFromInput} - ${startValueFromInput}`)
          onEndValueChange(new Date(startValueFromInput))
        }
      }
    }
  }

  function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== `Tab`) {
      setIsOpen(false)
    }
  }

  function handleClearClick() {
    resetCalendar()
    setIsOpen(false)
  }

  function handleApplyClick() {
    if (localStartValue && localEndValue) {
      onStartValueChange(localStartValue)
      onEndValueChange(localEndValue)
    } else if (localStartValue && !localEndValue) {
      onStartValueChange(localStartValue)
      onEndValueChange(localStartValue)
    }

    setIsOpen(false)
  }

  function handleInputFocus() {
    setIsOpen(true)

    if (inputValue === `All`) {
      setInputValue(``)
    }
  }

  /* utils */

  function resetCalendar() {
    onStartValueChange(null)
    onEndValueChange(null)
    setLocalEndValue(null)
    setLocalStartValue(null)
  }

  function resetAndClose(close = false) {
    resetCalendar()
    setError(``)
    setInputValue(`All`)

    if (close) {
      setIsOpen(false)
    }
  }

  return (
    <div className={className}>
      <DatePicker
        open={isOpen}
        onChange={handleCalendarChange}
        startDate={localStartValue}
        endDate={localEndValue}
        selectsRange={true}
        customInput={
          <CustomInput
            handleOnChange={handleInputChange}
            handleOnBlur={handleInputBlur}
            inputValue={inputValue}
            handleOnKeyDown={handleInputKeyDown}
            handleOnFocus={handleInputFocus}
          />
        }
        isClearable={true}
        maxDate={new Date()}
        closeOnScroll={true}
        onClickOutside={() => setIsOpen(false)}
        formatWeekDay={day => day.charAt(0)}
      >
        <SelectionActions
          onClearClick={handleClearClick}
          onApplyClick={handleApplyClick}
          startDate={localStartValue}
        />
      </DatePicker>
    </div>
  )
}

import * as React from "react"
import { Notification, ThemeCss } from "gatsby-interface"
import { useTriggerZendeskWidget } from "@modules/zendesk"
import { FormattedMessage } from "@modules/locales"
export { useAuthError } from "../hooks/useAuthError"

export type AuthErrorProps = {
  heading?: string | null
  message: string
  className?: string
  dismissDetails?: {
    label: string
    path: string
  }
  withSupport?: boolean
}

export function AuthError({ message, className }: AuthErrorProps) {
  const triggerZendeskWidget = useTriggerZendeskWidget()

  return (
    <Notification
      variant="SECONDARY"
      tone="DANGER"
      role="alert"
      className={className}
      content={
        <span>
          <FormattedMessage<never, "strong" | "button">
            message={message}
            tags={{
              strong: function(content) {
                return <strong>{content}</strong>
              },
              button: function(content) {
                return (
                  <button onClick={triggerZendeskWidget} css={btnCss}>
                    {content}
                  </button>
                )
              },
            }}
          />
        </span>
      }
    />
  )
}

/* styles */

const btnCss: ThemeCss = theme => ({
  display: `inline`,
  padding: 0,
  border: `none`,
  color: theme.colors.gatsby,
  background: `none`,
  cursor: `pointer`,

  "&:hover": {
    textDecoration: `underline`,
  },
})

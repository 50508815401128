import * as React from "react"
import { usePersistentState } from "@modules/toolkit/hooks/usePersistentState"
import { useCurrentUser } from "@modules/auth"
import { useInvitationByIdQuery } from "@modules/invitation/queries.generated"
import { InvitationType } from "@modules/graphql/types"

export function useWaitingSiteTransferInvitation() {
  const { currentUser } = useCurrentUser()

  const [invitationId, setInvitationId] = usePersistentState<string>(
    `gatsby:waitingSiteTransferInvitation:${currentUser?.id}`,
    ``
  )

  const { data } = useInvitationByIdQuery({
    variables: {
      id: invitationId,
    },
    skip: !invitationId,
  })

  const isActive =
    data?.invitationById?.accepted === false &&
    data?.invitationById?.expired === false
  const isCorrect =
    data?.invitationById?.type === InvitationType.ParentOrganization &&
    data?.invitationById?.invitationEmail === currentUser?.email
  const isValid = isActive && isCorrect

  React.useEffect(() => {
    // cleans state, just in case the useAcceptSiteTransfer.handleAccept() failed to clean it when the site transfer is accepted
    if (isCorrect && !isActive) {
      setInvitationId(``)
    }
  }, [isCorrect, isActive])

  const handleStore = (id: string) => {
    if (!id) {
      return null
    }

    setInvitationId(id)
  }

  const handleClean = () => {
    setInvitationId(``)
  }

  return {
    id: isValid ? invitationId : null,
    details: isValid ? data?.invitationById : null,
    store: handleStore,
    clean: handleClean,
  }
}

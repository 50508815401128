import React from "react"
import PropTypes from "prop-types"
import {
  BuildStatus,
  PreviewStatus,
  BuildRunnerType,
} from "@modules/graphql/types"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import BuildStatusIcon from "@modules/build/shared/components/BuildStatusIcon"
import { getBuildStatusLabels } from "../utils"

const propTypes = {
  buildStatus: PropTypes.oneOf([
    ...Object.values(BuildStatus),
    ...Object.values(PreviewStatus),
  ]).isRequired,
  runnerType: PropTypes.oneOf(Object.values(BuildRunnerType)).isRequired,
  branch: PropTypes.string.isRequired,
  live: PropTypes.bool,
  className: PropTypes.string,
  a11yId: PropTypes.string.isRequired,
  id: PropTypes.string,
}

export default function BuildStatusIndicator({
  buildStatus,
  runnerType,
  branch,
  live = true,
  className,
  a11yId,
  id,
}) {
  const { label: statusLabel } = getBuildStatusLabels(buildStatus)
  const runnerLabel =
    runnerType === BuildRunnerType.Preview ? `Preview` : `Build`

  const branchText = (branch && `for branch ${branch}`) || ``

  return (
    <React.Fragment>
      <span
        id={id}
        css={visuallyHiddenCss}
        aria-live={live ? "polite" : "off"}
        role="status"
        aria-atomic="true"
      >
        {`${runnerLabel} ${statusLabel.toLowerCase()} ${branchText}`}
      </span>
      <BuildStatusIcon
        a11yId={a11yId}
        buildStatus={buildStatus}
        runnerType={runnerType}
        className={className}
        aria-hidden={live}
      />
    </React.Fragment>
  )
}

BuildStatusIndicator.propTypes = propTypes

import { Regions } from "../constants/regions"
import { countries, RegionData, CountryData } from "../constants/countries"

export function getRegionOptions(countryShortCode: string) {
  if (!Regions[countryShortCode]) return []

  const initial = { label: `Select a Region`, value: `` }
  const { regions } = countries.find(
    (country: CountryData) => country.countryShortCode === countryShortCode
  )

  return regions
    ? [
        initial,
        ...regions.map((region: RegionData) => ({
          label: region.name,
          value: region.shortCode,
        })),
      ]
    : []
}

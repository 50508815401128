import * as React from "react"
import { Breadcrumb } from "gatsby-interface"
import {
  getPathToSiteDetails,
  getSiteDetailsTabBasePath,
  getPathToPullRequestHistory,
} from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { Build, BuildRunnerType } from "@modules/graphql/types"
import {
  deploysView as deploysText,
  siteDetails as siteDetailsText,
} from "@modules/locales/default.js"

type BuildsBreadcrumbsProps = {
  organizationId: string
  siteId: string
  build?: Build | null
}

type BreadcrumbItem = {
  to?: string
  active?: boolean
  text: string
}

export function BuildsBreadcrumbs({
  organizationId,
  siteId,
  build,
}: BuildsBreadcrumbsProps) {
  const pathToSiteDetails = getPathToSiteDetails(siteId, organizationId)
  const pathToPullRequests = getSiteDetailsTabBasePath(
    SiteDetailsTab.PullRequests,
    siteId,
    organizationId
  )
  const pathToPreviewTab = getSiteDetailsTabBasePath(
    SiteDetailsTab.CmsPreview,
    siteId,
    organizationId
  )
  const pathToPullRequestsHistory = build?.pullRequest
    ? getPathToPullRequestHistory(build.pullRequest.id, siteId, organizationId)
    : ``

  const getBreadcrumbItems = (): BreadcrumbItem[] => {
    if (!build) {
      // on the Pull Request History page
      return [
        {
          to: pathToPullRequests,
          text: deploysText.headers.pullRequests,
        },
        {
          active: true,
          text: deploysText.labels.history,
        },
      ]
    } else if (build.pullRequest) {
      // Pull Requests page
      return [
        {
          to: pathToPullRequests,
          text: deploysText.headers.pullRequests,
        },
        {
          to: pathToPullRequestsHistory,
          text: deploysText.labels.history,
        },
        {
          active: true,
          text: deploysText.labels.buildDetails,
        },
      ]
    } else if (
      !build.pullRequest &&
      build.runnerType === BuildRunnerType.Preview
    ) {
      // CMS Preview page
      return [
        {
          to: pathToPreviewTab,
          text: siteDetailsText.previews,
        },
        {
          active: true,
          text: deploysText.labels.buildDetails,
        },
      ]
    } else {
      // Production Build page
      return [
        {
          to: pathToSiteDetails,
          text: build.branch || ``,
        },
        {
          active: true,
          text: deploysText.labels.buildDetails,
        },
      ]
    }
  }

  return (
    <Breadcrumb separator="slash" size="S" css={{ display: `flex` }}>
      {getBreadcrumbItems().map(({ text, ...rest }, index) => (
        <Breadcrumb.Item {...rest} key={text + index} css={{ marginBottom: 0 }}>
          {text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

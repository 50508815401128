import * as React from "react"
import { Theme, AriaFormGroupFieldData } from "gatsby-interface"
import {
  RadioButtonCard,
  RadioButtonCardInput,
} from "@modules/ui/components/RadioButtonCard"
import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"
import { Interpolation } from "@emotion/core"
import { CardHeader, CardDetails } from "./PlanSelectorCard.parts"
import { PlanProfile } from "../constants/planProfiles"

export type PlanSelectorCardProps = {
  onSelect: (value: PlanProfile) => void
  billingInterval?: BillingInterval
  isSelected?: boolean
  isCurrentPlan?: boolean
  currentPlanOnTrial?: boolean
  isDisabled?: boolean
  plan: PlanProfile
  enterpriseSelectable?: boolean
  isStatic?: boolean
  isRecommended?: boolean
} & Pick<
  AriaFormGroupFieldData,
  "getOptionControlProps" | "getOptionLabelProps"
>

export function PlanSelectorCard({
  plan,
  isSelected,
  isCurrentPlan,
  currentPlanOnTrial,
  isDisabled,
  onSelect,
  billingInterval,
  getOptionControlProps,
  isStatic,
  isRecommended,
}: PlanSelectorCardProps) {
  const optionValue = plan.tier

  const isEmphasized = isRecommended || isCurrentPlan
  const isFramed = isSelected || isRecommended

  return (
    <RadioButtonCard
      checked={isSelected}
      css={theme =>
        cardCss({
          theme,
          isDisabled,
          isEmphasized: isEmphasized,
          isStatic,
          isFramed,
        })
      }
    >
      <CardHeader
        plan={plan}
        billingInterval={billingInterval}
        isRecommended={isRecommended}
        isCurrentPlan={isCurrentPlan}
        currentPlanOnTrial={currentPlanOnTrial}
        isStatic={isStatic}
      />

      <CardDetails
        heading={plan?.qualitiesHeading}
        qualities={plan?.qualities}
        isEmphasized={isEmphasized || isSelected}
      />

      {!isStatic && (
        <RadioButtonCardInput
          value={optionValue}
          name={`TierOption`}
          checked={isSelected}
          onChange={() => {
            !isDisabled && onSelect(plan)
          }}
          {...getOptionControlProps(optionValue)}
          isHidden={true}
          disabled={isDisabled}
        />
      )}
    </RadioButtonCard>
  )
}

/* styles */

const cardCss = ({
  theme,
  isDisabled,
  isEmphasized,
  isStatic,
  isFramed,
}: {
  theme: Theme
  isDisabled?: boolean
  isEmphasized?: boolean
  isStatic?: boolean
  isFramed?: boolean
}): Interpolation => [
  {
    "--cardOpacity": isDisabled ? 0.5 : 1,

    border: `1px solid ${theme.colors.grey[30]}`,
    borderRadius: theme.radii[2],
    padding: 0,
    display: `grid`,
    gridTemplateRows: `auto 1fr`,
    cursor: isStatic ? `default` : `pointer`,
    transition: `border 0.5s ease-out`,

    "&:hover": {
      borderColor: theme.colors.grey[50],
    },
  },

  isDisabled && {
    cursor: `not-allowed`,
  },

  isEmphasized && {
    borderColor: theme.colors.purple[50],

    "&:hover": {
      borderColor: theme.colors.purple[50],
    },
  },

  isFramed && {
    borderColor: theme.colors.purple[50],
    boxShadow: `0px 0px 0px 2px ${theme.colors.purple[50]}`,

    "&:hover": {
      borderColor: theme.colors.purple[50],
    },
  },
]

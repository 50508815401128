import { format } from "date-fns"
import * as Yup from "yup"

export function translateDatesToInputString(
  start?: Date | null,
  end?: Date | null
) {
  return `${start ? format(new Date(start), "MM/dd/yyyy") : ``}${
    end ? ` -` : ``
  }${end ? format(new Date(end), " MM/dd/yyyy") : ``}`
}

export function validateInputValue(value: string) {
  const schema = Yup.string().matches(
    // it validates the string if it contains a single 'mm/dd/yyyy' date string or two date strings separated by dash 'mm/dd/yyy - mm/dd/yyy'
    /((^0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(20(19|2[0-9])$)|(^0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(20(19|2[0-9]))( - )(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(20(19|2[0-9]))$)/,
    {
      excludeEmptyString: true,
    }
  )
  return schema.isValid(value)
}

export function extractDatesFromInputValue(value: string) {
  const matches = value.match(
    // it looks for 'mm/dd/yyyy' date strings
    /(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(20(19|2[0-9]))/g
  )

  const start = matches?.[0]
  const end = matches?.[1]

  return [start, end]
}

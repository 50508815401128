import { ThemeCss } from "gatsby-interface"

export const viewDetailsLinkCss: ThemeCss = theme => ({
  display: `inline-flex`,
  alignItems: `center`,
  color: theme.colors.grey[40],
  textDecoration: `none`,
  fontSize: theme.fontSizes[1],
  "&:hover, &:focus": {
    color: theme.colors.gatsby,
  },
})

export const rowCss: ThemeCss = theme => [
  {
    display: `flex`,
    minHeight: theme.space[8],
    fontSize: theme.fontSizes[1],
    color: theme.colors.grey[50],
    flexWrap: `wrap`,

    "& > div, & > span, & > time": {
      marginRight: theme.space[7],
      marginBottom: theme.space[2],
    },

    "& > :last-child": {
      marginRight: 0,
      marginBottom: 0,
    },

    [theme.mediaQueries.desktop]: {
      marginTop: 0,
      marginBottom: 0,
      flexDirection: `row`,
      alignItems: `center`,

      "& > div, & > span, & > time": {
        marginRight: theme.space[7],
        marginBottom: 0,
      },
    },
  },
]

export const itemCss: ThemeCss = theme => [
  {
    alignItems: `center`,
    display: `flex`,

    svg: {
      marginRight: theme.space[3],
    },
  },
]

export const actionsCss: ThemeCss = () => ({
  marginLeft: `auto`,
})

export const separatorCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  transform: `translateY(-10%)`,
  color: theme.colors.grey[40],
  display: `none`,

  [theme.mediaQueries.desktop]: {
    display: `inline-block`,
  },
})

import React from "react"
import PropTypes from "prop-types"
import { ExternalLink } from "@modules/ui/components/ExternalLink"

import { getBuildURL } from "@modules/build/shared/utils"

const propTypes = {
  buildUrl: PropTypes.string,
  className: PropTypes.string,
  truncate: PropTypes.bool,
}

function BuildUrl({ buildUrl, truncate, className }) {
  return (
    <ExternalLink
      data-cy="build-url"
      data-testid="build-url"
      href={getBuildURL(buildUrl)}
      truncate={truncate}
      className={className}
    />
  )
}

BuildUrl.propTypes = propTypes

export default BuildUrl

import * as React from "react"
import IconSkeleton from "./IconSkeleton"
import { IconProps } from "./types"

export default function FreePlanIcon(props: IconProps) {
  return (
    <IconSkeleton {...props} iconName="FreePlanIcon" stroke="none">
      <path
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="currentColor"
      />
      <path
        d="M17.5 8.5C18.6046 8.5 19.5 7.60457 19.5 6.5C19.5 5.39543 18.6046 4.5 17.5 4.5C16.3954 4.5 15.5 5.39543 15.5 6.5C15.5 7.60457 16.3954 8.5 17.5 8.5Z"
        fill="currentColor"
      />
    </IconSkeleton>
  )
}

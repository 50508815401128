import { useQuery } from "react-apollo"

import { ApolloStatus } from "@modules/enums/constants"
import { LATEST_BUILD_BY_STATUS_QUERY } from "@modules/build/shared/queries"
import { BuildRunnerType, BuildStatus } from "@modules/graphql/types"

export const useLatestBuildByStatus = ({
  siteId,
  siteBranch,
  runnerType = BuildRunnerType.Builds,
  status = BuildStatus.Success,
  setError = () => {},
}) => {
  const { loading, data, networkStatus, refetch } = useQuery(
    LATEST_BUILD_BY_STATUS_QUERY,
    {
      variables: {
        siteId,
        branch: siteBranch,
        runnerType,
        status,
      },
      onError: error => setError(error),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: `cache-and-network`,
      pollInterval: 180000,
    }
  )

  const isLoading = loading && !data && networkStatus !== ApolloStatus.Polling

  const dataLoaded = data && data.latestBuildByStatus

  return [isLoading, dataLoaded, refetch]
}

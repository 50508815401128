import icon01 from "@modules/user/assets/feature01.svg"
import icon02 from "@modules/user/assets/feature02.svg"
import icon03 from "@modules/user/assets/feature03.svg"
import icon04 from "@modules/user/assets/feature04.svg"

export type Feature = {
  heading: string
  description: string
  icon: string
}

const Features: Feature[] = [
  {
    heading: `Faster Builds and Deploys`,
    description: `Get the fastest builds and deploys on the web with infrastructure customized for Gatsby`,
    icon: icon01,
  },

  {
    heading: `Shareable Preview Environments`,
    description: `Easily collaborate with shareable URLs created on each pull-request or content change `,
    icon: icon02,
  },
  {
    heading: `Global Edge Network`,
    description: `Host your website on a performant, secure, and reliable Edge network with 33+ PoPs`,
    icon: icon03,
  },
  {
    heading: `Testing and Rollbacks`,
    description: `Deploy each build with only the confidence of an atomic build that can be rolled back in seconds`,
    icon: icon04,
  },
]

export const data = Features

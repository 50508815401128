import * as React from "react"
import { VisuallyHidden, ToggleSwitch, ThemeCss, Text } from "gatsby-interface"
import { BillingInterval } from "@modules/graphql/types"
import { billing as text } from "@modules/locales/default.js"

export type PlanSelectorIntervalProps = {
  interval: BillingInterval
  onChange: (val: BillingInterval) => void
  variant?: `oneRow` | `twoRows`
}

export function PlanSelectorInterval({
  variant = `oneRow`,
  interval,
  onChange,
  ...rest
}: PlanSelectorIntervalProps) {
  const isTwoRowsVariant = variant === `twoRows`

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as BillingInterval

    onChange(value)
  }

  const shortDiscountMessage = !isTwoRowsVariant ? text.labels.save15 : null
  const longDiscountMessage = isTwoRowsVariant ? (
    <Text css={discountCss} size="S">
      {text.messages.save15withyearlyBilling}
    </Text>
  ) : null

  return (
    <div css={rootCss} {...rest}>
      <VisuallyHidden id="intervalTitle">
        {text.headers.billingInterval}
      </VisuallyHidden>
      {longDiscountMessage}
      <ToggleSwitch
        id="interval"
        labelOn={
          interval === BillingInterval.Annual ? (
            <React.Fragment>
              <b>{text.labels.yearly}</b> {shortDiscountMessage}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {text.labels.yearly} {shortDiscountMessage}
            </React.Fragment>
          )
        }
        labelOff={
          interval === BillingInterval.Monthly ? (
            <b>{text.labels.monthly}</b>
          ) : (
            text.labels.monthly
          )
        }
        valueOn={BillingInterval.Annual}
        valueOff={BillingInterval.Monthly}
        value={interval}
        onChange={handleChange}
        aria-describedby="intervalTitle"
        tone="SUCCESS"
      />
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
})

const discountCss: ThemeCss = theme => ({
  marginBottom: theme.space[2],
})

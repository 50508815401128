import { AvailablePlanNames } from "@modules/enums/constants"
import {
  BillingInterval,
  SubscriptionPlan,
  MachinePricingTier,
} from "@modules/graphql/types"
import {
  useAvailablePlansQuery,
  useAllAvailablePlansQuery,
} from "@modules/billing/queries.generated"
import { normalizePlan } from "../shared/utils"
import {
  PlanTier,
  NextPlanInfo,
  SimplifiedPlanTier,
} from "../components/RequestForm/types"
import * as qs from "query-string"

export const FREE_PLAN_ID = `free`

export const FREE_PLAN: SubscriptionPlan = {
  name: `Free`,
  features: [
    `Builds & Preview`,
    `Total of 25 real-time edits/day`,
    `Online documentation`,
    `Build a single branch`,
    `Fast builds `,
  ],
  formattedAmount: `$0.00`,
  formattedMonthlyAmount: `$0.00`,
  id: FREE_PLAN_ID,
  planId: FREE_PLAN_ID,
  amount: 0,
  isRecommended: false,
  buildsTier: MachinePricingTier.Free,
  hostingTier: MachinePricingTier.Free,
  baseFeatures: {
    tier: MachinePricingTier.Free,
  },
}

const legacyPaidPlans = [
  // plans with long names will need access to downgrade to free plan
  `Standard Builds - Performance Hosting`,
  `Standard Builds - Free Hosting`,
  `Standard Builds - Standard Hosting`,
  `Performance Builds - Performance Hosting`,
  `Performance Builds - Free Hosting`,
  `Performance Builds - Standard Hosting`,
  `Free Builds - Performance Hosting`,
  `Free Builds - Free Hosting`,
  `Free Builds - Standard Hosting`,
]
export const useFilteredPlans = ({
  organizationId,
  interval,
  planName: currentPlanName,
  skip,
}: {
  organizationId?: string
  interval: BillingInterval
  planName?: string
  skip?: boolean
}) => {
  const planGroup = `UNIT_PRICING_SELF_SERVE`

  const { loading, error, data } = useAvailablePlansQuery({
    variables: {
      organizationId,
      interval: interval,
      group: planGroup,
    },
    skip,
  })

  const availableSubscriptionPlans =
    (data && data.availableSubscriptionPlans) || []

  const withFreePlan =
    availableSubscriptionPlans &&
    availableSubscriptionPlans.length > 0 &&
    (!currentPlanName ||
      AvailablePlanNames.includes(currentPlanName) ||
      legacyPaidPlans.includes(currentPlanName)) // legacy support for adding free plan when coming from old tiers

  const finalPlans = withFreePlan
    ? [...availableSubscriptionPlans, FREE_PLAN]
    : availableSubscriptionPlans

  return {
    plans: finalPlans && finalPlans.map(normalizePlan),
    loading,
    error,
  }
}

export const isBillingChange = (
  nextPlanInfo: NextPlanInfo,
  billing: {
    plan: {
      baseFeatures: {
        tier?: SimplifiedPlanTier
      }
      hostingTier: PlanTier
      buildsTier: PlanTier
      interval: `MONTHLY` | `ANNUAL`
    }
  }
): boolean => {
  // is there a change in tier? old plans included both hosting and builds tier so we use this for backwards compatibility
  const hasTierChange =
    billing.plan?.baseFeatures?.tier !== nextPlanInfo.tier ||
    billing.plan?.hostingTier !== nextPlanInfo.hostingTier ||
    billing.plan?.buildsTier !== nextPlanInfo.buildsTier

  const billingIntervalHasChanged =
    nextPlanInfo.billingInterval !== billing.plan.interval

  if (hasTierChange) {
    return true
  } else if (billingIntervalHasChanged) {
    return (
      billing?.plan?.baseFeatures?.tier === MachinePricingTier.Professional ||
      billing?.plan?.baseFeatures?.tier === MachinePricingTier.Agency
    )
  } else {
    return false
  }
}

export function getInitialPlanInfo(plan: SubscriptionPlan, search: string) {
  if (!plan) {
    return null
  }

  const { upgrade } = qs.parse(search)
  const isFree = plan.baseFeatures?.tier === MachinePricingTier.Free

  // if there is 'upgrade' param in query string, what means the user comes to the billing
  // by clicking the 'Upgrade Plan' button and the workspace is on Free plan it automatically
  // selects the Professional plan option on the plan selector
  if (upgrade && isFree) {
    return {
      billingInterval: BillingInterval.Annual,
      tier: MachinePricingTier.Professional,
    }
  }

  return {
    billingInterval: plan.interval || BillingInterval.Annual,
    buildsTier: plan.buildsTier,
    hostingTier: plan.hostingTier,
    tier: plan.baseFeatures?.tier,
  }
}

import * as React from "react"
import { MachinePricingTier, BillingInterval } from "@modules/graphql/types"
import {
  FreePlanIcon,
  ProfessionalPlanIcon,
  AgencyPlanIcon,
  EnterprisePlanIcon,
  Link,
} from "gatsby-interface"
import { planInformation as planInformationText } from "@modules/locales/default.js"

export enum PlanSignup {
  NoTrial = `invitation-error`,
  WithTrial = `withTrial`,
  ViaSales = `viaSales`,
  Netlify = `netlify`,
}

export const SignupLabel: Record<PlanSignup, string> = {
  [PlanSignup.NoTrial]: `Deploy project`,
  [PlanSignup.Netlify]: `Deploy on Netlify`,
  [PlanSignup.WithTrial]: `Sign Up`,
  [PlanSignup.ViaSales]: `Contact Sales`,
}

export type PlanProfile = {
  tier: MachinePricingTier
  name: string
  icon: React.ComponentType
  description: string | React.ReactNode
  qualitiesHeading: string
  qualities: string[]
  price: Record<BillingInterval, number | null>
  signupType: PlanSignup
}

const freePlan = {
  tier: MachinePricingTier.Free,
  name: planInformationText.morphemes.freePlanName,
  icon: FreePlanIcon,
  description: `Best for launching your personal projects.`,
  qualitiesHeading: "Includes",
  qualities: [
    `Fast Gatsby builds`,
    `Bring your own domain and host for no charge`,
    `Preview changes before they go live`,
    `Discord, community support`,
  ],
  actionLabel: `Deploy to Netlify`,
  price: {
    MONTHLY: 0,
    ANNUAL: 0,
  },
  signupType: PlanSignup.Netlify,
}

const professionalPlan = {
  tier: MachinePricingTier.Professional,
  name: planInformationText.morphemes.professionalPlanName,
  icon: ProfessionalPlanIcon,
  description: `Best for small teams focused on collaboration.`,
  qualitiesHeading: "Everything in Free, plus",
  qualities: [
    `Blazingly fast builds for even large Gatsby sites`,
    `1TB of bandwidth and quick page loads for every visitor`,
    `Image CDN, Serverless Functions for business-critical performance`,
    `Email support and 99% uptime SLA`,
  ],
  actionLabel: `Deploy to Netlify`,
  price: {
    MONTHLY: 5000,
    ANNUAL: 51000 / 12,
  },
  signupType: PlanSignup.Netlify,
}

const agencyPlan = {
  tier: MachinePricingTier.Agency,
  name: planInformationText.morphemes.agencyPlanName,
  icon: AgencyPlanIcon,
  description: (
    <React.Fragment>
      Best for{" "}
      <Link to="https://www.netlify.com/agency-directory/">
        Netlify-approved Agency Partners
      </Link>{" "}
      looking to scale their client relationships.
    </React.Fragment>
  ),
  qualitiesHeading: "Everything in Professional, plus",
  qualities: [
    `Up to 100 sites`,
    `Zero downtime client handoff`,
    `Share sites and collaborate across client and business workspaces`,
    `Invite your whole team (includes 10 users)`,
  ],
  price: {
    MONTHLY: 25000,
    ANNUAL: 25000 * 0.85,
  },
  signupType: PlanSignup.ViaSales,
}

const enterprisePlan = {
  tier: MachinePricingTier.Enterprise,
  name: planInformationText.morphemes.enterprisePlanName,
  icon: EnterprisePlanIcon,
  description: `Best for high performance teams needing state-of-the-art infra security and white-glove support.`,
  qualitiesHeading: "Everything in Agency, plus",
  qualities: [
    `Dedicated onboarding & top-tier support`,
    `Deliver content everywhere with Global CDN`,
    `Priority email support from Gatsby engineers`,
    `Dedicated Account Manager for all of your onboarding, migration, hands-on training and performance audit needs`,
  ],
  price: {
    MONTHLY: null,
    ANNUAL: null,
  },
  signupType: PlanSignup.ViaSales,
}

export type SupportedPlan =
  | MachinePricingTier.Free
  | MachinePricingTier.Professional
  | MachinePricingTier.Agency
  | MachinePricingTier.Enterprise

const plansData: Record<SupportedPlan, PlanProfile> = {
  FREE: freePlan,
  PROFESSIONAL: professionalPlan,
  AGENCY: agencyPlan,
  ENTERPRISE: enterprisePlan,
}

const orderedPlans: SupportedPlan[] = [
  MachinePricingTier.Free,
  MachinePricingTier.Professional,
  MachinePricingTier.Agency,
  MachinePricingTier.Enterprise,
]

export function usePlanProfiles() {
  return {
    order: orderedPlans,
    data: plansData,
  }
}

import React from "react"
import PropTypes from "prop-types"
import { Badge } from "gatsby-interface"
import { MdFlashOn } from "react-icons/md"

const propTypes = {
  className: PropTypes.string,
}

export default function IncrementalBuildChip({ className }) {
  return (
    <Badge
      tone="NEUTRAL"
      Icon={MdFlashOn}
      className={className}
      textVariant="DEFAULT"
    >
      Cloud
    </Badge>
  )
}

IncrementalBuildChip.propTypes = propTypes

import * as React from "react"

/**
 * A hook provides methods to manage WorkspaceBuildQueue component
 */

export function useWorkspaceBuildQueue(organizationId: string) {
  // manages the modal panel mode (open/closed)
  const [isOpen, setIsOpen] = React.useState(false)
  // keeps the build's id received from the queued build card on which the user has clicked the 'View queue' link (if applicable)
  const [originId, setOriginId] = React.useState<string | null>(null)

  const handleOpen = (id: string) => {
    setIsOpen(true)
    setOriginId(id)
  }

  const handleClose = () => {
    setIsOpen(false)
    setOriginId(null)
  }

  return {
    isOpen,
    onOpen: handleOpen,
    onClose: handleClose,
    originId,
  } as const
}

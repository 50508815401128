// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type UpdateUserWelcomeFieldsMutationVariables = Types.Exact<{
  user: Types.UserWelcomeInput;
}>;


export type UpdateUserWelcomeFieldsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserWelcomeFields?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message'>
  )> }
);


export const UpdateUserWelcomeFieldsDocument = gql`
    mutation UpdateUserWelcomeFields($user: UserWelcomeInput!) {
  updateUserWelcomeFields(user: $user) {
    success
    message
  }
}
    `;
export type UpdateUserWelcomeFieldsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserWelcomeFieldsMutation, UpdateUserWelcomeFieldsMutationVariables>;

/**
 * __useUpdateUserWelcomeFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateUserWelcomeFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserWelcomeFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserWelcomeFieldsMutation, { data, loading, error }] = useUpdateUserWelcomeFieldsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserWelcomeFieldsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserWelcomeFieldsMutation, UpdateUserWelcomeFieldsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserWelcomeFieldsMutation, UpdateUserWelcomeFieldsMutationVariables>(UpdateUserWelcomeFieldsDocument, baseOptions);
      }
export type UpdateUserWelcomeFieldsMutationHookResult = ReturnType<typeof useUpdateUserWelcomeFieldsMutation>;
export type UpdateUserWelcomeFieldsMutationResult = ApolloReactCommon.MutationResult<UpdateUserWelcomeFieldsMutation>;
export type UpdateUserWelcomeFieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserWelcomeFieldsMutation, UpdateUserWelcomeFieldsMutationVariables>;

import * as React from "react"
import { Waypoint } from "react-waypoint"
import { keyframes } from "@emotion/core"
import { ThemeCss, Theme, EmptyState } from "gatsby-interface"
import { InvocationLogs } from "./InvocationLogs"
import { useFunctionInvocationsLogs } from "../hooks/useFunctionInvocationsLogs"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import Loading from "@modules/ui/components/Loading"
import { NetworkStatus } from "apollo-client"
import { SeeRecentLogsAlert } from "./SeeRecentLogsAlert"
import { functionsView as text } from "@modules/locales/default"
import { LambdaGraphic } from "../assets/LambdaGraphic"

export type FunctionLogsProps = {
  siteId: string
  functionId: string
  buildId: string
}

export function FunctionLogs({
  siteId,
  functionId,
  buildId,
}: FunctionLogsProps) {
  const topAnchor = React.useRef<HTMLDivElement | null>(null)
  const [
    logs,
    {
      loading,
      error,
      networkStatus,
      loadMore,
      seeRecentLogs,
      setSeeRecentLogs,
    },
  ] = useFunctionInvocationsLogs(siteId, functionId, buildId)
  const [initialLastTimestamp, setInitialLastTimestamp] = React.useState<
    number
  >(0)

  React.useEffect(() => {
    if (!initialLastTimestamp && logs?.[0]?.startedAt) {
      const lastLogTimestamp = new Date(logs[0].startedAt).getTime()
      setInitialLastTimestamp(lastLogTimestamp)
    }
  })

  const clearAlert = () => {
    setSeeRecentLogs(false)
  }

  if (!logs && loading) {
    return <Loading bufferSize="padded" delay={500} />
  }

  if (error) {
    return <ErrorAlert>{error.message}</ErrorAlert>
  }

  return (
    <React.Fragment>
      {!logs || logs?.length === 0 ? (
        <React.Fragment>
          <EmptyState
            heading={`No logs`}
            text={<span>{text.messages.thisFunctionhasNotBeenInvoked}</span>}
            graphic={<LambdaGraphic />}
            css={emptyStateCss}
          />
        </React.Fragment>
      ) : (
        <div css={{ position: `relative` }}>
          <div id="top" ref={topAnchor} />
          <Waypoint onEnter={clearAlert} />

          {seeRecentLogs && <SeeRecentLogsAlert anchor={topAnchor} />}

          <div role="log" css={baseCss}>
            <ol aria-label="" css={itemsCss}>
              {logs.map(item => {
                const { id, method, status, logs, startedAt } = item

                const startedAtTimestamp = new Date(startedAt).getTime()

                return (
                  <li
                    key={id}
                    id={id}
                    css={(theme: Theme) => [
                      itemCss(theme),
                      {
                        "--status-color": theme.colors[getColor(status)][80],
                        "--entry-color": theme.colors[getColor(status)][90],
                      },
                      initialLastTimestamp &&
                        startedAtTimestamp > initialLastTimestamp && {
                          animation: `${fade} 2s ease forwards`,
                        },
                    ]}
                  >
                    <div css={invocationCss}>
                      <h3 css={headerCss}>
                        <span css={badgeCss}>
                          <span>{method}</span>
                          <span>›</span>
                          <span>{status}</span>
                        </span>
                        {text.headers.invocation} {id}
                      </h3>
                      <InvocationLogs logs={logs} />
                    </div>
                  </li>
                )
              })}
            </ol>
          </div>

          <Waypoint onEnter={loadMore} />

          {loading && networkStatus === NetworkStatus.fetchMore ? (
            <Loading variant="baby" css={loadingCss} />
          ) : null}
        </div>
      )}
    </React.Fragment>
  )
}

/* helpers */

function getColor(status: number) {
  if (status >= 400) {
    return `red`
  } else if (status >= 200 && status < 300) {
    return `green`
  } else {
    return `blue`
  }
}

/* styles */

const loadingCss: ThemeCss = theme => ({
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
})

const baseCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.grey[80],
  color: theme.colors.white,
  fontFamily: theme.fonts.monospace,
  fontSize: theme.fontSizes[1],
  lineHeight: theme.lineHeights.default,
  position: `relative`,
  fontVariantLigatures: `none`,
})

const itemsCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  padding: 0,
  position: `relative`,
})

const fade = keyframes`
  from {
    background: var(--entry-color);
  }
  to {
    background: transparent;
  }
`

const itemCss: ThemeCss = theme => ({
  margin: 0,
  padding: theme.space[5],

  [theme.mediaQueries.desktop]: {
    padding: theme.space[7],
  },
})

const invocationCss: ThemeCss = theme => ({
  position: `relative`,
  paddingBottom: theme.space[3],

  "&:before": {
    content: '""',
    left: 0,
    top: theme.space[2],
    width: `1px`,
    bottom: 0,
    position: `absolute`,
    backgroundColor: `var(--status-color)`,
  },

  "&:after": {
    content: '""',
    position: `absolute`,
    left: 0,
    bottom: 0,
    width: `5px`,
    height: `6px`,
    backgroundColor: `var(--status-color)`,
  },
})

const headerCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `flex-end`,
  fontFamily: `inherit`,
  fontWeight: theme.fontWeights.body,
  fontSize: theme.fontSizes[0],
  margin: 0,
  color: theme.colors.grey[40],
})

const badgeCss: ThemeCss = theme => ({
  backgroundColor: `var(--status-color)`,
  color: theme.colors.white,
  padding: `${theme.space[2]} ${theme.space[3]}`,
  borderRadius: theme.radii[2],
  borderBottomLeftRadius: 0,
  fontSize: theme.fontSizes[1],
  marginRight: theme.space[5],
  display: `flex`,
  alignSelf: `flex-start`,

  "span:nth-child(2)": {
    display: `inline-block`,
    padding: `0 ${theme.space[2]}`,
    opacity: 0.5,
  },

  "span:nth-child(3)": {
    fontWeight: theme.fontWeights.bold,
  },

  i: {
    fontStyle: `normal`,
  },
})

const emptyStateCss: ThemeCss = theme => ({
  "& > div": {
    maxWidth: `none`,
  },
})

import * as React from "react"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { useUserOrganizationsByPlan } from "../hooks/useUserOrganizationsByPlan"
import { useUserOrganizationsBySites } from "../hooks/useUserOrganizationsBySites"
import {
  Title,
  Intro,
  Workspaces,
  Actions,
  Wrapper,
  Notes,
} from "./SiteTransferActions.parts"
import {
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Button,
  Text,
  Spacer,
} from "gatsby-interface"
import { useAcceptSiteTransfer } from "../hooks/useAcceptSiteTransfer"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import Loading from "@modules/ui/components/Loading"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { siteTransfer as text, ui as uiText } from "@modules/locales/default.js"
import { Redirect } from "@gatsbyjs/reach-router"
import { ApolloError } from "apollo-client"

export type SiteTransferActionsProps = RouteComponentProps & {
  invitationId: string
}

export function SiteTransferActions({
  invitationId,
}: SiteTransferActionsProps) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const organizations = useUserOrganizationsByPlan()

  const userDoesNotOwnOrg = organizations?.userOwns?.length === 0
  const userDoesNotOwnPaidOrg = organizations?.onPaidPlanUserOwns?.length === 0

  const eligibleOrganizations = useUserOrganizationsBySites(
    organizations.onPaidPlanUserOwns,
    { skip: userDoesNotOwnOrg }
  )

  const siteTransfer = useAcceptSiteTransfer(invitationId, {
    setError,
  })

  React.useEffect(() => {
    if (!siteTransfer.target && eligibleOrganizations.withRoom?.length === 1) {
      siteTransfer.setTarget(eligibleOrganizations.withRoom?.[0])
    }
  }, [eligibleOrganizations])

  const handleSelect = (orgName: string) => {
    const selectedOrg = eligibleOrganizations.withRoom.find(
      org => org.name === orgName
    )

    siteTransfer.setTarget(selectedOrg)
  }

  const loading =
    siteTransfer.loading ||
    organizations.loading ||
    eligibleOrganizations.loading

  let error: ApolloError | { message: string } | undefined =
    siteTransfer.error || organizations.error || eligibleOrganizations.error

  if (siteTransfer.notValid) {
    error = { message: text.messages.notAuthorized }
  }

  if (
    siteTransfer.accepted &&
    siteTransfer.invitation?.resourceId &&
    siteTransfer.target?.id
  ) {
    const redirectTo = `/dashboard/${siteTransfer.target.id}/sites/${siteTransfer.invitation?.resourceId}`

    return <Redirect to={redirectTo} noThrow />
  }

  if (siteTransfer.accepted) {
    return null
  }

  return (
    <React.Fragment>
      <main>
        <StandardSingleColumn>
          {loading && <Loading />}

          {error && <ErrorAlert>{error.message}</ErrorAlert>}

          {!error && !loading && (
            <React.Fragment>
              <Title
                siteName={siteTransfer.siteName}
                orgName={siteTransfer.sourceOrgName}
              />

              <Wrapper>
                <Intro
                  hasNoOrg={userDoesNotOwnOrg}
                  hasNoPaidOrg={userDoesNotOwnPaidOrg}
                  senderEmail={siteTransfer?.senderEmail}
                  senderName={siteTransfer?.senderName}
                />

                <Workspaces
                  free={organizations.onFreePlanUserOwns}
                  withRoom={eligibleOrganizations.withRoom}
                  withNoRoom={eligibleOrganizations.withNoRoom}
                  selectedOrganization={siteTransfer.target}
                  onSelect={handleSelect}
                  invitationId={invitationId}
                />

                {siteTransfer.target && (
                  <Notes
                    senderName={siteTransfer?.senderName}
                    orgName={siteTransfer.sourceOrgName}
                  />
                )}

                {errorAlert && (
                  <React.Fragment>
                    <Spacer size={7} />
                    {errorAlert}
                  </React.Fragment>
                )}

                <Actions
                  onDecline={siteTransfer.decline}
                  onAccept={() => siteTransfer.accept(siteTransfer.target)}
                  onDismiss={siteTransfer.dismiss}
                  transfering={siteTransfer.transfering}
                  selectedOrganization={siteTransfer.target}
                />
              </Wrapper>
            </React.Fragment>
          )}
        </StandardSingleColumn>
      </main>

      <Modal
        isOpen={siteTransfer.declineInitialized}
        aria-label={``}
        onDismiss={siteTransfer.cancelDecline}
        type="error"
      >
        <ModalCard>
          <StyledModal variant="WARNING">
            <StyledModalHeader onCloseButtonClick={siteTransfer.cancelDecline}>
              {text.headers.declineSiteTransfer}
            </StyledModalHeader>
            <StyledModalBody>
              <Text>{text.messages.areYouSure}</Text>

              <StyledModalActions>
                <Button
                  variant="SECONDARY"
                  tone="NEUTRAL"
                  type="button"
                  onClick={siteTransfer.cancelDecline}
                >
                  {uiText.actions.cancel}
                </Button>
                <Button
                  variant="PRIMARY"
                  tone="DANGER"
                  type="submit"
                  data-cy="revoke-invite-cta"
                  onClick={siteTransfer.confirmDecline}
                >
                  {text.labels.decline}
                </Button>
              </StyledModalActions>
            </StyledModalBody>
          </StyledModal>
        </ModalCard>
      </Modal>
    </React.Fragment>
  )
}

import * as React from "react"
import { WindowLocation } from "@gatsbyjs/reach-router"
import * as Yup from "yup"
import { UserInfoInput } from "@modules/graphql/types"
import * as qs from "query-string"
import {
  ThemeCss,
  Card,
  CardDivider,
  InputConnectedField,
  Heading,
  Button,
  Text,
  Link,
  Notification,
} from "gatsby-interface"
import Form from "@modules/form/components/Form"
import { Layout } from "@modules/user/shared/components/Layout"
import { HomeLink } from "@modules/user/shared/components/HomeLink"
import {
  auth as authText,
  ui as uiText,
  loginErrors as errorsText,
} from "@modules/locales/default.js"
import { FormattedMessage } from "@modules/locales"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { isAuthenticated, processLoginAttempt } from "@modules/auth"
import {
  AuthenticationOption,
  AuthenticationLabels,
} from "@modules/user/shared/constants/authentication"
import { oneColumnCss } from "@modules/user/shared/stylesheets/layouts"
import { boxCss } from "@modules/user/shared/stylesheets/box"
import { AuthError } from "../../shared/components/AuthError"

export type SSOFormValues = UserInfoInput

export const validationSchema = Yup.object<SSOFormValues>().shape({
  email: Yup.string()
    .email(uiText.messages.validationInvalidEmail)
    .required(uiText.messages.validationIsRequired),
})

export type SingleSignOnProps = {
  location?: WindowLocation
}

export function SingleSignOn({ location }: SingleSignOnProps) {
  const { trackSegment, trackAction } = useTracker()
  const { ssoLoginTest, error, invitationId, invitationToken } = qs.parse(
    location?.search || ``
  )

  return (
    <Layout css={oneColumnCss}>
      <HomeLink />
      <Card css={boxCss} as="div">
        <Heading as="h1">Single Sign-On</Heading>

        {ssoLoginTest === `success` && (
          <Notification
            variant="SECONDARY"
            tone="SUCCESS"
            content={<div>{authText.messages.ssoLoginTestSuccess}</div>}
          />
        )}

        {error && <AuthError message={errorsText.messages.ssoAuthError} />}

        <div>
          <Form<SSOFormValues>
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
              email: ``,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              trackAction({
                eventType: `TRACK_EVENT`,
                name: `Clicked Authenticate via WorkOs`,
                uiSource: `Login page`,
              })
              trackSegment({
                type: SegmentEventType.Track,
                event: `Clicked to Autneticate via WorkOs`,
                properties: {
                  loginType: AuthenticationLabels.WORKOS,
                },
              })
              const authType = isAuthenticated()
                ? `SSO_VERIFY_AUTH`
                : `DASHBOARD_AUTH`
              // assemble login attempt query param string
              let loginAttemptQueryParams = `idpcontact=${values.email}&authType=${authType}`
              if (invitationId) {
                loginAttemptQueryParams += `&invitationId=${invitationId}`
              }
              if (invitationToken) {
                loginAttemptQueryParams += `&invitationToken=${invitationToken}`
              }
              processLoginAttempt(
                loginAttemptQueryParams,
                AuthenticationOption.Workos
              )
            }}
          >
            {() => (
              <Form.FormElement applySpacing noValidate>
                <InputConnectedField
                  aria-label="email"
                  label=""
                  name="email"
                  placeholder={authText.labels.enterYourEmail}
                  required
                />

                <Button type="submit" size="XL" width="FIT_CONTAINER">
                  {authText.actions.continueToProvider}
                </Button>
              </Form.FormElement>
            )}
          </Form>
        </div>

        <Text size="L" noMarginBottom={true} css={toLoginCss}>
          <FormattedMessage<never, "link">
            message={authText.messages.notUsingSsoBackToLogin}
            tags={{
              link: function (content) {
                return (
                  <Link to="/dashboard/login" variant="SIMPLE">
                    {content}
                  </Link>
                )
              },
            }}
          />
        </Text>

        <CardDivider />

        <Text noMarginBottom={true} size="S" noMarginTop={true}>
          <FormattedMessage<never, "link">
            message={authText.messages.noAccount}
            tags={{
              link: function (content) {
                return (
                  <Link to="https://app.netlify.com/signup" variant="SIMPLE">
                    {content}
                  </Link>
                )
              },
            }}
          />
        </Text>
      </Card>
    </Layout>
  )
}

/* styles */

const toLoginCss: ThemeCss = _theme => ({
  textAlign: `center`,
})

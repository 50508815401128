import { UserOrganizationsDocument } from "@modules/header/queries.generated"
import { useAcceptResourceInvitationMutation } from "@modules/invitation/queries.generated"
import { logout } from "@modules/auth"

export function useAcceptInvite() {
  const [mutate] = useAcceptResourceInvitationMutation({
    refetchQueries: [{ query: UserOrganizationsDocument }],
  })

  const acceptInvite = (
    invitationId: string,
    setRedirectPath: (value: string) => void,
    setSuccess: (value: boolean) => void,
    setError: (e: { message: string }) => void
  ) => {
    mutate({
      variables: {
        invitationId,
      },
    })
      .then(({ data }) => {
        const resultData = data?.acceptResourceInvitation
        const mutationResult = resultData?.mutationResult

        if (mutationResult?.success) {
          const redirectPath = resultData?.redirectPath
          if (redirectPath) {
            setRedirectPath(redirectPath)
          }
          return setSuccess(true)
        }

        if (mutationResult?.message === `Invalid User Accepting Invitation`) {
          logout(`?error=invitation-mismatch`)
        }

        return setError({
          message: mutationResult?.message ?? ``,
        })
      })
      .catch(e => setError(e))
  }

  return [acceptInvite] as const
}

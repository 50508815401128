import { countries, CountryData } from "../constants/countries"

export const getCountryOptions = () => {
  const initial = { label: `Select a country`, value: `` }

  return [
    initial,
    ...countries.map((country: CountryData) => ({
      label: country.countryName,
      value: country.countryShortCode,
    })),
  ]
}

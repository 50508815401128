import * as React from "react"
import {
  CursorPagination,
  BuildRunnerType,
  BuildStatus,
  BuildType,
  Build,
} from "@modules/graphql/types"
import {
  useBuildsForSiteByFilterParamsQuery,
  BuildsForSiteByFilterParamsDocument,
  BuildsForSiteByFilterParamsQuery,
  BuildsForSiteByFilterParamsQueryVariables,
} from "../queries.generated"
import { useBuildCreatedSubscription } from "@modules/build/shared/queries.generated"
import { useApolloClient } from "react-apollo"
import { NetworkStatus } from "apollo-client"

export type UseFilteredBuildsParams = {
  siteId: string
  pagination?: CursorPagination
  branch?: string
  runnerType?: BuildRunnerType
  buildStatus: BuildStatus | null
  buildType: BuildType | null
  date?: Date | null
  startDate: Date | null
  endDate: Date | null
  keyword: string
  skip?: boolean
}

const LIMIT = 10

export const useFilteredBuilds = ({
  siteId,
  branch,
  runnerType = BuildRunnerType.IncrementalBuilds,
  buildStatus,
  buildType,
  date,
  startDate,
  endDate,
  keyword,
  skip = true,
}: UseFilteredBuildsParams) => {
  const shiftedEndDate = shiftEndDate(endDate)

  const variables = {
    siteId,
    runnerType,
    pagination: {
      limit: LIMIT,
    },
    branch,
    buildStatus,
    buildType,
    startDate,
    endDate: shiftedEndDate,
    keyword,
  }

  const client = useApolloClient()

  // every time one of the filteres value changes the hook checks
  // if there is cached data for particular filter values
  React.useEffect(() => {
    try {
      const cachedData = client.readQuery({
        query: BuildsForSiteByFilterParamsDocument,
        variables,
      })

      // if there is no cached data does nothing
      if (!cachedData) {
        return
      }

      // if there is cached data it removes it
      client.writeQuery({
        query: BuildsForSiteByFilterParamsDocument,
        data: {
          buildsForSiteByFilterParams: null,
        },
        variables,
      })
    } catch (e) {
      // uncomment console.log for debuging, but the only try-catch
      // job in this case is to silence the Apollo client complaing
      // every time it does not find the sought - after cache key,
      // what is not an error per - se
      // console.log(e)
    }
  }, [buildType, buildStatus, startDate, endDate, keyword])

  const {
    data,
    error,
    refetch,
    fetchMore,
    networkStatus,
  } = useBuildsForSiteByFilterParamsQuery({
    variables,
    fetchPolicy: getFetchPolicy({
      buildType,
      buildStatus,
      startDate,
      endDate,
      keyword,
    }),
    skip,
    notifyOnNetworkStatusChange: true,
  })

  useBuildCreatedSubscription({
    variables: {
      id: siteId,
      runnerType: BuildRunnerType.Builds,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const createdBuild = subscriptionData?.data?.buildCreated

      if (createdBuild?.branch !== variables.branch) {
        return
      }

      const queryInfo = {
        query: BuildsForSiteByFilterParamsDocument,
        variables: variables,
      }

      try {
        const currentBuildsForSiteByFilterParams = client.readQuery<
          BuildsForSiteByFilterParamsQuery,
          BuildsForSiteByFilterParamsQueryVariables
        >(queryInfo)

        if (!currentBuildsForSiteByFilterParams) {
          return
        }

        const builds =
          currentBuildsForSiteByFilterParams?.buildsForSiteByFilterParams
            ?.builds

        const isEligibleBuild = isBuildEligibleToCacheUpdate(
          createdBuild as Build,
          variables
        )

        if (!isEligibleBuild) {
          return
        }

        if (
          builds &&
          createdBuild &&
          currentBuildsForSiteByFilterParams?.buildsForSiteByFilterParams
            ?.builds
        ) {
          currentBuildsForSiteByFilterParams.buildsForSiteByFilterParams.builds = [
            createdBuild,
            ...builds,
          ]
        }

        client.writeQuery<
          BuildsForSiteByFilterParamsQuery,
          BuildsForSiteByFilterParamsQueryVariables
        >({
          ...queryInfo,
          data: currentBuildsForSiteByFilterParams,
        })
      } catch (e) {
        console.error(e)
      }
    },
  })

  const loadMore = () => {
    const endCursor = data?.buildsForSiteByFilterParams?.pageInfo?.endCursor
    const hasNextPage = data?.buildsForSiteByFilterParams?.pageInfo?.hasNextPage

    if (
      hasNextPage &&
      Boolean(endCursor) &&
      networkStatus === NetworkStatus.ready
    ) {
      fetchMore({
        variables: {
          ...variables,
          pagination: {
            limit: LIMIT,
            endCursor: endCursor,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newBuilds = fetchMoreResult?.buildsForSiteByFilterParams?.builds
          const pageInfo =
            fetchMoreResult?.buildsForSiteByFilterParams?.pageInfo

          if (!newBuilds || newBuilds.length === 0) {
            return previousResult
          }

          return {
            ...previousResult,
            buildsForSiteByFilterParams: {
              __typename: "BuildResults",
              builds: [
                ...(previousResult.buildsForSiteByFilterParams?.builds || []),
                ...newBuilds,
              ],
              pageInfo,
            },
          }
        },
      })
    }
  }

  return [
    data?.buildsForSiteByFilterParams?.builds,
    {
      networkStatus,
      loading: networkStatus === NetworkStatus.loading,
      loadingMore: networkStatus === NetworkStatus.fetchMore,
      updating: networkStatus === NetworkStatus.setVariables,
      error,
      loadMore,
      refetch,
      hasNextPage: data?.buildsForSiteByFilterParams?.pageInfo?.hasNextPage,
    },
  ] as const
}

/* utils */

/**
 * Shifts the endDate to the end of the day, it increases the timestamp by day (8640000) - 1 ms
 * the adjustment is neccessary because the callendar widget provides selected dates with 00:00 time
 */
const shiftEndDate = (date?: Date | null) =>
  date ? new Date(new Date(date).getTime() + (86400000 - 1)) : undefined

const getFetchPolicy = ({
  buildStatus,
  buildType,
  startDate,
  endDate,
  keyword,
}: {
  buildStatus: BuildStatus | null
  buildType: BuildType | null
  startDate: Date | null
  endDate: Date | null
  keyword: string
}) => {
  const isFiltered = buildType || buildStatus || startDate || endDate || keyword

  return isFiltered ? `network-only` : `cache-and-network`
}

function isBuildEligibleToCacheUpdate(
  build: Build,
  variables: BuildsForSiteByFilterParamsQueryVariables
) {
  if (variables.buildStatus && build.buildStatus !== variables.buildStatus) {
    return false
  }

  if (variables.buildType && build.buildType !== variables.buildType) {
    return false
  }

  const createdAt = new Date(build.createdAt)
  const startDate = variables.startDate && new Date(variables.startDate)

  if (startDate && createdAt < startDate) {
    return false
  }

  const endDate = variables.endDate && new Date(variables.endDate)

  if (endDate && createdAt > endDate) {
    return false
  }

  return true
}

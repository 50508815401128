import * as React from "react"
import {
  SplitButton,
  SplitButtonProps,
  DropdownMenuItem,
} from "gatsby-interface"
import { OrganizationStatus } from "@modules/graphql/types"
import { useTriggerCloudBuilder } from "../hooks/useTriggerCloudBuilder"
import { SitePermissions } from "@modules/site/permissions"

function ToastMessage() {
  return (
    <React.Fragment>
      <strong>Build triggered</strong>
      {` `}
      successfully
    </React.Fragment>
  )
}

export type TriggerBuildProps = {
  siteId: string
  selectedBranch?: string
  pullRequestId?: string
  orgStatus?: OrganizationStatus | null
  size?: SplitButtonProps["size"]
  triggerBuildError?: JSX.Element | null
  setTriggerBuildError?: (alert: JSX.Element | null) => void
}

export function TriggerBuild({
  selectedBranch,
  siteId,
  orgStatus,
  size,
  pullRequestId,
  triggerBuildError,
  setTriggerBuildError,
}: TriggerBuildProps) {
  const selectedBranchName = selectedBranch || ``
  const [
    triggerCloudBuilder,
    { loading, errorAlert },
  ] = useTriggerCloudBuilder()

  React.useEffect(() => {
    if (setTriggerBuildError && errorAlert && !triggerBuildError) {
      setTriggerBuildError(errorAlert)
    }
  }, [errorAlert])

  return (
    <SitePermissions id={siteId} resource="sites" action="edit">
      <SplitButton
        buttonLabel="Trigger build"
        dropdownButtonLabel="Other build options"
        disabled={orgStatus === OrganizationStatus.Idle || loading}
        size={size}
        onClick={() => {
          return triggerCloudBuilder({
            siteId,
            branch: selectedBranchName,
            pullRequestId,
            successMessage: <ToastMessage />,
          })
        }}
        data-testid="trigger-build-dropdown"
      >
        <DropdownMenuItem
          onSelect={() => {
            return triggerCloudBuilder({
              siteId,
              branch: selectedBranchName,
              pullRequestId,
              deleteCache: true,
              successMessage: <ToastMessage />,
            })
          }}
        >
          Clear cache and build {selectedBranchName}
        </DropdownMenuItem>
      </SplitButton>
    </SitePermissions>
  )
}

export default TriggerBuild

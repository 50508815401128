// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import { useApolloFragment } from 'apollo-fragment-react';


export type UserNameFieldsFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'firstName' | 'lastName' | 'name' | 'email'>
);

export const UserNameFieldsFragmentDoc = gql`
    fragment userNameFields on User {
  firstName
  lastName
  name
  email
}
    `;

  /**
   * __useUserNameFieldsFragment__
   * To read a fragment data from Apollo Cache, call `useUserNameFieldsFragment` and pass it the ID of the cached object.
   * When your component renders, `useUserNameFieldsFragment` returns an object from Apollo Client cache that contains data property
   * you can use to render your UI.
   *
   * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
   *
   * @example
   * const { data } = useUserNameFieldsFragment('fragment-id');
   */
export function useUserNameFieldsFragment(id: string) {
          return useApolloFragment<UserNameFieldsFragment>(UserNameFieldsFragmentDoc, id);
        }
export type UserNameFieldsFragmentHookResult = ReturnType<typeof useUserNameFieldsFragment>;
import React from "react"
import {
  Button,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
} from "gatsby-interface"
import { TableRow } from "@modules/ui/components/Table"
import styled from "@emotion/styled"
import { useDeletePersonalAccessTokenMutation } from "@modules/user-settings/queries.generated"
import { ui as uiText, userSettings as text } from "@modules/locales/default.js"
import { MdArrowForward } from "react-icons/md"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

export type PersonalAccessTokenRowProps = {
  key: string
  id: string
  name: string
  lastUsedAt: string
}

export function PersonalAccessTokenRow({
  key,
  id,
  name,
  lastUsedAt,
}: PersonalAccessTokenRowProps) {
  const [deletePersonalAccessTokenMutation, { loading, error }] =
    useDeletePersonalAccessTokenMutation({
      variables: {
        id,
      },
      refetchQueries: [`PersonalAccessTokens`],
    })
  const [openModal, setOpenModal] = React.useState(false)
  const handleClose = () => setOpenModal(false)

  const TableCell = styled.td({
    width: `60%`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
  })

  return (
    <React.Fragment>
      <TableRow key={key}>
        <TableCell data-cy={`peresonal-access-token-` + name}>{name}</TableCell>
        <TableCell
          css={{ fontStyle: `italic` }}
          data-cy={`personal-access-token-` + lastUsedAt}
        >
          {lastUsedAt}
        </TableCell>
        <TableCell>
          <Button
            variant="SECONDARY"
            tone="DANGER"
            size="M"
            onClick={() => setOpenModal(true)}
          >
            {text.personalAccessTokens.deleteButton}
          </Button>
        </TableCell>
      </TableRow>

      {error && <ErrorAlert>{error.message}</ErrorAlert>}

      <Modal
        aria-label={text.personalAccessTokens.deleteLabel}
        onDismiss={handleClose}
        isOpen={openModal}
        type="error"
      >
        <ModalCard>
          <StyledModal variant="WARNING">
            <StyledModalHeader onCloseButtonClick={handleClose}>
              {text.personalAccessTokens.deleteHeading}
            </StyledModalHeader>

            <StyledModalBody>
              {text.personalAccessTokens.deleteBody}
              <StyledModalActions>
                <Button
                  onClick={handleClose}
                  disabled={loading}
                  variant="SECONDARY"
                  tone="DANGER"
                >
                  {uiText.actions.cancel}
                </Button>

                <Button
                  rightIcon={<MdArrowForward />}
                  tone="DANGER"
                  onClick={() =>
                    deletePersonalAccessTokenMutation(id).then(handleClose)
                  }
                  loading={loading}
                >
                  {text.personalAccessTokens.deleteConfirmation}
                </Button>
              </StyledModalActions>
            </StyledModalBody>
          </StyledModal>
        </ModalCard>
      </Modal>
    </React.Fragment>
  )
}

import { WindowLocation } from "@gatsbyjs/reach-router"
import {
  BillingInterval,
  MachinePricingTier,
  SubscriptionPlan,
} from "@modules/graphql/types"
import { PlanProfile, SupportedPlan } from "../constants/planProfiles"
import { PlanInfo } from "@modules/billing/shared/constants/tiers"

export function isPricingPage(location: WindowLocation) {
  return (
    location?.pathname.includes("/pricing") &&
    !location?.pathname.includes("/dashboard")
  )
}

export function isCreateOrgPage(location: WindowLocation) {
  return location?.pathname.includes("/organization/create")
}

export type FindBillingPlanProps = {
  selectedPlan: PlanInfo
  billingPlans: SubscriptionPlan[]
  profile?: PlanProfile
  interval?: BillingInterval
}

export function findBillingPlan({
  selectedPlan,
  billingPlans,
  profile,
  interval,
}: FindBillingPlanProps) {
  return billingPlans.find(plan => {
    // depending on arguments, if 'profile' or 'interval' is provided, serves different scenarios

    // user intentionally selects plan card, what provides the planProfile data
    if (profile) {
      if (profile?.tier === MachinePricingTier.Free) {
        return plan?.baseFeatures?.tier === profile?.tier
      } else {
        return (
          profile?.tier === plan?.baseFeatures?.tier &&
          selectedPlan?.billingInterval === plan.interval
        )
      }
    }

    // user intentionally changes billing interval, what provides the new interval value
    if (interval) {
      if (selectedPlan?.tier === MachinePricingTier.Free) {
        return selectedPlan?.tier === plan?.baseFeatures?.tier
      } else {
        return (
          selectedPlan?.tier === plan?.baseFeatures?.tier &&
          interval === plan.interval
        )
      }
    }

    // user does nothing, PlansSelector reacts to pre selected plan at the component's mount
    if (selectedPlan?.tier === MachinePricingTier.Free) {
      return selectedPlan?.tier === plan?.baseFeatures?.tier
    } else {
      return (
        selectedPlan?.tier === plan?.baseFeatures?.tier &&
        selectedPlan?.billingInterval === plan.interval
      )
    }
  })
}

export function checkDuplicatedBillingPlans(billingPlans: SubscriptionPlan[]) {
  const planIdentifiers = billingPlans.map(
    plan => `${plan.baseFeatures?.tier}-${plan.interval}`
  )

  const uniquePlanIdentifiers = Array.from(new Set(planIdentifiers))

  return billingPlans.length > uniquePlanIdentifiers.length
}

export function checkNotMatchingBillingPlans(
  billingPlans: SubscriptionPlan[],
  planProfiles: Record<SupportedPlan, PlanProfile>
) {
  // returns true if there is a billing plan which has no corresponding plan profile with matching price
  return billingPlans.some(plan => {
    // matching plan profile for given billing plan
    const profile =
      plan?.baseFeatures?.tier &&
      planProfiles[plan.baseFeatures.tier as SupportedPlan]

    const profileMonthlyPrice =
      Number(plan.interval && Number(profile?.price?.[plan.interval])) || 0
    const profilePrice =
      plan.interval === BillingInterval.Annual
        ? profileMonthlyPrice * 12
        : profileMonthlyPrice

    const planPrice = Number(plan.amount)

    return planPrice !== profilePrice
  })
}

import * as React from "react"

export type AlphaIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  a11yId?: string
}

export function AlphaIcon({ a11yId, ...rest }: AlphaIconProps) {
  const id = a11yId ? a11yId : `ProfileRequestIcon`

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      {...rest}
    >
      <path
        d="M4.34766 20.8689L9.98433 7.46577L8.87325 5.34833H7.05759V3.1311H9.76753C10.3366 3.1311 10.8244 3.41934 11.0277 3.82953L18.7916 18.6517H20.6073V20.8689H17.8973C17.3147 20.8689 16.8269 20.5696 16.6372 20.1594L11.5696 10.5034L7.22019 20.8689H4.34766Z"
        fill="currentColor"
      />
    </svg>
  )
}

import React from "react"
import BuildUrl from "@modules/build/shared/components/BuildUrl"
import { Text } from "gatsby-interface"

const textCss = theme => ({
  color: theme.colors.grey[90],
  fontWeight: "bold",
  margin: 0,
})

export function LastBuildInfo({ buildUrl }) {
  if (buildUrl) {
    return <BuildUrl buildUrl={buildUrl} truncate />
  }

  return (
    <Text
      size="L"
      css={textCss}
      data-testid="last-build-info-text"
      data-cy="empty-build-header-content"
    >
      No successful builds yet
    </Text>
  )
}
